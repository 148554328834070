<template>
    <article class="container-fluid p-3" data-content-type="AUDIENCE">
        <section>
            <div class="container-fluid p-0">
                <spinner class="bg-white" v-if="audienceReportLoading" text="Building..." />

                <div v-if="ready" class="report-container">
                    <div class="report-section bg-white">
                        <!-- Header -->
                        <div v-if="!printMode" class="row p-2">
                            <div class="col-auto position-relative align-self-center"
                                    data-intent="representative-photo"
                            >
                                <Suspense>
                                    <choose-representative-photo
                                        v-memo="[audience.userPhoto?.id, audience.photoCharacteristics]"
                                        :insights="insights"
                                        :object="audience"
                                    />
                                    <template #fallback>
                                        <div role="status" class="text-primary spinner-border spinner-border-sm p-2 mx-3">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </template>
                                </Suspense>
                            </div>

                            <div class="col ps-0 align-self-center">
                                <section v-if="audience.isActive">
                                    <form @submit.prevent="updateAudienceName">
                                        <div class="text-primary header-title d-flex">
                                            <div class="py-0 align-self-center"><strong>Audience:</strong></div>
                                            <div class="py-0 px-1 flex-grow-1 align-self-center">
                                                <input
                                                    class="form-control mx-n1 px-1 w-100"
                                                    :class="{'is-invalid': audienceNameValidation.error.message}"
                                                    data-action="update-build-name"
                                                    maxlength="100"
                                                    type="text"
                                                    v-model="editableAudienceName"
                                                    @focus="allowUpdateAudienceName = true"
                                                    @blur="blurUpdateAudienceName"
                                                />
                                            </div>
                                            <div
                                                v-show="audienceNameValidation.error.message.length"
                                                class="py-0 px-1 align-self-center text-danger"
                                            >
                                                {{ audienceNameValidation.error.message }}
                                            </div>
                                        </div>
                                    </form>

                                    <div class="d-flex flex-row">
                                        <ul class="list-inline d-inline mb-0 me-3">
                                            <li class="list-inline-item">
                                                <a href @click.prevent="showSummaryInfo">
                                                    <font-awesome-icon :icon="['solid', 'circle-info']" fixed-width/>
                                                </a>
                                            </li>
                                            <li class="list-inline-item">
                                                <div class="spinner-border spinner-border-sm" role="status" v-if="pdfSharing">
                                                    <span class="visually-hidden">Loading...</span>
                                                </div>
                                                <a v-else
                                                    href
                                                    :title="`Download or Share PDF`" v-tooltip
                                                    @click.prevent="shareModal('pdf', 'show')"
                                                >
                                                    <font-awesome-icon :icon="['regular', 'file-pdf']" fixed-width/>
                                                </a>
                                            </li>
                                            <li class="list-inline-item">
                                                <div class="spinner-border spinner-border-sm" role="status" v-if="reportSharing">
                                                    <span class="visually-hidden">Loading...</span>
                                                </div>
                                                <a v-else
                                                    href
                                                    :title="`Share this Report`" v-tooltip
                                                    @click.prevent="shareModal('report', 'show')"
                                                >
                                                    <font-awesome-icon :icon="['solid', 'share-from-square']" fixed-width/>
                                                </a>
                                            </li>
                                            <li class="list-inline-item">
                                                <router-link
                                                    :disabled="sharedMode ? true : null"
                                                    :event="sharedMode ? '' : 'click'"
                                                    :title="`Send this Audience`" v-tooltip
                                                    :to="{
                                                        name: 'audienceSend',
                                                        params: {
                                                            id: audience.id
                                                        }
                                                    }">
                                                    <font-awesome-icon :icon="['solid', 'truck-fast']" fixed-width/>
                                                </router-link>
                                            </li>
                                        </ul>
                                        <em class="header-detail small">Built on {{ buildDate }}</em>
                                        <div class="ms-auto">
                                            <div v-if="allowUpdateAudienceName" class="ms-auto me-2">
                                                <a href="#" class="no-color text-success"
                                                    @click.prevent="updateAudienceName"
                                                >
                                                    <font-awesome-icon :icon="['regular', 'circle-check']" size="sm"/>
                                                </a>
                                                <a href="#" class="ms-1 no-color text-danger"
                                                    @click.prevent="updateAudienceNameCancel"
                                                >
                                                    <font-awesome-icon :icon="['regular', 'circle-xmark']" size="sm"/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <section v-else>
                                    <div class="text-primary header-title d-flex">
                                        <div class="py-0 px-1 align-self-center"><strong>Audience:</strong></div>
                                        <div class="py-0 px-1 flex-grow-1 align-self-center">
                                            {{ audience.name }}
                                        </div>
                                    </div>
                                </section>
                            </div>

                            <div class="col-auto my-auto">
                                <img class="brand-logo"
                                        :onerror="Utils.imageFallback(defaultBrandLogoPath)"
                                        :src="logoPath"
                                />
                            </div>
                        </div>
                        <!-- END header -->

                        <!-- Subnav moved per SDSPOT-73 -->
                        <subnav v-if="audience.isActive && !printMode"
                                class="border-top"
                                :navLinks="subnavLinks"
                                placement="page"
                        />

                        <section v-if="audience.isActive && reportReady">
                            <h1 v-if="!printMode"
                                class="section-title h3 text-center my-3"
                            >
                                {{ sectionTitle }}
                                <on-page-help/>
                            </h1>

                            <div class="d-flex flex-column-reverse">
                                <audience-summary-pdf
                                    v-if="printMode"
                                    :audience="audience"
                                    :chart-associated-data="chartListAssociatedData"
                                    :chart-data="customLayout"
                                    :insights="insights"
                                    :original-persona="persona"
                                    :persona="decoratedPersona"
                                    :sentences="sentences"
                                />

                                <section v-else>
                                    <audience-summary
                                        v-if="isUndefinedOrNullOrEmpty($route.params.tab)"
                                        :audience="audience"
                                        :chart-associated-data="chartListAssociatedData"
                                        :chart-data="chartListData"
                                        @set-segment-id="setSegmentId"
                                    />
                                    <underlying-persona
                                        v-if="$route.params.tab === 'persona'"
                                        :audience="audience"
                                        :insights="insights"
                                        :job="job"
                                        :persona="decoratedPersona"
                                        :sentences="sentences"
                                        :shared-mode="sharedMode"
                                    />
                                    <distribution-history
                                        v-if="$route.params.tab === 'distributions'"
                                        :audience="audience"
                                    />
                                </section>
                            </div>

                            <div v-if="!printMode"
                                    class="row p-4 bg-primary"
                                    :class="{'mt-n4': params.tab === 'persona'}"
                            >
                                <div class="col-lg-2 offset-lg-5">
                                    <img alt="Powered by GRAPHMASSIVE®"
                                            class="img-fluid"
                                            src="/assets/images/powered-by-graphmassive-gray-25.svg"
                                    />
                                </div>
                            </div>
                        </section>
                        <section v-else-if="!audience.isActive" class="p-4">
                            <div class="d-block py-4">
                                <i class="warning p-2">
                                    <font-awesome-icon flip="horizontal" size="lg" fixed-width :icon="['solid', 'message-exclamation']" />
                                </i>
                                The Audience that you are looking for has been removed from the system. Consider updating your bookmarks.
                            </div>
                        </section>
                        <section v-else>
                            <spinner />
                        </section>

                        <!-- Font Awesome elements for late-binding in Highcharts -->
                        <div class="d-none">
                            <font-awesome-icon
                                data-intent="font-awesome-icon"
                                data-type="twitterMention"
                                :icon="['solid', 'at']"
                            />
                            <font-awesome-icon
                                data-intent="font-awesome-icon"
                                data-type="twitterHashtag"
                                :icon="['solid', 'hashtag']"
                            />
                            <font-awesome-icon
                                data-intent="font-awesome-icon"
                                data-type="twitterTerm"
                                :icon="['solid', 'quote-left']"
                            />
                        </div>
                    </div>
                </div>
                <div v-else-if="containsSensitiveData">
                    <div class="alert alert-warning">
                        This report was created with sensitive data that we have since purged from our system. If you want to view the report, you'll need to rebuild it.
                    </div>
                </div>
            </div>

            <bootstrap-modal
                body-class="text-normal"
                class="modal-xl"
                content-class="bg-blue-25 border-primary"
                :hide-on-backdrop="false"
                title-class="text-primary"
                :id="summaryInfoModalId"
            >
                <template #title class="text-primary">Audience Summary Information</template>
                <template #body>
                    <div class="row mb-3 text-primary">
                        <div class="col text-start">Audience built by: <strong>{{ audienceBuiltBy }}</strong>
                        </div>
                    </div>

                    <h6><i>Audience Characteristics</i></h6>
                    <div id="characteristics">
                        <audience-characteristics
                            :audience="audience"
                            class="text-muted px-0"
                            inner-container-class="card-body mt-n3 bg-blue-10"
                            :mode="audienceMode.REPORT"
                        />
                    </div>

                    <div class="mt-3">
                        <h6><i>Suppression file to filter specific people out of your Audience</i></h6>
                    </div>
                    <div class="card bg-blue-10">
                        <div class="card-body selected-list-files">
                            <i class="text-gray-50"
                                v-if="Utils.isEmptyArray(listFiles)">No file selected</i>
                            <div v-for="(file, index) in listFiles" class="text-danger" :key="index">
                                <font-awesome-icon class="me-2" :icon="['regular', 'file-lines']"/>
                                <span class="text-uppercase">{{ file.name }}</span>
                            </div>
                        </div>
                    </div>
                </template>
            </bootstrap-modal>
        </section>

        <share ref="share"
            :content-title="audience.name"
            content-type="Audience"
            :context="shareContext"
            :link="shareLink(shareContext)"
        />
    </article>
</template>

<script lang="ts">
    import {Modal} from 'bootstrap';
    import {useHead} from '@unhead/vue';
    import {defineAsyncComponent, nextTick} from 'vue';
    import {Vue, Component, Watch, toNative} from 'vue-facing-decorator';
    import {useRoute} from 'vue-router';
    import cloneDeep from 'lodash-es/cloneDeep';
    import forOwn from 'lodash-es/forOwn';
    import Highcharts from 'highcharts';
    import {Chart} from 'highcharts-vue';
    import BootstrapModal from 'Components/common/bootstrap/modal.vue';
    import FontAwesomeIcon from 'Components/common/font-awesome-icon.vue';
    import ChooseRepresentativePhoto from 'Components/common/report/choose-representative-photo.vue';
    import {useAudienceStore, AudienceMode, AudienceSegment, geographicFields} from 'Stores/audience/audience';
    import {useAccountStore} from 'Stores/account';
    import {ActivityType} from 'Stores/activity';
    import {useAppStore} from 'Stores/common/app';
    import {PhotoAssignment} from 'Stores/common/models';
    import {useInsightStore} from 'Stores/insight';
    import {useJobStore} from 'Stores/job';
    import {usePersonaStore, PersonaMode} from 'Stores/persona';
    import {isEmptyArray, isObject, isUndefinedOrNullOrEmpty} from 'Utilities/inspect';
    import {Events} from 'Utilities/immutables';
    import * as ReportUtilities from 'Utilities/reports';
    import * as Utils from 'Utilities/utils';
    import * as validators from 'Utilities/validators';

    const AudienceSummary = () => import(/* webpackChunkName: "AudienceSummary" */ 'Components/audience/report/audience-summary.vue');
    const AudienceSummaryPdf = () => import(/* webpackChunkName: "AudienceSummaryPdf" */ 'Components/audience/report/audience-summary-pdf.vue');
    const Spinner = () => import(/* webpackChunkName: "Spinner" */ 'Components/common/spinner/spinner.vue');
    const Subnav = () => import(/* webpackChunkName: "Subnav" */ 'Components/app/navigation/subnav.vue');

    @Component<AudienceReport>({
        emits: [Events.HIDE_TOOLTIPS],
        setup() {
            const route = useRoute();
            const appStore = useAppStore();
            const accountStore = useAccountStore();
            const audienceStore = useAudienceStore();
            const insightStore = useInsightStore();
            const jobStore = useJobStore();
            const personaStore = usePersonaStore();
            const audience = cloneDeep(audienceStore.getAudience);

            useHead({
                bodyAttrs: {
                    class: route.name === 'audienceReportPDF' ?
                        ['bg-white', 'letter', 'm-0'] :
                        ['bg-primary'],
                },
                title: 'Audience: Loading...',
            });

            return {appStore, accountStore, audienceStore, insightStore, jobStore, personaStore};
        },
        components: {
            highcharts: Chart,
            AudienceSummary,
            AudienceSummaryPdf,
            AudienceCharacteristics: defineAsyncComponent(() =>
                import('Components/audience/audience-characteristics.vue')
            ),
            BootstrapModal,
            ChooseRepresentativePhoto,
            DistributionHistory: defineAsyncComponent(() =>
                import('Components/audience/report/distribution-history.vue')
            ),
            FontAwesomeIcon,
            OnPageHelp: defineAsyncComponent(() =>
                import('Components/common/on-page-help.vue')
            ),
            RepresentativePhoto: defineAsyncComponent(() =>
                import('Components/common/representative-photo.vue')
            ),
            Share: defineAsyncComponent(() =>
                import('Components/common/report/share.vue')
            ),
            Spinner,
            Subnav,
            UnderlyingPersona: defineAsyncComponent(() =>
                import('Components/audience/report/underlying-persona.vue')
            )
        }
    })
    class AudienceReport extends Vue {
        asyncData: any = [];
        audienceNameValidationDefault = {
            valid: false,
            error: {
                message: ''
            }
        };
        audienceNameValidation = Utils.dereference(this.audienceNameValidationDefault);
        audienceMode = AudienceMode;
        blankBrandLogoPath = '/assets/images/transparent.png';
        chartDataCache: any = {};
        customLayout: any = false;
        defaultBrandLogoPath = '/assets/images/wiland-logo.svg';
        filterSettings: any = {
            segmentId: null,
        };
        insights: any = {};
        allowUpdateAudienceName: boolean = false;
        editableAudienceName: string = '';
        decoratedPersona: any = {};
        maxPrintCharacteristicSamples = 52;
        persona: any = {};
        personaMode = PersonaMode;
        noneSelectedMessage: String = 'None Selected';
        audienceReportLoader = 'Audience report loading';
        params = {
            id: '',
            tab: '',
            section: '',
            shared: false
        };
        ready = false;
        reportReady = false;
        personIconDimensions = {
            width: 19,
            height: 19
        }
        shareContext: string = '';
        shareToken: any = {};
        Utils = Utils;
        summaryInfoModal: Modal | null = null;
        summaryInfoModalId: string = 'audienceSummaryInfoId';
        isUndefinedOrNullOrEmpty = isUndefinedOrNullOrEmpty;

        async mounted() {
            this.appStore.setLoader(this.audienceReportLoader);
            await this.initialize(true);
            this.reportReady = !this.containsSensitiveData;
            this.appStore.clearLoader(this.audienceReportLoader);

            await nextTick(() => {
                this.summaryInfoModal = new Modal(document.getElementById(this.summaryInfoModalId)!);
                // const summaryInfoModalElement: HTMLElement | null = document.getElementById(this.summaryInfoModalId);
                //
                // if (summaryInfoModalElement) {
                //     this.summaryInfoModal = new Modal(summaryInfoModalElement, {backdrop: 'static'});
                // }
            });

            this.ready = this.reportReady;
        }

        get account() {
            return this.accountStore.getAccount;
        }

        get logoPath(): string {
            return this.account ?
                this.account.logoFile?.filePathUri?.length > 0 ?
                    this.blankBrandLogoPath :
                        this.defaultBrandLogoPath
                        : '';
        }

        get audience() {
            return this.audienceStore.originalAudience;
        }

        get audienceBuiltBy(): string {
            return `${this.audience.updated?.user?.firstName || 'unknown'} ${this.audience.updated?.user?.lastName || 'unknown'}`;
        }

        get audienceReportLoading() {
            return this.appStore.getLoaderActive(this.audienceReportLoader);
        }

        get chartList() {
            switch (this.params.tab) {
                case undefined: // Summary (default tab)
                case '':
                    if (!this.segments?.length) {
                        return [];
                    }

                    const reportElements = this.segmentData?.offlineReport || {};
                    let chartList = {
                        demographic: [],
                        geographic: [],
                    }
                    for (const type of Object.keys(chartList)) {
                        const chartIds = Object.keys(reportElements)
                            .filter(chartId => type === 'geographic' ?
                                this.isGeographicChart(chartId) :
                                !this.isGeographicChart(chartId)
                            );

                        chartList[type] = Utils.sortByProperty(
                            chartIds.map(chartId => {
                                return {
                                    chartId,
                                    position: this.fieldDictionary.standard[chartId]?.position || 9999,
                                }
                            }), 'position', 'asc'
                        )
                            .map(chartSetting => chartSetting.chartId);

                    }

                    return chartList;
            }

            return [];
        }

        get chartListData() {
            if (typeof this.chartList === 'object') {
                let chartListData = {};
                for (const type of Object.keys(this.chartList)) {
                    chartListData[type] = this.chartList[type].map(chartId => {
                        return {
                            chartId,
                            data: this.chartData(chartId),
                        }
                    });
                }

                return chartListData;
            }

            return this.chartList.map(chartId => {
                return {
                    chartId,
                    data: this.chartData(chartId),
                }
            });
        }


        get chartListAssociatedData(): any {
            let chartListAssociatedData = {};
            for (const type of Object.keys(this.chartList)) {
                chartListAssociatedData[type] = this.chartList[type]
                    .map((chartId: string) => {
                        if (!this.segmentData) {
                            return null;
                        }

                        const associatedId = `audience_segment_index_${chartId}`;

                        return {
                            chartId,
                            associatedId,
                            data: ReportUtilities.chartAssociatedData(
                                associatedId,
                                this.mappedInsights,
                                {
                                    dmaCodes: this.dmaCodes,
                                    fieldDictionary: this.fieldDictionary,
                                }
                            ),
                        }
                    });
            }

            return chartListAssociatedData;
        }

        get containsSensitiveData(): boolean {
            // return this.audience?.sensitiveSelectsAndOmits;
            return this.audience?.containsSensitiveData;
        }

        get dmaCodes() {
            return this.accountStore.getAccountDmaCodes;
        }

        get fieldDictionary() {
            return this.accountStore.getAccountDictionary;
        }

        get job() {
            return this.jobStore.getJob;
        }

        get listFiles(): Array<any> {
            // Note: For now there will only be 1 list per audience
            return this.audienceStore.getAudienceListFiles;
        }

        get mappedInsights() {
            // Map audience segment data to insights standard schema
            return {
                is_data_available: this.segmentData !== null,
                offline_standard: {
                    demographics: this.mapSegmentToGroup(this.segmentData),
                },
            }
        }

        get pdfSharing() {
            return this.appStore.getLoaderActive(`${ReportUtilities.ReportLoaders.SHARING_REPORT}-pdf`);
        }

        get reportSharing() {
            return this.appStore.getLoaderActive(`${ReportUtilities.ReportLoaders.SHARING_REPORT}-report`);
        }

        mapSegmentToGroup(segmentData: any) {
            let characteristics = cloneDeep(segmentData?.offlineReport);
            for (const key of Object.keys(characteristics)) {
                characteristics[key] = characteristics[key].map((item: any) => {
                    item.groupCount = item.segmentCount;
                    item.groupRatio = item.segmentRatio;

                    return item;
                })
            }

            return characteristics;
        }

        get printMode(): boolean {
            return this.$route.name === 'audienceReportPDF';
        }

        get sectionTitle() {
            let title: string;

            switch (this.$route.params.tab) {
                case 'persona':
                    title = 'Underlying Persona: Persona Characteristics and Unique Facts';
                    break;

                case 'summary':
                default:
                    title = 'Audience Summary: Estimated Size and Distributions';
                    break;
            }

            return title;
        }

        get segmentData(): AudienceSegment | null {
            return this.segments.find((segment: any) => segment.id === this.filterSettings.segmentId) || null;
        }

        get segments(): any {
            return this.audience.segments;
        }

        get selectsAndOmits(): any {
            return this.audience.selectsAndOmits;
        }

        get sentences() {
            return this.accountStore.getAccountSentences;
        }

        get sharedMode() {
            return this.$route.path.indexOf('/share/') > -1 && this.$route.params.token?.length > 0;
        }

        get subnavLinks() {
            const routeName = this.$route.name;

            return {
                placement: 'page',
                links: [
                    {
                        label: 'Audience Summary',
                        name: routeName,
                        params: {tab: ''},
                        default: true,
                    },
                    {
                        label: 'Underlying Persona',
                        name: routeName,
                        params: {tab: 'persona'},
                    },
                    {
                        label: 'Distribution History',
                        name: routeName,
                        params: {tab: 'distributions'},
                    },
                ],
            };
        }

        get updatedDate() {
            return this.Utils.dateFormat(this.audience.updated.timestamp, {format: 'englishDate'})
        }

        get buildDate() {
            return this.Utils.dateFormat(this.audience.completedAt, {format: 'standard'});
        }

        blurUpdateAudienceName(event: Event) {
            // Re-focus the persona name input if there are unsaved changes
            if (this.audience.name !== this.editableAudienceName) {
                const target = <HTMLElement>event.target;
                target.focus();
            } else {
                this.allowUpdateAudienceName = false;
            }
        }

        // /**
        //  * Get ChartAssociatedData parameters
        //  *
        //  * @param associatedId
        //  */
        // chartAssociatedData(associatedId: string) {
        //     switch (associatedId) {
        //         case (associatedId.match(ReportUtilities.audienceSegmentAssociatedDataPattern) || {}).input: {
        //             const [, demographic] = associatedId.match(ReportUtilities.audienceSegmentAssociatedDataPattern),
        //                 demographicName = this.fieldDictionary[demographic].shortDescription;
        //             const associatedData = ReportUtilities.chartAssociatedGreatestValue(
        //                 `${demographicName} Relative Index`,
        //                 `Your audience is {{ value }}% {{ direction }} to be {{ label }}.`,
        //                 this.mappedInsights.offline_standard?.demographics[demographic]
        //             );
        //             associatedData.persona_label = associatedData.persona_label.replace(/^(.*) in Persona$/, '$1 in Audience');
        //
        //             return associatedData;
        //         }
        //
        //         default:
        //             return false;
        //     }
        // }

        /**
         * Convenience method to retrieve and cache chart data for display
         */
        chartData(chartId: string, returnData: boolean = true) {
            let segmentId = this.filterSettings.segmentId;
            if (ReportUtilities.characteristicSegmentPattern.test(chartId)) {
                [, chartId, segmentId] = chartId.match(ReportUtilities.characteristicSegmentPattern);segmentId;
            }

            const segmentChartId = `${chartId}:${segmentId}`;
            if (!this.chartDataCache.hasOwnProperty(segmentChartId)) {
                let chartData: any;

                if (this.isGeographicChart(chartId)) {
                    chartData = this.prepareGeographicChartData(chartId, this.filterSettings.segmentId);
                } else {
                    chartData = ReportUtilities.prepareChartData({
                        // asyncData: this.asyncData,
                        chartId,
                        fieldDictionary: this.fieldDictionary,
                        filterSettings: this.filterSettings,
                        Highcharts,
                        insights: this.mappedInsights,
                        params: {
                            chartLabels: {
                                group: 'Segment'
                            }
                        },
                        returnData,
                        // sortGroup: this.sortGroup,
                    });
                }

                // Custom label/legend formatting to display select/omit choices the user made
                if (chartData.chart.type === 'pie') {
                    // We have to store some data in the chart itself since we can't use an ES6-style arrow function...
                    chartData.dictionaryReference = this.fieldDictionary.standard[chartId];
                    chartData.selectsAndOmits = this.selectsAndOmits;
                    chartData.legend = Object.assign(chartData.legend || {}, {
                        labelFormatter: function () {
                            const {dictionaryReference, selectsAndOmits} = this.series.chart.userOptions,
                                dictionaryItem = dictionaryReference.values.find(item => item.shortDescription === this.name),
                                isSelect = selectsAndOmits.select.offline[chartId]?.includes(dictionaryItem.value) || false,
                                isOmit = selectsAndOmits.omit.offline[chartId]?.includes(dictionaryItem.value) || false;

                            const color = isSelect ?
                                '#6fa84f' : // green-100
                                isOmit ?
                                    '#ce3438' : // red-100
                                    ''; // default

                            return `<span style="color: ${color}">${this.name}</span>`;
                        }
                    })
                } else {
                    chartData.xAxis.labels = Object.assign(chartData.xAxis.labels || {}, {
                        formatter: (label) => {
                            let isOmit, isSelect;
                            if (!this.fieldDictionary.standard[chartId].hasOwnProperty('values')) {
                                // Geo charts, mostly
                                isOmit = this.selectsAndOmits.omit.offline.hasOwnProperty(chartId);
                                isSelect = this.selectsAndOmits.select.offline.hasOwnProperty(chartId);
                            } else {
                                const dictionaryItem = this.fieldDictionary.standard[chartId].values.find(item => item.shortDescription === label.value);
                                isOmit = this.selectsAndOmits.omit.offline[chartId]?.includes(dictionaryItem.value) || false;
                                isSelect = this.selectsAndOmits.select.offline[chartId]?.includes(dictionaryItem.value) || false;
                            }

                            const color = isSelect ?
                                '#6fa84f' : // green-100
                                isOmit ?
                                    '#ce3438' : // red-100
                                    ''; // default

                            return `<span style="color: ${color}">${label.value}</span>`;
                        }
                    });
                }

                if ((chartData.cache || true) === false) {
                    return chartData;
                }
                this.chartDataCache[segmentChartId] = cloneDeep(chartData);
            }

            return this.chartDataCache[segmentChartId];
        }

        clearChartDataCache() {
            this.chartDataCache = {};
        }

        currentPhoto(audience) {
            if (audience.photos && audience.photos.length > 0) {
                for (let i = 0; i < audience.photos.length; i++) {
                    const photo: PhotoAssignment = audience.photos[i];

                    if (photo.type === 'current') {
                        return photo;
                    }
                }
            }

            return null;
        }

        async handleCustomLayout() {
            this.customLayout = false;

            switch (this.$route.name) {
                case 'audienceReportPDF': {
                    let customData: any = {
                        demographic: [],
                        geographic: [],
                    };

                    if (this.segments?.length) {
                        for (const segment of this.segments) {
                            this.filterSettings.segmentId = segment.id;

                            const reportElements = this.segmentData?.offlineReport || {};
                            for (const type of Object.keys(customData)) {
                                const chartIds = Object.keys(reportElements)
                                    .filter(chartId => type === 'geographic' ?
                                        this.isGeographicChart(chartId) :
                                        !this.isGeographicChart(chartId)
                                    );
                                if (!chartIds.length) {
                                    continue;
                                }

                                const charts = Utils.sortByProperty(
                                    chartIds.map(chartId => {
                                        return {
                                            chartId,
                                            position: this.fieldDictionary.standard[chartId]?.position || 9999,
                                        }
                                    }), 'position', 'asc'
                                )
                                    .map(chartSetting => {
                                        return {
                                            chartId: chartSetting.chartId,
                                            segmentId: segment.id,
                                            data: this.chartData(chartSetting.chartId),
                                        }
                                    });

                                const associatedData = chartIds.map(chartId => {
                                    const associatedId = `audience_segment_index_${chartId}`;

                                    return {
                                        chartId,
                                        associatedId,
                                        // data: this.chartAssociatedData(associatedId),
                                        data: ReportUtilities.chartAssociatedData(
                                            associatedId,
                                            this.insights,
                                            {
                                                dmaCodes: this.dmaCodes,
                                                fieldDictionary: this.fieldDictionary,
                                            }
                                        ),
                                    }
                                });

                                customData[type].push({
                                    segment,
                                    charts,
                                    associatedData
                                });
                            }
                        }
                    }

                    customData.maxPrintCharacteristicSamples = this.maxPrintCharacteristicSamples;
                    this.customLayout = customData;
                }
            }
        }

        hasImage(job) {
            return job.photoUri?.length;
        }

        hasTweets(topic) {
            return topic.twitter?.topByCount?.reduce((total, account) => {
                return total + (account.hasOwnProperty('tweetCount') ? 1 : 0);
            }, 0) || false;
        }

        /**
         * Functions to run on "page load"-whether via mounted() or inline route change
         * @param isInitial
         */
        async initialize(isInitial: boolean = false) {
            this.clearChartDataCache();
            this.params = this.$route.params;
            await this.prepareAsyncData(isInitial);
            if (!this.containsSensitiveData && this.audience.isActive) {
                await this.retrieveReportData(isInitial);
                await this.mapAudienceMetadata();
                await this.handleCustomLayout();
            }
        }

        isGeographicChart(chartId): boolean {
            return geographicFields.includes(chartId);
        }

        /**
         * Add elements to the Audience which do not come back from the API in a useful fashion
         */
        async mapAudienceMetadata() {
            await this.audienceStore.setAudiencePersonaJob(this.job);
            await this.audienceStore.setAudienceTuningProfile(this.audience['tuningProfile']);

            // Translate the segments
            let segments = [],
                segmentIndex = 1;
            for (const segment of this.segments) {
                segment.position = segmentIndex;
                segment.name = await this.audienceStore.getAudienceSegmentLabel({
                    position: segmentIndex,
                    segments: this.segments
                });
                segments.push(segment);
                ++segmentIndex;
            }
            await this.audienceStore.setAudienceSegments(segments);
        }

        async prepareAsyncData(initial: boolean = false) {
            this.asyncData = [];

            if (initial) {
                await this.retrieveAudienceData();
                if (this.containsSensitiveData) {
                    return false;
                }
                await this.retrievePersonaData();
                // await this.retrieveReportData();
            }

            let chartIdList: string[] = [];
            if (typeof this.chartList === 'string') {
                chartIdList = [this.chartList];
            } else if (isObject(this.chartList)) {
                for (const key of Object.keys(this.chartList)) {
                    chartIdList.push(this.chartList[key].chartId);
                }
            }

            // Retrieve any asynchronous data necessary for the active report page
            const forLoop = async () => {
                if (!isEmptyArray(chartIdList)) {
                    for (const chartId of chartIdList) {
                        const chartInfo = this.prepareChartData(chartId, false);
                        if (typeof chartInfo === 'object' && chartInfo.asyncData !== undefined) {
                            const asyncData = await chartInfo.asyncData();

                            // If any data is returned, store it for later use
                            forOwn(asyncData, (data, key) => {
                                this.asyncData[key] = data;
                            });
                        }
                    }
                }
            };
            await forLoop();
        }

        /**
         * Prepare chart data for display
         */
        prepareChartData(chartId: string, returnData: boolean = true) {
            let chartData: any = {};
            // let chartSourceData;

            switch (chartId) {
            }

            // if (chartData.hasOwnProperty('tooltip')) {
            //     chartData.tooltip = Highcharts.merge(ReportUtilities.defaultChartOptions.tooltip, chartData.tooltip);
            // }
            chartData.id = chartId;

            return Highcharts.merge(ReportUtilities.defaultChartOptions, chartData);
        }

        prepareGeographicChartData(chartId: string, segmentId: string): any {
            let chartSourceData: any;
            const mappedSegment = this.mapSegmentToGroup(this.segmentData);

            switch (chartId) {
                case 'dma_code':
                    chartSourceData = mappedSegment[chartId]
                        .map((item: any ) => {
                            if (item.hasOwnProperty('originalValue')) {
                                // This was previously mapped - no changes
                                return item;
                            }

                            // item.originalValue = `${item.value}`;
                            const dmaCode = this.dmaCodes.find((dmaSource: any ) => dmaSource.code === item.value);
                            if (dmaCode) {
                                item.value = `${dmaCode.code} - ${dmaCode.description}`;
                                item.originalValue = dmaCode.code;
                            } else {
                                console.error(`No DMA code found for "${item.value}"`);
                            }

                            return item;
                        });
                    break;

                case 'postal_code':
                    chartSourceData = mappedSegment[chartId];
                    break;

                default:
                    chartSourceData = Utils.sortByProperty(
                        ReportUtilities.dictionaryLookup(
                            this.fieldDictionary.standard,
                            this.mappedInsights.offline_standard?.demographics[chartId],
                            chartId
                        ),
                        'position',
                        'asc'
                    );
            }
            if (!chartSourceData) {
                return false;
            }

            // Sort alphabetically
            chartSourceData = Utils.sortByProperty(chartSourceData, 'value', 'asc');

            let barHeight = ReportUtilities.barSize;
            if (this.printMode) {
                barHeight = (barHeight * .75);
                chartSourceData = chartSourceData.slice(0, this.maxPrintCharacteristicSamples); // Limit how many items can be displayed
            }
            let chartHeight = (chartSourceData.length * barHeight) + (ReportUtilities.headerSize * 1.5);

            let chartData = {
                chart: {
                    type: 'bar',
                    height: chartHeight, // Includes margins
                },
                plotOptions: ReportUtilities.chartPlotOptions.bar,
                xAxis: {
                    categories: chartSourceData.map(item => item.value)
                },
                yAxis: ReportUtilities.chartAxisOptions.barIndexGroupPercent,
                series: ReportUtilities.chartDataToSeries('barIndex', chartSourceData, {
                    chartLabels: {
                        group: 'Segment'
                    },
                }),
                tooltip: {
                    formatter: ReportUtilities.tooltipFormatter('percent'),
                    shared: true,
                },
            };

            return ReportUtilities.prepareChartDefaults(chartData, {
                chartId,
                fieldDictionary: this.fieldDictionary,
                filterSettings: this.filterSettings,
                Highcharts,
                printMode: this.printMode, // TODO: handle print mode here?
            });
        }

        async retrieveAudienceData(initial: boolean = true) {
            if (initial) {
                await this.audienceStore.clearAudienceState();
                await this.audienceStore.clearAudienceDemographicFilters();
                await this.audienceStore.clearAudienceGeographicFilters();
                await this.audienceStore.setAvailableAudienceDemographics('');
                await this.audienceStore.setAvailableAudienceGeographics('');
            }
            await this.audienceStore.setOriginalAudience({
                id: this.params.id,
                mode: AudienceMode.REPORT,
                refresh: true,
                shared: this.params.shared
            });

            await this.audienceStore.applyAudienceSelectsAndOmits();
        }

        async retrievePersonaData() {
            // Get a decorated persona for use in characteristics
            this.persona = await this.personaStore.getPersonaById(Object.assign({}, this.$route.params, {
                id: this.audience.personaId,
                shared: this.$route.params.shared
            }));
            this.persona.mode = PersonaMode.REPORT;
            this.personaStore.setPersonaEdit(this.persona);

            // Get a decorated persona for use in characteristics
            this.personaStore.clearPersonaState();
            await this.personaStore.setOriginalPersona({
                persona: this.persona,
                shared: this.$route.params.shared,
                refresh: false
            });
            this.personaStore.setAvailableDemographics(PersonaMode.EDIT);

            const persona = this.personaStore.getPersona;
            this.decoratedPersona = cloneDeep(persona);
        }

        async retrieveReportData(isInitial: boolean = false) {
            if (isInitial) {
                await this.insightStore.clearInsightState();
                this.insightStore.setOriginatorRoot('personas');
                this.insightStore.setOriginatorId(this.persona.id);
                await this.jobStore.setJobId(this.audience.personaJobId);
                if (this.sharedMode) {
                    this.shareToken = await this.accountStore.getAccountShareToken( {
                        accountId: this.$route.params.accountId,
                        token: this.$route.params.token,
                    });

                    await this.jobStore.setJobId(this.shareToken.personaJobId);
                }

                // Get insights
                let insightsTypes: string[] = [
                    'offline_insights_standard',
                    'social_insights',
                    'unique_insights',
                ];

                let insightsData = await this.insightStore.getInsightsBatch(this.params, insightsTypes);
                for (let i = 0, end = insightsTypes.length; i < end; ++i) {
                    const insightsType = insightsTypes[i];
                    const insightsKey = insightsType.replace('_insights', '');
                    this.insights[insightsKey] = insightsData[insightsType];
                }

                this.insights.is_data_available = true;
            }
        }

        setSegmentId(segmentId: string | null = null) {
            this.filterSettings.segmentId = segmentId;
        }

        shareLink(context: string) {
            let route: any = {
                name: '',
                params: {
                    accountId: this.account.id,
                    id: this.audience.id,
                },
            };

            switch (context) {
                case 'pdf':
                    if (!this.shareToken.hasOwnProperty('token')) {
                        return '';
                    }
                    route.name = 'audienceReportPDF';
                    route.params.token = this.shareToken.token;
                    break;

                case 'report':
                    if (!this.shareToken.hasOwnProperty('token')) {
                        return '';
                    }
                    route.name = 'audienceReportShare';
                    route.params.token = this.shareToken.token;
                    break;
            }

            return Utils.siteRoot() + this.$router.resolve(route).href.replace(/^\//, '');
        }

        async shareModal(context: string, action: string) {
            this.$eventBus.emit(Events.HIDE_TOOLTIPS);
            this.shareContext = context;
            this.appStore.setLoader(`${ReportUtilities.ReportLoaders.SHARING_REPORT}-${this.shareContext}`);

            switch (action) {
                case 'hide':
                    this.$refs['share'].shareModal.hide();
                    break;
                case 'show':
                    if (!this.shareToken.hasOwnProperty('personaJobId') || this.job.id !== this.shareToken.personaJobId) {
                        this.shareToken = await this.audienceStore.generateAudienceShareToken({
                            audienceId: this.audience.id,
                            personaJob: this.job
                        });
                    }

                    await nextTick(_ => {
                        this.$refs['share'].shareModal.show();
                        this.appStore.clearLoader(`${ReportUtilities.ReportLoaders.SHARING_REPORT}-${this.shareContext}`);
                    })
                    break;
            }
        }

        async showSummaryInfo() {
            this.summaryInfoModal?.show();
        }

        async updateAudienceName() {
            if (this.editableAudienceName !== this.audience.name) {
                if (this.editableAudienceName.toLowerCase() === this.audience.name.toLowerCase()) {
                    // Skip validation - only the case has changed
                    this.audienceNameValidation = Object.assign({}, this.audienceNameValidationDefault, {valid: true});
                } else {
                    // Validate the name
                    this.audienceNameValidation = await validators.isValidActivityName(this.editableAudienceName, ActivityType.audience);
                }

                if (this.audienceNameValidation.valid) {
                    await this.audienceStore.setAudience(this.audience);
                    await this.audienceStore.saveAudienceName(this.editableAudienceName);
                } else {
                    return false;
                }
            } else {
                // Same name - clear any existing errors
                this.audienceNameValidation = Object.assign({}, this.audienceNameValidationDefault, {valid: true});
            }

            document.querySelector<HTMLInputElement>('input[data-action="update-build-name"]').blur();
            this.allowUpdateAudienceName = false;
        }

        updateAudienceNameCancel() {
            this.audienceNameValidation.error.message = '';
            this.editableAudienceName = this.audience.name;
            this.allowUpdateAudienceName = false;
            document.querySelector<HTMLInputElement>('input[data-action="update-build-name"]').blur();
        }

        @Watch('$route.params')
        async onRouteParamsChanged() {
            this.reportReady = false;
            await this.initialize(false);
            this.reportReady = true;
        }

        @Watch('audience.name')
        onAudienceNameChanged() {
            useHead({
                title: `Audience: ${this.audience ? this.audience.name : 'Loading...'}`,
            })
            this.editableAudienceName = this.audience.name;
        }
    }
    export default toNative(AudienceReport);
</script>

<style lang="scss" src="../../common/report/report.scss"/>
