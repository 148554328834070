<template>
    <main
        class="app h-100"
        :data-is-loading="isLoading"
        v-show="userAuthenticated || !$route.meta.requiresAuth"
        @click="keepBuilding"
        @contextmenu="keepBuilding"
    >
        <debug v-if="adminDebugEnabled"/>
        <spinner v-if="accountLoading" text="Switching Account..."/>
        <spinner v-if="logoutPending" text="Logging out..." :text-class-list="$route.path.includes('/report') ? ['text-light'] : []"/>
        <section v-show="!accountLoading && !logoutPending">
            <header class="nav-fixed-top" id="appNavigation" v-if="routeHasNavigation">
                <navigation v-if="showFullNavigation" @logout="logout" />
                <navigation-anonymous v-else />
            </header>
            <section id="appView" class="container-fluid p-0" >
                <spinner v-if="navigating" :classList="spinnerClasses" :text="logoutPending ? 'Logging out...' : 'Loading...'" />
                <!-- Note: Have to use show vs else to maintain page meta title -->
                <router-view v-slot="{Component, route}">
                    <template v-if="Component && !navigating">
                        <Suspense>
                            <KeepAlive>
                                <component :is="Component"
                                    :class="{ admin: ( $route.name === 'account' || $route.name === 'user' ) }"
                                    :event="appEvent"
                                    :key="routeKey"
                                    @loading="setSectionLoading"
                                    @keep-building="keepBuilding"
                                    @logout="logout"
                                    @save-draft="setSaveStatus">
                                </component>
                            </KeepAlive>
                        </Suspense>
                    </template>
                </router-view>
            </section>
        </section>
        <Teleport to="body">
            <div :id="sessionTimeoutModalId" class="modal modal-lg timeout" aria-labelledby="Session Timeout" tabindex="-1">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content border-danger">
                        <div class="modal-header">
                        </div>
                        <div class="modal-body">
                            <Suspense>
                                <div class="d-block text-center">
                                    <h3 class="mb-3">
                                        <i><span class="text-danger"><strong>Alert:</strong></span> You will be logged out in: {{ session.countdown }} seconds</i></h3>
                                    <h6>
                                        Choose <span class="text-success text-uppercase">keep building</span> to stay active or
                                        <span class="text-danger text-uppercase">logout now</span> to save any current work and secure your account.<br />
                                        <i>
                                            If you take no action you will be logged out.
                                        </i>
                                    </h6>
                                    <div class="d-block">
                                        <div class="row row-btn-arrow">
                                            <div class="col-md-6 d-flex align-items-center justify-content-center">
                                                <button
                                                    class="btn btn-success btn-arrow-left text-light"
                                                    type="button"
                                                    @click.prevent="keepBuilding"
                                                >
                                                    Keep building
                                                </button>
                                            </div>
                                            <div class="col-md-6 d-flex justify-content-center">
                                                <button
                                                    class="btn btn-danger btn-arrow-right"
                                                    type="button"
                                                    @click.prevent="logout()"
                                                >
                                                    Log out now
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <template #fallback>
                                    <div class="d-block text-center">
                                        <h4 class="d-block text-center"><i v-html="logoutModalMessage"></i></h4>
                                        <spinner class="my-4" :no-label="true"/>
                                    </div>
                                </template>
                            </Suspense>
                        </div>
                        <div class="modal-footer">
                        </div>
                    </div>
                </div>
            </div>
        </Teleport>
    </main>
</template>

<script lang="ts">
    import {nextTick} from 'vue';
    import {Vue, Component, Watch, toNative} from 'vue-facing-decorator';
    import {bootstrap as bootstrapGoogleAnalytics} from 'vue-gtag';
    import {RouteRecordRaw} from 'vue-router';
    import {useHead} from '@unhead/vue';
    import {Modal} from 'bootstrap';
    import SessionWorker from '../../session-worker';
    import Navigation from 'Components/app/navigation/navigation.vue';
    import NavigationAnonymous from 'Components/app/navigation/navigation-anonymous.vue';
    import Debug from 'Components/common/debug.vue';
    import Spinner from 'Components/common/spinner/spinner.vue';
    import {useAccountStore, AccountLoaders} from 'Stores/account';
    import {useAppStore} from 'Stores/common/app';
    import {useNavigationStore} from 'Stores/common/navigation/navigation';
    import {sessionTimerSourceName, useUserStore, User, UserSession} from 'Stores/user';
    import {
        closeAllModals,
        closeAllTooltips, getCookieValue,
        isValidLastPath,
        setCookie
    } from 'Utilities/utils';
    import {
        currentTimestamp,
        defaultSessionTimeoutSeconds,
        defaultSessionCountdownSeconds,
        getSecondsFromMinutes, futureTimestamp
    } from 'Utilities/time-utils';
    import {publicRouteNames, saveStateRouteNames} from 'Utilities/routes';
    import {Status, RequestStatus, Actions, Events} from 'Utilities/immutables';
    import {SecurityRole} from 'Stores/common/models';

    @Component({
        name: 'App',
        setup() {
            useHead({
                titleTemplate: (title) => {
                        return title ? `${title} | PersonaBuilder` : `PersonaBuilder`;
                },
                htmlAttrs: {
                    lang: 'en'
                },
            });

            return {
                accountStore: useAccountStore(),
                appStore: useAppStore(),
                navigationStore: useNavigationStore(),
                userStore: useUserStore(),
            };
        },
        components: {
            Debug,
            Navigation,
            NavigationAnonymous,
            Spinner,
        }
    })
    class App extends Vue {
        appEvent: string = Status.INITIALIZED;
        sectionLoading: boolean = false;
        // spinnerClasses: string[] = [];
        logoutLoader: string = Actions.LOGOUT_PENDING;
        logoutModalMessage = '';
        session: UserSession = {
            active: false,
            source: sessionTimerSourceName,
            timeout: defaultSessionTimeoutSeconds
        };
        saveDraftStatus: string = '';
        worker = SessionWorker;
        lastPath = '';
        routes: RouteRecordRaw[] = [];
        routerHistory: RouteRecordRaw[] = [];
        currentRoute: string =  ( this.routerHistory && this.routerHistory.current ) ? this.routerHistory.current.name : '';
        sessionTimeoutModal: Modal | null = null;
        sessionTimeoutModalId: string = 'sessionTimeoutModal';

        created() {
            this.$eventBus.on(Events.HIDE_TOOLTIPS, () => {
                closeAllTooltips();
            });
        }

        async mounted() {
            this.routes = this.$router.options.routes;
            this.routerHistory = this.$router.history;
            // <link rel="stylesheet" href="" crossorigin="anonymous">
            let fontawesomeCss = document.createElement('link');
            fontawesomeCss.setAttribute('crossorigin', 'anonymous');
            // fontawesomeCss.setAttribute('href', 'https://pro.fontawesome.com/releases/v5.13.0/css/all.css');
            fontawesomeCss.setAttribute('href', 'https://kit.fontawesome.com/96e8debd66.css');
            // fontawesomeCss.setAttribute('integrity', 'sha384-IIED/eyOkM6ihtOiQsX2zizxFBphgnv1zbe1bKA+njdFzkr6cDNy16jfIKWu4FNH');
            fontawesomeCss.setAttribute('rel', 'stylesheet');
            document.head.appendChild(fontawesomeCss);
            window.addEventListener('storage', this.bindStorageEvents, false);
            const sessionTimeoutModalElement: HTMLElement | null = document.getElementById(this.sessionTimeoutModalId);

            if (sessionTimeoutModalElement) {
                this.sessionTimeoutModal = new Modal(sessionTimeoutModalElement, {backdrop: 'static'});
            }
        }

        unmounted() {
            window.removeEventListener('storage', this.bindStorageEvents, false);
        }

        get accountLoading() {
            return this.appStore.getLoaderActive(AccountLoaders.LOADING);
        }

        get adminDebugEnabled() {
            return this.user.security?.roles?.find((role: SecurityRole) => role.identifier === 'ALL') !== undefined;
        }

        get isLoading() {
            return this.appStore.getLoaders.length > 0 || null;
        }

        get logoutPending() {
            return this.appStore.getLoaderActive(this.logoutLoader);
        }

        get navigating() {
            return this.appStore.getLoaderActive(Actions.NAVIGATING);
        }

        get navigation() {
            return this.navigationStore.getNavigation;
        }

        get routeHasAccount() {
            return this.$route.params.accountId?.length > 0;
        }

        get routeHasNavigation() {
            return ( this.$route.meta.hasNavigation !== false );
        }

        get routeKey() {
            let routeKey = this.$route?.name;
            if (this.navigation && this.$route?.meta?.forceRouteRefresh) {
                routeKey = `${routeKey}_${this.navigation.timestamp}`;
            }

            return routeKey;
        }

        get showFullNavigation() {
            return this.$route.meta.requiresAuth
                && this.routeHasAccount
            // && userAuthenticated;
        }

        get spinnerClasses() {
            return ['container-fluid', 'mx-auto', 'bg-white'];
        }

        get user() {
            return this.userStore.getUser;
        }

        get userInteractionTimestamp() {
            return this.userStore.getUser.interaction?.timestamp;
        }

        async bindStorageEvents(event) {
            const currentURL: string = window.location.toString();

            if ( event.url.indexOf( '/share/' ) < 0 && !currentURL.includes( 'share' ) ) {
                switch (event.key) {
                    case 'pbuser':
                        switch (parseInt(event.newValue)) {
                            case 0: // Logged out; return to login page
                                console.debug('🍅 [LOG OUT] Storage event triggering account metadata clear...');
                                this.accountStore.clearAccountMetadata();
                                // console.debug('STORING PREVIOUS ROUTE FOR RE-LOGIN USE:', this.$route);
                                const previousRoute: string = ![ '/', '/login' ].includes( this.$route.fullPath ) ? this.$route.fullPath : '/';
                                const session: UserSession = { active: false }
                                this.worker.postMessage( session );
                                window.sessionStorage.setItem('previousRoute', previousRoute);
                                window.localStorage.setItem( 'pbexp', '' );
                                this.$router.push( { path: '/login' }, ( onComplete: any ) => {}, ( onAbort: any ) => {} );

                                break;

                            case 1: // Logged in; return to last route or homepage
                                console.debug('🍅 [LOG IN] Storage event triggering account metadata clear...');
                                this.accountStore.clearAccountMetadata();
                                // console.debug('RETURNING TO PREVIOUS ROUTE:', window.sessionStorage.getItem('previousRoute') || '/');
                                // if ( document.hidden ) {
                                //     // Allow active tab time to set auth before inactive tabs detect no auth and force a logout
                                //     setTimeout( async () => {
                                //         await useAccountStore().setPersistedAccountMetadata();
                                //     }, 3000 );
                                // } else {
                                //     await useAccountStore().setPersistedAccountMetadata();
                                // }
                                this.$router.push( { path: window.sessionStorage.getItem('previousRoute') || '/' }, ( onComplete: any ) => {}, ( onAbort: any ) => {} );
                                await this.setSessionTimer();
                                break;

                            default:
                                console.error(`Unknown pbuser event - ${event.newValue}`, event);
                                break;
                        }
                        break;
                    case 'pbexp':
                        if ( event.oldValue && !event.newValue ) {
                            if ( this.currentRoute !== 'login' ) {
                                console.error('👋 Logout action initiated due to expiration...', JSON.stringify(event));
                                await this.logout();
                            }
                        }
                        break;
                }
            }
        }

        bootstrapGoogleAnalytics(user: User) {
            // Only external users should be tracked in Google Analytics
            if (!user.isInternal) {
                // console.debug(`📊 Adding Google Analytics code for user ${user.firstName} ${user.lastName} (${user.email})`);
                bootstrapGoogleAnalytics();
            }
        }

        hideSessionTimeoutModal() {
            if (this.sessionTimeoutModal) {
                this.sessionTimeoutModal.hide();
            }
        }

        async keepBuilding() {
            // console.debug('🔥 KEEP BUILDING TRIGGERED @ ' + (new Date()).toLocaleTimeString());
            setCookie( 'pbAuthAt', currentTimestamp());
            // TODO: move this logic to a single place?
            const timeout = getSecondsFromMinutes(this.user.inactiveTimeout) || defaultSessionTimeoutSeconds;
            const expiration = futureTimestamp(new Date(currentTimestamp()), 'seconds', timeout || 0)
            window.localStorage.setItem('pbexp', expiration!);
            this.hideSessionTimeoutModal();
            await this.setSessionTimer();
        }

        async logout(arg: string = '') {
            if (this.$route.name !== 'login') {
                // window.alert(`🎯 Logout aborted: ${arg}`);
                this.appStore.setLoader(this.logoutLoader);
                this.lastPath = isValidLastPath(this.$route.fullPath) ? this.$route.fullPath : '/';
                await this.appStore.logout({lastPath: this.lastPath, rememberMe: arg !== 'clearMultifactor'});

                await nextTick(() => {
                    setTimeout(async () => {
                        await this.$router.push({path: '/login'});
                        this.appStore.clearLoader(this.logoutLoader);
                    }, 5000);
                });
            }
        }

        prepareLogout() {
            // console.debug('🔨 Logout preparation called...')
            this.appStore.setLoader(this.logoutLoader);
            this.appEvent = '';

            if ( saveStateRouteNames.includes( this.$route.name! ) ) {
                this.appEvent = 'saveDraft';
                this.logoutModalMessage = 'Your progress is being saved...';
            } else {
                this.appEvent = '';
                this.logoutModalMessage = 'Logging out...';
            }
        }

        setSaveStatus( status: string ) {
            this.saveDraftStatus = status || '';
        }

        setSectionLoading( loading: boolean ) {
            this.sectionLoading = loading;
        }

        async setSessionTimer() {
            const timeout = getSecondsFromMinutes(this.user.inactiveTimeout) || defaultSessionTimeoutSeconds;
            const countdown = defaultSessionCountdownSeconds
            // console.debug(`🕗 SET SESSION TIMER\r\n-> Timeout: ${timeout}\r\n -> Countdown: ${countdown}\r\n -> Current: ${(new Date()).toLocaleTimeString()}`);
            if (window.Worker) {
                let session: UserSession = {
                    source: sessionTimerSourceName,
                    active: Boolean(window.localStorage.getItem('pbuser')) || false,
                    timeout,
                    countdown,
                    action: 'initialize',
                    route: this.$route.name,
                    noAuthRoutes: publicRouteNames(this.routes)
                }

                this.worker.postMessage(session);
                this.worker.onmessage = async (message: any) => {
                    if (message.data.action) {
                        this.session = message.data;

                        switch (this.session.action) {
                            case 'countdown':
                                this.showSessionTimeoutModal();
                                break;
                            case Actions.LOGOUT_PENDING:
                                // console.debug(`🧠 Preparing logout:\n ${JSON.stringify(message)}`);
                                this.prepareLogout();
                                break;
                            case Actions.LOGOUT:
                                this.appStore.clearLoader(this.logoutLoader);
                                this.hideSessionTimeoutModal();

                                if (this.$route.name !== 'login') {
                                    closeAllModals(['loginModal']);
                                    // console.debug(`🧠🧠🧠 Logging out due to session worker:\n ${JSON.stringify(message)}`);
                                    await this.logout();
                                }

                                break;
                            default:
                                this.hideSessionTimeoutModal();
                                this.user.session = this.session;
                                break;
                        }
                    }
                }
            } else {
                console.warn('Your browser doesn\'t support web workers.');
            }
        }

        showSessionTimeoutModal() {
            this.sessionTimeoutModal!.show();
        }

        async userAuthenticated() {
            return await this.userStore.setUserAuthentication();
        }

        @Watch('$route.path', {immediate: true})
        async onRouteChange(newVal: string, oldVal: string) {
            this.$eventBus.emit(Events.HIDE_TOOLTIPS);
            if (oldVal && newVal) {
                if (this.user.id && !oldVal.includes('/login') && !newVal.includes('/login')) {
                    await this.keepBuilding();
                }
            }
        }

        @Watch('navigation', {immediate: true, deep: true})
        async onNavigationChange(newVal: any, oldVal: any) {
            if (newVal === RequestStatus.SUCCESS) { // TODO: this will never be true, is this necessary anymore?
                this.appStore.clearLoader(Actions.NAVIGATING);
            }
        }

        @Watch('saveDraftStatus', {immediate: true})
        async onSaveStatusChange(newVal: any, oldVal: any) {
            if (oldVal && newVal) {
                switch (newVal) {
                    case RequestStatus.SUCCESS:
                        this.lastPath = '/';
                        break;
                    default:
                        this.lastPath = isValidLastPath(this.$route.fullPath) ? this.$route.fullPath : '/';
                        break;
                }

                await this.logout();
            }
        }

        @Watch('user.id', {immediate: true})
        async onUserIdChange(userId: any/*, oldUserId: any*/) {
            if (userId !== '' && this.user?.lastLogin) {
                // Authenticated user
                this.bootstrapGoogleAnalytics(this.user);
                await nextTick(async () => {
                    await this.setSessionTimer();
                });
            }
        }

        @Watch('userInteractionTimestamp')
        async onUserInteractionTimestamp() {
            await this.keepBuilding();
        }
    }

    export default toNative(App);
</script>
