import {defineStore} from 'pinia';
import Vue, {toRaw} from 'vue';
import clone from 'lodash-es/clone';
import cloneDeep from 'lodash-es/cloneDeep';
import {AxiosResponse} from 'axios';
import api from 'Server/api';
import {useAccountStore, Account} from 'Stores/account';
import {
    ConglomerateRfmBrand,
    ConglomerateRfmMarket,
    ListParameters,
    Operator,   PhotoAssignment,
    PhotoCharacteristics,
    SubmitParameter,
    SubmitOption
} from 'Stores/common/models';
import {FileMeta, useFileStore} from 'Stores/file';
import {Job} from 'Stores/job';
import {Topic} from 'Stores/topic';
import {useUserStore, defaultUserTimestamp, UserPhoto, UserTimestamp, User} from 'Stores/user';
import {GroupDefinitionType, Operators, RequestError} from 'Utilities/immutables';
import {currentTimestamp} from 'Utilities/time-utils';
import {
    isEmptyObject,
    axiosResponse,
    socialContentIcon,
    sortArray,
    sortByProperty,
    isEmptyArray,
    deleteObjectProperties,
    dateFormat,
    isNumeric,
    isString
} from 'Utilities/utils';
import {isEmptyString, isUndefinedOrNullOrEmpty} from 'Utilities/inspect';
import {geographicSelectionData} from 'Utilities/reports';

export interface PersonaState {
    persona: Persona,
    servicePersona: ServicePersona,
    originalPersona: OriginalPersona,
    selectableDemographics: Array<any>,
    selectableGeographics: Array<any>,
    selectableHighLevelRfm: Array<any>,
    accountTopics: Array<Topic>,
    selectableTopics: SelectableTopics,
    topicCount: number,
    selectableConsumerIndicators: SelectableConsumerIndicators,
    subHeaders: Object,
    socialFollowsAtLeastAutoAdjusted: boolean,
    socialContentAtLeastAutoAdjusted: boolean,
    consumerIndicatorsAtLeastAutoAdjusted: boolean
}

export interface Persona {
    id: string,
    account: {
        id: string,
    },
    accountId: string,
    userId: string,
    name: string,
    mode: string,
    isDraft: boolean,
    photos?: PhotoAssignment[],
    photoCharacteristics?: PhotoCharacteristics | null,
    userPhoto: UserPhoto | null,
    tags: string[],
    operator: string,
    conglomerateRfm: {
        markets: ConglomerateRfmMarket[],
        operator: string,
    },
    conglomerateRfmBrand: {
        brands: ConglomerateRfmBrand[],
        operator: string,
    },
    demographics: any,
    geographics: any,
    highLevelRfm: any,
    social: any,
    consumer: any,
    lists: any,
    topics: Array<any>,
    containsSensitiveData?: boolean,
    submitParameters: Array<SubmitParameter>,
    retiredCharacteristics: any,
    draft?: boolean,
    created: UserTimestamp,
    updated: UserTimestamp,
    fetchedAt: string,
}

export interface ServicePersona {
    id: string,
    account: {
        id: string,
        name: string,
        logoFile: {
            id: string,
            filePathUri: string,
        },
    },
    accountId: string,  // Temp until post persona no longer needs
    userId: string,     // Temp until post persona no longer needs
    name: string,
    groupDefinition: GroupDefinition,
    photos: any[],
    photoCharacteristics: PhotoCharacteristics | null,
    containsSensitiveData: boolean,
    submitParameters: any[],
    tags: string[],
    topics: any[],
    fetchedAt: string,
    userPhoto: UserPhoto,
    created: {
        user: {
            id: string,
            firstName: string,
            lastName: string
        },
        timestamp: string
    },
    updated: {
        user: {
            id: string,
            firstName: string,
            lastName: string
        },
        timestamp: string
    }
}

export interface OriginalPersona extends Persona {
    groupDefinition: GroupDefinition,
}

export interface PersonaJob extends Job {
    persona: Persona,
}

// user-selected via Upload Lists operator (default OR)
export const defaultPersonaOperator = Operators.OR;

export interface GroupDefinition {
    include: {
        operator: string, // Note: see defaultPersonaOperator
        subgroups: SubGroupDefinition[]
    },
    exclude: {
        operator: string, // hard-coded
        subgroups: SubGroupDefinition[]
    }
}

export interface SubGroupDefinition {
    conglomerateRfm?: {
        markets: ConglomerateRfmMarket[],
        operator: string, // user-selected
    },
    conglomerateRfmBrand?: {
        brands: ConglomerateRfmBrand[],
        operator: string, // TODO: make user-selected
    },
    consumerSpend?: {
        minimum: number,
        operator: string, // user-selected via Consumer Spend control
        spendingIndicators: any[],
    },
    highLevelRfm?: {
        variables: any,
    },
    lists?: {
        definitions: any[],
        operator: string, // hard-coded
    }
    offline?: {
        demographics: any,
        geographics: any,
    },
    operator: string, // hard-coded
    social?: {
        content?: {
            endDate: string,
            minimum: number,
            operator: string, // user-selected via Social Content control
            seeds: any[],
            startDate: string,
        }
        follow?: {
            minimum: number,
            operator: string, // user-selected via Social Follows control
            seeds: any[],
        },
        operator: string, // hard-coded
    },
}

export enum PersonaTabs {
    // TABS
    CHARACTERISTICS = 'characteristics',
    CONFIRMATION = 'confirmation',
    TOPICS = 'topics',

    // SECTIONS
    APPENDS = 'appends',
    CONGLOMERATERFM = 'market-affinity',
    CONGLOMERATERFMBRANDS = 'brand-affinity',
    CONSUMER = 'consumer-spending',
    COPY = 'copyPersona',
    DEMOGRAPHICS = 'demographics',
    GEOGRAPHY = 'geographic-areas',
    HIGHLEVELRFM = 'high-level-rfm',
    LISTS = 'lists',
    SOCIAL = 'social-affinity',
}

export enum PersonaLoaders {
    PREP = 'preparing persona build',
    DEMOGRAPHICS = 'loading demogs',
    TOPICS = 'loading topics',
    FILES = 'loading files',
    CONSUMER = 'loading consumer indicators',
    VALIDATING = 'validating',
    SEARCHING = 'searching',
    SAVING = 'saving persona',
    CLEARING = 'clearing'
}

export enum PersonaMode {
    NEW = 'new',
    BUILD = 'build',
    REBUILD = 'rebuild',
    PREPEDIT = 'prepedit',
    EDIT = 'edit',
    COPY = 'copy',
    DELETE = 'delete',
    RESET = 'reset',
    REPORT = 'report',
    DRAFT = 'draft',
    COMPARE = 'compare'
}

export enum PersonaCharacteristics {
    SPENDING_INDICATORS = 'spendingIndicators'
}

export const geographicFields = [/*'dma_code', 'postal_code',*/ 'region', 'state'];
export const groupDefinitionTypes: string[] = [GroupDefinitionType.INCLUDE, GroupDefinitionType.EXCLUDE];

export interface SelectableDemographicFilter {
    value: string,
    position: number,
    omitted: boolean,
    selected: boolean
    parent: string,
    parentPosition: number,
    shortDescription: string,
    longDescription: string,
    footprintDescription: string,
    allowOmit: boolean,
}

export interface SelectableTopics {
    params: ListParameters,
    topics: Array<Topic>
}

export interface ConsumerIndicator {
    id: string,
    name: string,
    isActive: boolean,
    shortDescription: string,
    longDescription: string,
    type: string,
    category: string,
    subCategory: string,
    positive?: boolean,
    selected?: boolean
}

export interface SelectableConsumerIndicators {
    params: ListParameters,
    indicators: Array<ConsumerIndicator>
}

export interface SocialFollow extends SocialFollowSeed {
    positive?: boolean;
    selected?: boolean;
}

export interface SocialFollowSeed {
    name: string,
    twitterHandle: string,
    instagramInterest?: string,
    instagramTitle?: string
}

export interface SocialContentSeed {
    twitterContent: string
}

export interface SocialContent {
    content: string,
    positive: boolean;
    selected: boolean;
    icon?: string;
}

export const defaultGroupDefinition: GroupDefinition = {
    include: {
        operator: defaultPersonaOperator,
        subgroups: [
            {
                conglomerateRfm: {
                    markets: [],
                    operator: Operators.OR, // user-selected
                },
                conglomerateRfmBrand: {
                    brands: [],
                    operator: Operators.OR, // TODO: make user-selected
                },
                consumerSpend: {
                    minimum: 1,
                    operator: Operators.OR, // user-selected via Consumer Spend control
                    spendingIndicators: [],
                },
                highLevelRfm: {
                    variables: {},
                },
                offline: {
                    demographics: {},
                    geographics: {},
                },
                operator: Operators.AND, // hard-coded
                social: {
                    content: {
                        endDate: '',
                        minimum: 1,
                        operator: Operators.OR, // user-selected via Social Content control
                        seeds: [],
                        startDate: '',
                    },
                    follow: {
                        minimum: 1,
                        operator: Operators.OR, // user-selected via Social Follows control
                        seeds: [],
                    },
                    operator: Operators.OR, // hard-coded
                },
            },
            {
                operator: Operators.OR, // hard-coded
                lists: {
                    operator: Operators.OR, // hard-coded
                    definitions: [],
                }
            },
        ]
    },
    exclude: {
        operator: defaultPersonaOperator,
        subgroups: [
            {
                highLevelRfm: {
                    variables: {}
                },
                offline: {
                    demographics: {},
                    geographics: {},
                },
                operator: Operators.OR, // hard-coded
                social: {
                    content: {
                        endDate: '',
                        minimum: 1,
                        operator: Operators.OR, // hard-coded
                        seeds: [],
                        startDate: '',
                    },
                    follow: {
                        minimum: 1,
                        operator: Operators.OR, // hard-coded
                        seeds: [],
                    },
                    operator: Operators.OR // hard-coded
                },
            },
            {
                operator: Operators.OR, // hard-coded
                lists: {
                    definitions: [],
                    operator: Operators.OR, // hard-coded
                }
            }
        ],
    },
};

export const defaultServicePersona = {
    id: '',
    accountId: '',  // Temp until post persona no longer needs
    userId: '',     // Temp until post persona no longer needs
    account: {
        id: '',
        name: 'Delivery Testing',
        logoFile: {
            id: '',
            filePathUri: '',
        },
    },
    name: '',
    photos: [],
    photoCharacteristics: null,
    containsSensitiveData: false,
    tags: [ '' ],
    topics: [],
    groupDefinition: cloneDeep( defaultGroupDefinition ),
    submitParameters: [],
    fetchedAt: '',
    userPhoto: {
        id: '',
        name: '',
        filePathUri: ''
    },
    created: cloneDeep(defaultUserTimestamp),
    updated: cloneDeep(defaultUserTimestamp)
}

export const defaultRetiredCharacteristics = {
    spendingIndicators: false
}

export const defaultSelectableTopicsParameters: ListParameters = {
    limit: 100, // Hardcode for now
    offset: 0,
    sort: 'lastUsed',
    order: 'desc',
    search: '',
    append: false,
    public: null,
};

export const defaultSelectableConsumerIndicatorsParameters: ListParameters = {
    limit: 55, // Hardcode for now
    offset: 0,
    sort: 'lastUsed',
    order: 'desc',
    search: '',
    append: false
};

// export const characteristicsSubHeader: string = 'Choose Characteristics of People that are Important for your Persona';
export const characteristicsByContext = (characteristics: any) => {
    let contexts = {
        selected: {},
        omitted: {},
    }

    if (characteristics) {
        for (const key of Object.keys(characteristics)) {
            const context = characteristics[key].some((characteristic: any) => characteristic.selected) ? 'selected' : 'omitted';
            contexts[context][key] = characteristics[key];
        }
    }

    return contexts;
}

export const usePersonaStore = defineStore( 'persona', {
    state: (): PersonaState => ( {
        persona: {
            id: '',
            account: {
                id: ''
            },
            accountId: '',
            userId: '',
            name: '',
            mode: '',
            isDraft: false,
            photos: [],
            photoCharacteristics: null,
            tags: [ '' ],
            operator: defaultPersonaOperator,
            conglomerateRfm: {
                markets: [],
                operator: Operators.OR,
            },
            conglomerateRfmBrand: {
                brands: [],
                operator: Operators.OR,
            },
            consumer: {
                operator: Operators.OR,
                minimum: 1,
                spendingIndicators: []
            },
            demographics: {},
            geographics: {},
            highLevelRfm: {},
            lists: {
                operator: Operators.OR, // hardcoded
                definitions: []
            },
            social: {
                operator: Operators.OR,
                follow: {
                    operator: Operators.OR,
                    minimum: 1,
                    seeds: [],
                },
                content: {
                    startDate: '',
                    endDate: '',
                    operator: Operators.OR,
                    minimum: 1,
                    seeds: [],
                }
            },
            topics: [],
            containsSensitiveData: false,
            submitParameters: [],
            retiredCharacteristics: clone( defaultRetiredCharacteristics ),
            fetchedAt: '',
            userPhoto: {
                id: '',
                name: '',
                filePathUri: ''
            },
            created: cloneDeep(defaultUserTimestamp),
            updated: cloneDeep(defaultUserTimestamp),
        },
        servicePersona: cloneDeep( defaultServicePersona ),
        originalPersona: {
            groupDefinition: cloneDeep( defaultGroupDefinition )
        } as OriginalPersona,
        accountTopics: [],
        consumerIndicatorsAtLeastAutoAdjusted: false,
        selectableConsumerIndicators: {
            params: {
                limit: defaultSelectableTopicsParameters.limit,
                offset: defaultSelectableTopicsParameters.offset,
                sort: defaultSelectableTopicsParameters.sort,
                order: defaultSelectableTopicsParameters.order,
                search: ''
            },
            indicators: []
        },
        selectableDemographics: [],
        selectableGeographics: [],
        selectableHighLevelRfm: [],
        selectableTopics: {
            params: {
                limit: defaultSelectableTopicsParameters.limit,
                offset: defaultSelectableTopicsParameters.offset,
                sort: defaultSelectableTopicsParameters.sort,
                order: defaultSelectableTopicsParameters.order,
                search: ''
            },
            topics: []
        },
        socialFollowsAtLeastAutoAdjusted: false,
        socialContentAtLeastAutoAdjusted: false,
        subHeaders: {
            // appends: 'Choose Data Bundles for Append to Uploaded Files',
            // characteristics: 'Choose Characteristics of People that are Important for your Persona',
            characteristics: {
                'consumer-spending': 'Choose Consumer Spending of People to Include in your Persona',
                'demographics': 'Choose Demographics of People to Include or Exclude in your Persona',
                'geographic-areas': 'Choose Geographic Areas',
                'high-level-rfm': 'Choose Any RFM Data to Include or Exclude in your Persona',
                'brand-affinity': 'Choose Brands to limit your Persona to People with Above Average Spend with those Brands',
                'market-affinity': 'Choose Markets to limit your Persona to People with Above Average Spend in those Markets',
                'social-affinity': 'Choose Social Affinities of People to Include or Exclude in your Persona',
                'lists': 'Upload a List of People to Include or Exclude in your Persona',
            },
            copyPersona: 'Choose an existing Persona from which to Copy the parameters',
            topics: 'Choose Topics of Interest to Analyze and Display as Additional Charts in the Persona Report',
        },
        topicCount: 0
    } ),

    getters: {
        getAccountTopics: (state: PersonaState): Array<Topic> => state.accountTopics,
        getConglomerateRfm: (state: PersonaState) => state.persona.conglomerateRfm,
        getConglomerateRfmBrands: (state: PersonaState) => state.persona.conglomerateRfmBrand,
        getConsumer: (state: PersonaState) => state.persona.consumer,
        getConsumerIndicators: (state: PersonaState) => state.persona.consumer?.spendingIndicators,
        getConsumerIndicatorsAtLeastAutoAdjusted: (state: PersonaState) => state.consumerIndicatorsAtLeastAutoAdjusted,
        getConsumerIndicatorsMinimum: (state: PersonaState) => state.persona.consumer?.minimum,
        getConsumerIndicatorsOperator: (state: PersonaState) => state.persona.consumer?.operator,
        getDemographics: (state: PersonaState) => state.persona.demographics,
        getDemographicsByContext: (state: PersonaState) => characteristicsByContext(state.persona.demographics),
        getGeographics: (state: PersonaState) => state.persona.geographics,
        getGeographicsByContext: (state: PersonaState) => characteristicsByContext(state.persona.geographics),
        getGroupDefinitionOperator: (state: PersonaState) => state.persona.operator,
        getHighLevelRfm: (state: PersonaState) => state.persona.highLevelRfm,
        getHighLevelRfmByContext: (state: PersonaState) => characteristicsByContext(state.persona.highLevelRfm),
        getListFiles: (state: PersonaState) => state.persona.lists?.definitions || [],
        getNegativeConsumerIndicators: (state: PersonaState) => [], // Note: We currently only support positive consumer indicators
        getNegativeListFiles: (state: PersonaState) => state.persona.lists?.definitions.filter((file: FileMeta) => file.selected && !file.positive), // Note: Currently we only support one include file and one exclude file per persona
        getNegativeSocialContent: (state: PersonaState) => state.persona.social?.content?.seeds.filter((socialContent: SocialContent) => socialContent.selected && !socialContent.positive),
        getNegativeSocialFollows: (state: PersonaState) => state.persona.social?.follow?.seeds.filter((follow: SocialFollow) => follow.selected && !follow.positive),
        getOriginalPersona: (state: PersonaState) => state.originalPersona,
        getPersona: (state: PersonaState) => state.persona,
        getPersonaSubHeaders: (state: PersonaState) => state.subHeaders,
        getPersonaTags: (state: PersonaState) => state.persona.tags,
        getPositiveConsumerIndicators: (state: PersonaState) => state.persona.consumer?.spendingIndicators.filter((indicator: ConsumerIndicator) => indicator.selected && indicator.positive),
        getPositiveListFiles: (state: PersonaState) => state.persona.lists?.definitions.filter((file: FileMeta) => file.selected && file.positive),
        getPositiveSocialContent: (state: PersonaState) => state.persona.social?.content?.seeds.filter((socialContent: SocialContent) => socialContent.selected && socialContent.positive),
        getPositiveSocialFollows: (state: PersonaState) => state.persona.social?.follow?.seeds.filter((follow: SocialFollow) => follow.selected && follow.positive),
        getSelectableConsumerIndicators: (state: PersonaState) => state.selectableConsumerIndicators.indicators,
        getSelectableConsumerIndicatorsParameters: (state: PersonaState) => state.selectableConsumerIndicators.params,
        getSelectableDemographics: (state: PersonaState) => state.selectableDemographics,
        // getSelectableGeographics: (state: PersonaState) => state.selectableGeographics,
        getSelectableHighLevelRfm: (state: PersonaState) => state.selectableHighLevelRfm,
        getSelectableTopics: (state: PersonaState) => state.selectableTopics.topics,
        getSelectableTopicsParameters: (state: PersonaState) => state.selectableTopics.params,
        getSocial: (state: PersonaState) => state.persona.social,
        getSocialContentAtLeastAutoAdjusted: (state: PersonaState) => state.socialContentAtLeastAutoAdjusted,
        getSocialContentEndDate: (state: PersonaState) => state.persona.social?.content?.endDate,
        getSocialContentMinimum: (state: PersonaState) => state.persona.social?.content?.minimum,
        getSocialContentOperator: (state: PersonaState) => state.persona.social?.content?.operator,
        getSocialContentStartDate: (state: PersonaState) => state.persona.social?.content?.startDate,
        getSocialFollowsAtLeastAutoAdjusted: (state: PersonaState) => state.socialFollowsAtLeastAutoAdjusted,
        getSocialFollowsMinimum: (state: PersonaState) => state.persona.social?.follow?.minimum,
        getSocialFollowsOperator: (state: PersonaState) => state.persona.social?.follow?.operator,
        getSocialOperator: (state: PersonaState) => state.persona.social?.operator,
        getSubmitParameters: (state: PersonaState) => state.persona.submitParameters,
        getTopicCount: (state: PersonaState) => state.topicCount,
        getTopics: (state: PersonaState) => state.persona.topics,
    },

    actions: {
        async clearConsumerIndicatorsSearchResults() {
            try {
                const defaultParams: ListParameters = cloneDeep( defaultSelectableConsumerIndicatorsParameters );
                await this.setSelectableConsumerIndicatorsParameters( defaultParams );
                this.selectableConsumerIndicators.indicators = [];

                return true;
            } catch ( error ) {
                console.error( error );
                return false;
            }
        },

        clearConsumerIndicatorSelections() {
            try {
                if ( this.persona.hasOwnProperty( 'consumer' ) ) {
                    let consumer: any = {
                        operator: Operators.OR,
                        minimum: 1,
                        spendingIndicators: []
                    };

                    this.persona.consumer = consumer;
                }

                return true;
            } catch ( error ) {
                console.error( error );
                return false;
            }
        },

        clearDemographics() {
            try {
                this.setDemographics( [] );
                this.setOriginalPersonaDemographics( [] );
                this.selectableDemographics = [];
            } catch ( error ) {
                console.error( error );
            }
        },

        clearGeographics() {
            try {
                this.setGeographics([]);
                this.setOriginalPersonaGeographics([]);
                this.selectableGeographics = [];
            } catch (error) {
                console.error(error);
            }
        },

        clearHighLevelRfm() {
            try {
                this.setHighLevelRfm([]);
                this.setSelectableHighLevelRfm([]);
                this.setOriginalPersonaHighLevelRfm([]);
            } catch (error) {
                console.error(error);
            }
        },

        clearPersonaState() {
            try {
                // TODO: Need to refactor this at some point - defaultState clone wasn't working
                this.persona.id = '';
                this.persona.fetchedAt = '';
                this.persona.mode = '';
                this.persona.name = '';
                this.persona.isDraft = false;
                this.persona.operator = defaultPersonaOperator;
                this.persona.created = clone( defaultUserTimestamp );
                this.persona.updated = clone( defaultUserTimestamp );
                this.persona.photoCharacteristics = null;
                this.persona.userPhoto = null;
                this.persona.tags = [];
                this.persona.conglomerateRfm = {};
                this.persona.conglomerateRfmBrand = {};
                this.persona.demographics = {};
                this.persona.geographics = {};
                this.persona.highLevelRfm = {};
                this.persona.social = {
                    operator: Operators.OR,
                    follow: {
                        operator: Operators.OR,
                        minimum: 1,
                        seeds: []
                    },
                    content: {
                        startDate: '',
                        endDate: '',
                        operator: Operators.OR,
                        minimum: 1,
                        seeds: []
                    }
                };
                this.persona.topics = [];
                this.persona.lists = {
                    operator: Operators.OR,
                    definitions: []
                };
                this.persona.consumer = {
                    operator: Operators.OR,
                    minimum: 1,
                    spendingIndicators: []
                };
                this.persona.submitParameters = [];
                this.persona.retiredCharacteristics = clone( defaultRetiredCharacteristics );
                this.selectableDemographics = [];
                // this.selectableGeographics = [];
                this.selectableHighLevelRfm = [];
                this.selectableTopics.params = defaultSelectableTopicsParameters;
                this.selectableTopics.topics = [];
                this.originalPersona.groupDefinition = cloneDeep( defaultGroupDefinition );
                this.originalPersona.topics = [];

                return true;
            } catch ( error ) {
                console.error( error );
                return false;
            }
        },

        clearServicePersonaEmptyDefinitionSubgroups() {
            try {
                let newPersona: ServicePersona = cloneDeep(this.servicePersona);

                groupDefinitionTypes.forEach(type => {
                    for (let i = 0; i < newPersona.groupDefinition[type].subgroups?.length; ++i) {
                        let newSubGroup: any = newPersona.groupDefinition[type].subgroups[i];

                        if (newSubGroup) {
                            const keys = Object.keys(newSubGroup);

                            if (keys.length > 1) {
                                for (const subgroupKey of keys) {
                                    let newSubGroupObj: any = newSubGroup[subgroupKey];
                                    const subgroupObjKeys = Object.keys(newSubGroupObj);

                                    for (const subgroupObjKey of subgroupObjKeys) {
                                        if (!isString(newSubGroupObj[subgroupObjKey]) && !isNumeric(newSubGroupObj[subgroupObjKey]) && isEmptyObject(newSubGroupObj[subgroupObjKey])) {
                                            delete newPersona.groupDefinition[type].subgroups[i][subgroupKey][subgroupObjKey]
                                        }
                                        if (isEmptyObject(newSubGroupObj)) {
                                            delete newPersona.groupDefinition[type].subgroups[i][subgroupKey];
                                        }
                                    }

                                    if (subgroupObjKeys.length === 1 && newSubGroupObj.hasOwnProperty('operator')) {
                                        delete newPersona.groupDefinition[type].subgroups[i][subgroupKey];
                                    }
                                }

                                newPersona.groupDefinition[type].subgroups = newPersona.groupDefinition[type].subgroups
                                    .filter(subgroupItem => Object.keys(subgroupItem).length > 1 || !subgroupItem.hasOwnProperty('operator'));
                            } else if (!keys.length || (keys[i] === 'operator')) {
                                // The only key in this subgroup is the operator - delete it
                                newPersona.groupDefinition[type].subgroups.splice(i, 1);
                                --i;
                            }
                        }
                    }
                } );

                this.servicePersona = newPersona;

                // Clear unused items from the service persona
                // delete this.servicePersona.demographics;
                // delete this.servicePersona.geographics;

                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        clearSocialSelections() {
            try {
                if ( this.persona.hasOwnProperty( 'social' ) ) {
                    this.persona.social = cloneDeep(defaultGroupDefinition.include.subgroups[0].social);
                }
                return true;
            } catch ( error ) {
                console.error( error );
                return false;
            }
        },

        clearSocialFollowSelection( socialFollow: SocialFollow ) {
            if ( this.persona.social && ('follow' in this.persona.social && 'seeds' in this.persona.social.follow )) {
                const existingIndex = this.persona.social.follow.seeds.findIndex( seed => ( seed && (seed.twitterHandle === socialFollow.twitterHandle && !seed.selected) ) );
                if ( existingIndex > -1 ) {
                    this.persona.social.follow.seeds.splice(existingIndex, 1);
                }
            }
        },

        clearSocialContentSelection( socialContent: SocialContent ) {
            if ( this.persona.social && ('content' in this.persona.social && 'seeds' in this.persona.social.content )) {
                const existingIndex = this.persona.social.content.seeds.findIndex( seed => ( seed?.content === socialContent?.content && !seed.selected ) );
                if ( existingIndex > -1 ) {
                    this.persona.social.content.seeds.splice(existingIndex, 1);
                }
            }
        },

        async deletePersona( params: any ) {
            const accountId = useUserStore().getUser.currentAccountId;
            let deleted: boolean = false;

            try {
                const response = await api.getAxiosInstance.delete( `/api/accounts/${accountId}/personas/${params.id}` );

                if ( response.data ) {
                    deleted = true;
                }
            } catch ( error ) {
                // TODO: Need to add logger
                console.error( error );
            }

            return deleted;
        },

        deselectPersonaFile(file: FileMeta, context: string = 'definitions') {
            this.persona.lists[context] = this.persona.lists[context]
                .filter((listFile: FileMeta) => listFile.id !== file.id);
        },

        async generatePersonaShareToken( personaJob: PersonaJob ) {
            try {
                const body: any = {};
                const response = await api.getAxiosInstance.post( `/api/accounts/${personaJob.persona.account.id}/personas/${personaJob.persona.id}/jobs/${personaJob.id}/share`, body );

                return response.data?.data || {};
            } catch ( error ) {
                console.error( error );
                return false;
            }
        },

        getCharacteristicObject(characteristicArray: Array<any>) {
            // Set sorted characteristic array back to object
            let sortedCharacteristic: Object = {};

            try {
                // for ( let j = 0; j < characteristicArray.length; j++ ) {
                for (let characteristic of characteristicArray) {
                    // let characteristic = characteristicArray[ j ];
                    let valueArray: any = [];
                    let sortedObject: Object = {};

                    for (let k = 0; k < characteristic.length; k++) {
                        let characteristicValue = characteristic[k];

                        if (characteristicValue) {
                            let delimiter: String = characteristic.footprint.values === '' ? '' : ', ';

                            if (characteristicValue.footprintDescription != null) {
                                characteristic.footprint.description = characteristicValue.footprintDescription;
                            }

                            valueArray.push(characteristicValue);
                            characteristic.footprint.values += delimiter + characteristicValue.shortDescription;
                        }
                    }

                    // Set sorted value array and footprint property in new object and make reactive
                    sortedObject = valueArray;
                    sortedObject['footprint'] = characteristic.footprint;
                    sortedCharacteristic[characteristic[0]?.parent] = sortedObject;
                }
            } catch (error) {
                throw error;
            }

            return sortedCharacteristic;
        },

        async getConsumerIndicatorsByPersonaSelections(personaId: string) {
            try {
                if (personaId) {
                    const response: any = await api.getAxiosInstance.get(`/api/meta/characteristics/consumerSpend?personaId=${personaId}`);
                    const consumerIndicators = response.data;

                    if (consumerIndicators.data) {
                        return consumerIndicators.data;
                    } else {
                        throw `${RequestError.INVALID_RESPONSE}: consumer indicators by persona`;
                    }
                } else {
                    throw `${RequestError.INVALID_REQUEST_PARAMS}: consumer indicators by persona requires persona id`;
                }
            } catch (error) {
                // TODO: Need to add logger
                console.error(error);
            }
        },

        async getPersonaById( params: any = { shared: false } ) {
            try {
                const accountId: string = params.accountId || useUserStore().getUserCurrentAccountId;
                const shared: boolean = params.shared === 'true';
                const queryString: string = shared ? `?shared=true` : ``;
                const response = await api.getAxiosInstance.get( `/api/accounts/${accountId}/personas/${params.id}${queryString}` );

                return response.data?.data || null;
            } catch ( error ) {
                console.error( error );
            }
        },

        async getPersonasByIds(params: any) {
            try {
                const accountId: string = params.accountId || useUserStore().getUserCurrentAccountId;
                const shared: boolean = params.shared === true || params.shared === 'true';
                const queryString: string = shared ? `?shared=true` : ``;
                const endpoints = params.ids.map((id: string) => `/api/accounts/${accountId}/personas/${id}${queryString}`);
                const responses: AxiosResponse<any>[] = await Promise.all(endpoints.map((endpoint: string) => api.getAxiosInstance.get(endpoint)));
                let personas: Persona[] = [];

                for (let i: number = 0; i < responses.length; i++) {
                    if (responses[i].data.data) {
                        personas.push(responses[i].data.data);
                    }
                }

                return personas;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async getPersonaNameValidation( value: string ) {
            const accountId: string = useUserStore().getUserCurrentAccountId;
            const name: string = encodeURIComponent( value );

            try {
                const response = await api.getAxiosInstance.get( `/api/accounts/${accountId}/personas/names/${name}` );

                return response.data?.data;
            } catch ( error ) {
                // TODO: Need to add logger
                console.error( error );
            }
        },

        async killPersonaJob( params: any ) {
            try {
                const currentUser: User = useUserStore().getUser;
                const currentAccountId = currentUser.currentAccountId;

                if ( currentUser ) {
                    const body: any = { currentAccountId: currentAccountId, currentUserId: currentUser.id };
                    const response: any = await api.getAxiosInstance.patch( `/api/accounts/${currentAccountId}/personas/${params.id}/jobs/${params.jobId}/kill`, body );

                    if ( response.data ) {
                        return response.data;
                    } else {
                        throw RequestError.INVALID_RESPONSE;
                    }
                } else {
                    throw RequestError.INVALID_USER;
                }
              } catch ( error ) {
                // TODO: Need to add logger
                console.error( error );
            }
        },

        mapServicePersonaConglomerateRfmBrandValues(persona: Persona) {
            // const contexts = characteristicsByContext(persona.highLevelRfm);
            let newPersona: any = cloneDeep(this.servicePersona);

            const groupDefinitionContext = GroupDefinitionType.INCLUDE;
            const subgroupIndex = newPersona.groupDefinition[groupDefinitionContext].subgroups
                .findIndex((subgroup: any) => subgroup && subgroup.hasOwnProperty('conglomerateRfmBrand') && !subgroup.hasOwnProperty('lists'));
            let newSubGroup: any = newPersona.groupDefinition[groupDefinitionContext].subgroups[subgroupIndex];

            if (!isEmptyObject(persona.conglomerateRfmBrand)) {
                let conglomerateRfmBrand = structuredClone(persona.conglomerateRfmBrand);
                if (!conglomerateRfmBrand.operator) {
                    conglomerateRfmBrand.operator = Operators.OR;
                }

                // if (conglomerateRfmBrand.brands) {
                //     conglomerateRfmBrand.brands = conglomerateRfmBrand.brands.map((brand: ConglomerateRfmBrand) => {
                //         return brand;
                //     });
                // }

                newSubGroup.conglomerateRfmBrand = conglomerateRfmBrand;
            } else {
                // Remove the group definition record when no conglomerate RFM was selected
                delete (newSubGroup.conglomerateRfmBrand);
            }

            delete newPersona.conglomerateRfmBrand;
            this.servicePersona = newPersona;
        },

        mapServicePersonaConglomerateRfmValues(persona: Persona) {
            // const contexts = characteristicsByContext(persona.highLevelRfm);
            let newPersona: any = cloneDeep(this.servicePersona);

            const groupDefinitionContext = GroupDefinitionType.INCLUDE;
            const subgroupIndex = newPersona.groupDefinition[groupDefinitionContext].subgroups
                .findIndex((subgroup: any) => subgroup && subgroup.hasOwnProperty('conglomerateRfm') && !subgroup.hasOwnProperty('lists'));
            let newSubGroup: any = newPersona.groupDefinition[groupDefinitionContext].subgroups[subgroupIndex];

            if (!isEmptyObject(persona.conglomerateRfm)) {
                let conglomerateRfm = structuredClone(persona.conglomerateRfm);
                if (!conglomerateRfm.operator) {
                    conglomerateRfm.operator = Operators.OR;
                }

                if (conglomerateRfm.markets) {
                    conglomerateRfm.markets = conglomerateRfm.markets.map((market: ConglomerateRfmMarket) => {
                        return market;
                    });
                }

                newSubGroup.conglomerateRfm = conglomerateRfm;
            } else {
                // Remove the group definition record when no conglomerate RFM was selected
                delete (newSubGroup.conglomerateRfm);
            }

            delete newPersona.conglomerateRfm;
            this.servicePersona = newPersona;
        },

        mapServicePersonaConsumerIndicators( persona: Persona ) {
            let newPersona: any = cloneDeep(this.servicePersona);
            let selectedIndicators: Array<ConsumerIndicator> = persona.consumer.spendingIndicators;

            if ( !isEmptyArray( selectedIndicators ) ) {
                // TODO: pos/neg toggling will come later
                groupDefinitionTypes.forEach( ( type ) => {
                    for ( let i = 0; i < newPersona.groupDefinition[ type ].subgroups?.length; i++ ) {
                        let newSubGroup: any = newPersona.groupDefinition[ type ].subgroups[ i ];

                        if (newSubGroup && newSubGroup.hasOwnProperty( 'consumerSpend' )) {
                            newSubGroup.consumerSpend.operator = ( type === GroupDefinitionType.INCLUDE )
                                ? persona.consumer.operator
                                : Operators.OR;
                            newSubGroup.consumerSpend.minimum = persona.consumer.minimum;

                            for ( let j = 0; j < selectedIndicators.length; j++ ) {
                                if ( ( type === GroupDefinitionType.INCLUDE ) && selectedIndicators[j].positive ) {
                                    newSubGroup.consumerSpend.spendingIndicators.push( selectedIndicators[ j ] );
                                } else if ( ( type === GroupDefinitionType.EXCLUDE ) && !selectedIndicators[j].positive ) {
                                    newSubGroup.consumerSpend.spendingIndicators.push( selectedIndicators[ j ] );
                                }

                                delete( selectedIndicators[ j ].selected );
                                delete( selectedIndicators[ j ].positive );
                            }

                            switch ( type ) {
                                case GroupDefinitionType.INCLUDE:
                                    if ( newSubGroup.consumerSpend.spendingIndicators.length < 1 ) {
                                        delete( newSubGroup.consumerSpend );
                                    }
                                    break;
                                default:
                                    // Note: We currently only support positive (per Ianda)
                                    delete( newSubGroup.consumerSpend );
                                    break;
                            }

                            break;
                        }
                    }
                } );
            } else {
                groupDefinitionTypes.forEach( ( type ) => {
                    for ( let i = 0; i < newPersona.groupDefinition[ type ].subgroups?.length; i++ ) {
                        let newSubGroup: any = newPersona.groupDefinition[ type ].subgroups[ i ];

                        if (newSubGroup && newSubGroup.hasOwnProperty( 'consumerSpend' )) {
                            delete( newSubGroup.consumerSpend );
                            break;
                        }
                    }
                } );
            }

            delete newPersona['consumer'];
            this.servicePersona = newPersona;
        },

        mapServicePersonaDemographics( persona: Persona) {
            const contexts = characteristicsByContext(persona.demographics);
            let newPersona: any = cloneDeep( this.servicePersona );

            if (!isEmptyObject(persona.demographics)) {
                for (const contextName of Object.keys(contexts)) {
                    const context = contexts[contextName];
                    const groupDefinitionContext = (contextName === 'omitted') ? GroupDefinitionType.EXCLUDE : GroupDefinitionType.INCLUDE;
                    const subgroupIndex = newPersona.groupDefinition[groupDefinitionContext].subgroups
                        .findIndex(subgroup => subgroup && subgroup.hasOwnProperty('offline') && !subgroup.hasOwnProperty('lists'));
                    let newSubGroup: any = newPersona.groupDefinition[groupDefinitionContext].subgroups[subgroupIndex];
                    let values: any = {};

                    for (const key of Object.keys(context)) {
                        let demographic = context[key];
                        let valueArray: any = [];

                        for (let j = 0; j < demographic.length; j++) {
                            valueArray.push(demographic[j].value);
                        }

                        values[key] = valueArray;
                    }

                    newSubGroup.offline.demographics = cloneDeep(values);
                }
            } else if (isEmptyObject(persona.geographics)) {
                // Only delete the section if there aren't other definitions that use the same section
                for (const contextName of Object.keys(contexts)) {
                    const groupDefinitionContext = (contextName === 'omitted') ? GroupDefinitionType.EXCLUDE : GroupDefinitionType.INCLUDE;
                    const subgroup = newPersona.groupDefinition[groupDefinitionContext].subgroups
                        .find(subgroup => subgroup && subgroup.hasOwnProperty('offline') && !subgroup.hasOwnProperty('lists'));
                    if (subgroup) {
                        delete subgroup.offline;
                    }
                }
            }

            // newPersona['demographics'];
            this.servicePersona = newPersona;
        },

        mapServicePersonaGeographics(persona: Persona) {
            const contexts = characteristicsByContext(persona.geographics);
            let newPersona: any = cloneDeep(this.servicePersona);

            if (!isEmptyObject(persona.geographics)) {
                for (const contextName of Object.keys(contexts)) {
                    const context = contexts[contextName];
                    const groupDefinitionContext = (contextName === 'omitted') ?
                        GroupDefinitionType.EXCLUDE :
                        GroupDefinitionType.INCLUDE;
                    // TODO: create a new subgroup if Demographics aren't defined???
                    const subgroupIndex = newPersona.groupDefinition[groupDefinitionContext].subgroups
                        .findIndex(subgroup => subgroup && subgroup.hasOwnProperty('offline') && !subgroup.hasOwnProperty('lists'));
                    let newSubGroup: any = newPersona.groupDefinition[groupDefinitionContext].subgroups[subgroupIndex];
                    let values: any = {};

                    for (const key of Object.keys(context)) {
                        let geographic = context[key];
                        let valueArray: any = [];

                        for (let j = 0; j < geographic.length; j++) {
                            valueArray.push(geographic[j].code);
                        }

                        values[key] = valueArray;
                    }

                    newSubGroup.offline.demographics = Object.assign({}, newSubGroup.offline.demographics, cloneDeep(values));
                }
            } else if (isEmptyObject(persona.demographics)) {
                // Only delete the section if there aren't other definitions that use the same section
                for (const contextName of Object.keys(contexts)) {
                    const groupDefinitionContext = (contextName === 'omitted') ? GroupDefinitionType.EXCLUDE : GroupDefinitionType.INCLUDE;
                    const subgroup = newPersona.groupDefinition[groupDefinitionContext].subgroups
                        .find(subgroup => subgroup && subgroup.hasOwnProperty('offline') && !subgroup.hasOwnProperty('lists'));
                    if (subgroup) {
                        delete subgroup.offline;
                    }
                }
            }

            // newPersona['geographics'];
            this.servicePersona = newPersona;
        },

        mapServicePersonaHighLevelRfmValues( persona: Persona) {
            const contexts = characteristicsByContext(persona.highLevelRfm);
            let newPersona: any = cloneDeep( this.servicePersona );

            if (!isEmptyObject(persona.highLevelRfm)) {
                for (const contextName of Object.keys(contexts)) {
                    const context = contexts[contextName];
                    const groupDefinitionContext = (contextName === 'omitted') ? GroupDefinitionType.EXCLUDE : GroupDefinitionType.INCLUDE;
                    const subgroupIndex = newPersona.groupDefinition[groupDefinitionContext].subgroups
                        .findIndex(subgroup => subgroup && subgroup.hasOwnProperty('highLevelRfm') && !subgroup.hasOwnProperty('lists'));
                    let newSubGroup: any = newPersona.groupDefinition[groupDefinitionContext].subgroups[subgroupIndex];
                    let values: any = {};
                    for (const key of Object.keys(context)) {
                        let rfmVariable = context[key];
                        let valueArray: any = [];

                        for (let j = 0; j < rfmVariable.length; j++) {
                            valueArray.push(rfmVariable[j].value);
                        }

                        values[key] = valueArray;
                    }

                    newSubGroup.highLevelRfm.variables = cloneDeep(values);
                }
            } else {
                for (const contextName of Object.keys(contexts)) {
                    const groupDefinitionContext = (contextName === 'omitted') ? GroupDefinitionType.EXCLUDE : GroupDefinitionType.INCLUDE;
                    const subgroupIndex = newPersona.groupDefinition[groupDefinitionContext].subgroups
                        .findIndex(subgroup => subgroup && subgroup.hasOwnProperty('highLevelRfm') && !subgroup.hasOwnProperty('lists'));
                    let newSubGroup: any = newPersona.groupDefinition[groupDefinitionContext].subgroups[subgroupIndex];
                    delete (newSubGroup.highLevelRfm);
                }
            }

            newPersona['highLevelRfm'];
            this.servicePersona = newPersona;
        },

        mapServicePersonaListFiles( persona: Persona ) {
            let newPersona: any = cloneDeep( this.servicePersona );
            let selectedLists: Array<FileMeta> = persona.lists.definitions;

            if ( !isEmptyArray( selectedLists ) ) {
                groupDefinitionTypes.forEach( ( type ) => {
                    for ( let i = 0; i < newPersona.groupDefinition[ type ].subgroups?.length; i++ ) {
                        let newSubGroup: any = newPersona.groupDefinition[ type ].subgroups[ i ];

                        if (newSubGroup && newSubGroup.hasOwnProperty( 'lists' )) {
                            for ( let i = 0; i < selectedLists.length; i++ ) {
                                const listFile: FileMeta = selectedLists[ i ];
                                let definition: any = selectedLists[ i ];
                                definition.fileType = listFile.type;
                                delete( definition.selected );

                                if ( ( type === GroupDefinitionType.INCLUDE ) && ( definition.positive === true ) ) {
                                    newSubGroup.lists.definitions.push( definition );
                                } else if ( ( type === GroupDefinitionType.EXCLUDE ) && ( definition.positive === false ) ) {
                                    newSubGroup.lists.definitions.push( definition );
                                }
                            }

                            if ( newSubGroup.lists.definitions.length < 1 ) {
                                // No lists obj if none selected (per Ianda)
                                delete( newSubGroup.lists );
                            }
                        }
                    }
                } );
            } else {
                groupDefinitionTypes.forEach( ( type ) => {
                    for ( let i = 0; i < newPersona.groupDefinition[ type ].subgroups?.length; i++ ) {
                        let newSubGroup: any = newPersona.groupDefinition[ type ].subgroups[ i ];

                        if (newSubGroup && newSubGroup.hasOwnProperty( 'lists' )) {
                            delete newSubGroup['lists'];
                        }
                    }
                } );
            }

            delete newPersona['lists'];
            this.servicePersona = newPersona;
        },

        mapServicePersonaDefinition( persona: Persona ) {
            let newPersona: any = cloneDeep( this.servicePersona );
            let buildProperties: string[] = [ 'account', 'created', 'updated' ];

            if ( persona.isDraft || persona.mode === PersonaMode.EDIT ) {
                newPersona.fetchedAt = persona.fetchedAt;
            } else {
                buildProperties.push( 'fetchedAt' );
            }

            deleteObjectProperties( newPersona, buildProperties );

            newPersona.userId = persona.userId;
            newPersona.accountId = persona.accountId;
            newPersona.name = persona.name;
            newPersona.tags = this.persona.tags;
            groupDefinitionTypes.forEach( ( type ) => {
                newPersona.groupDefinition[ type ].operator = ( type === GroupDefinitionType.INCLUDE ) ? persona.operator : Operators.OR;
            } );

            this.servicePersona = newPersona;
        },

        async mapPersonaBuild( params: any ) {
            try {
                params.refresh = false;
                this.clearPersonaState();
                await this.setOriginalPersona( params );
                this.setAvailableDemographics( PersonaMode.EDIT );
                // this.setAvailableGeographics( PersonaMode.EDIT );
                this.setAvailableTopics();
                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        mapServicePersona(persona: ServicePersona) {
            (persona as any).isDraft = ((persona as any).mode === PersonaMode.DRAFT) ? true : false;

            if (!persona.hasOwnProperty('groupDefinition')) {
                persona.groupDefinition = cloneDeep(defaultGroupDefinition);
            }

            this.servicePersona = structuredClone(persona);
            delete this.servicePersona['mode'];
            delete this.servicePersona['retiredCharacteristics'];
            delete this.servicePersona['userPhoto'];
            delete this.servicePersona['operator'];
        },

        mapServicePersonaSubmitParameters( persona: Persona ) {
            const nonApiProperties: string[] = [ 'obsolete', 'autoDefaulted' ];
            let newPersona: any = cloneDeep( this.servicePersona );

            if ( !persona.submitParameters || persona.submitParameters.length === 0 || ( persona.submitParameters.length > 0 && !persona.submitParameters[ 0 ] ) ) {
                delete( newPersona.submitParameters );
            } else {
                for ( let i = 0; i < persona.submitParameters.length; i++ ) {
                    deleteObjectProperties( persona.submitParameters[ i ], nonApiProperties );

                    if ( persona.submitParameters[ i ].options  ) {
                        let options: SubmitOption[] = persona.submitParameters[ i ].options || [];

                        for ( let j = 0; j < options.length; j++ ) {
                            deleteObjectProperties( options[ j ], nonApiProperties );
                        }

                        persona.submitParameters[ i ].options = options;
                    }

                    if ( persona.submitParameters[ i ].submitOption ) {
                        let option: SubmitOption | null = persona.submitParameters[ i ].submitOption || null;

                        if ( option ) {
                            deleteObjectProperties( option, nonApiProperties );
                            persona.submitParameters[ i ].submitOption = option;
                        }
                    }
                }

                newPersona.submitParameters = persona.submitParameters;
            }

            this.servicePersona = newPersona;
        },

        mapServicePersonaSocialOperators( persona: Persona ) {
            let newPersona: any = cloneDeep( this.servicePersona );
            let socialFollows: Array<SocialFollow> = persona.social.follow?.seeds;
            let socialContent: Array<SocialContent> = persona.social.content?.seeds;

            if ( socialFollows.length > 0 || socialContent.length > 0 ) {
                groupDefinitionTypes.forEach( ( type ) => {
                    for ( let i = 0; i < newPersona.groupDefinition[ type ].subgroups?.length; i++ ) {
                        let newSubGroup: any = newPersona.groupDefinition[ type ].subgroups[ i ];

                        if (newSubGroup && !newSubGroup.hasOwnProperty( 'lists' ) ) {
                            if ( newSubGroup.hasOwnProperty( 'social' ) ) {
                                newSubGroup.social.operator = ( type === GroupDefinitionType.INCLUDE ) ? persona.social.operator : Operators.OR;
                                break;
                            }
                        }
                    }
                } );
            } else {
                groupDefinitionTypes.forEach( ( type ) => {
                    for ( let i = 0; i < newPersona.groupDefinition[ type ].subgroups?.length; i++ ) {
                        let newSubGroup: any = newPersona.groupDefinition[ type ].subgroups[ i ];

                        if (newSubGroup && newSubGroup.hasOwnProperty( 'social' )) {
                            delete( newSubGroup.social );
                            break;
                        }
                    }
                } );
            }

            delete newPersona['social'];
            this.servicePersona = newPersona;
        },

        mapServicePersonaSocialContent( persona: Persona ) {
            let newPersona: any = cloneDeep( this.servicePersona );
            let socialContent: Array<SocialContent> = persona.social.content?.seeds;
            let addContent: boolean = ( socialContent.length > 0 );

            if ( addContent ) {
                try {
                    groupDefinitionTypes.forEach( ( type ) => {
                        for ( let i = 0; i < newPersona.groupDefinition[ type ].subgroups?.length; i++ ) {
                            let newSubGroup: any = newPersona.groupDefinition[ type ].subgroups[ i ];

                            if (newSubGroup && !newSubGroup.hasOwnProperty( 'lists' )) {
                                if ( newSubGroup.hasOwnProperty( 'social' ) ) {
                                    for ( let i = 0; i < socialContent.length; i++ )  {
                                        let tweet: any = socialContent[ i ];
                                        let seed: SocialContentSeed = {
                                            twitterContent: tweet.content
                                        };

                                        if ( ( type === GroupDefinitionType.INCLUDE ) && ( tweet.positive === true ) ) {
                                            newSubGroup.social.content.seeds.push( seed );
                                        } else if ( ( type === GroupDefinitionType.EXCLUDE ) && ( tweet.positive === false ) ) {
                                            newSubGroup.social.content.seeds.push( seed );
                                        }
                                    }

                                    if ( persona.social.content.startDate && persona.social.content.endDate ) {
                                        newSubGroup.social.content.startDate = persona.social.content.startDate;
                                        newSubGroup.social.content.endDate = persona.social.content.endDate;
                                    } else {
                                        delete( newSubGroup.social.content.startDate );
                                        delete( newSubGroup.social.content.endDate );
                                    }

                                    // The operator for excluded seed is always hard-coded to OR and min 1 on follow and content
                                    newSubGroup.social.content.operator = ( type === GroupDefinitionType.INCLUDE ) ?
                                        persona.social.content.operator.replace( / /g, '_' ) :
                                        Operators.OR;
                                    newSubGroup.social.content.minimum = ( type === GroupDefinitionType.INCLUDE ) ? persona.social.content.minimum : 1;

                                    if ( newSubGroup.social.content.seeds.length < 1 ) {
                                        // No content obj in social if no seeds (per Ianda)
                                        delete( newSubGroup.social.content );
                                    }

                                    break;
                                }
                            }
                        }
                    } );
                } catch ( error ) {
                    // TODO: Need to add logger
                    console.error( error );
                }
            } else {
                groupDefinitionTypes.forEach( ( type ) => {
                    for ( let i = 0; i < newPersona.groupDefinition[ type ].subgroups?.length; i++ ) {
                        let newSubGroup: any = newPersona.groupDefinition[ type ].subgroups[ i ];

                        if (newSubGroup && newSubGroup.hasOwnProperty( 'social' )) {
                            delete( newSubGroup.social.content );
                            break;
                        }
                    }
                } );
            }

            this.servicePersona = newPersona;
        },

        mapServicePersonaSocialFollow( persona: Persona ) {
            let newPersona: any = cloneDeep( this.servicePersona );
            let socialFollows: Array<SocialFollow> = persona.social.follow?.seeds;
            let addFollows: boolean = ( socialFollows.length > 0 );

            if ( addFollows ) {
                groupDefinitionTypes.forEach( ( type ) => {
                    for ( let i = 0; i < newPersona.groupDefinition[ type ].subgroups?.length; i++ ) {
                        let newSubGroup: any = newPersona.groupDefinition[ type ].subgroups[ i ];

                        if (newSubGroup && !newSubGroup.hasOwnProperty( 'lists' )) {
                            if ( newSubGroup.hasOwnProperty( 'social' ) ) {
                                for ( let i = 0; i < socialFollows.length; i++ )  {
                                    let follow: any = socialFollows[ i ];
                                    let seed: SocialFollowSeed = {
                                        name : follow.name,
                                        twitterHandle : follow.twitterHandle
                                    };

                                    if ( ( type === GroupDefinitionType.INCLUDE ) && ( follow.positive === true ) ) {
                                        newSubGroup.social.follow.seeds.push( seed );
                                    } else if ( ( type === GroupDefinitionType.EXCLUDE ) && ( follow.positive === false ) ) {
                                        newSubGroup.social.follow.seeds.push( seed );
                                    }
                                }

                                // The operator for excluded seeds is always hard-coded to OR and min 1 on follow and content
                                newSubGroup.social.follow.operator = ( type === GroupDefinitionType.INCLUDE ) ?
                                    persona.social.follow.operator.replace( / /g, '_' ) :
                                    Operators.OR;
                                newSubGroup.social.follow.minimum = ( type === GroupDefinitionType.INCLUDE ) ? persona.social.follow.minimum : 1;

                                if ( newSubGroup.social.follow.seeds.length < 1 ) {
                                    // No follow obj in social if no seeds (per Ianda)
                                    delete( newSubGroup.social.follow );
                                }

                                break;
                            }
                        }
                    }
                } );
            } else {
                groupDefinitionTypes.forEach( ( type ) => {
                    for ( let i = 0; i < newPersona.groupDefinition[ type ].subgroups?.length; i++ ) {
                        let newSubGroup: any = newPersona.groupDefinition[ type ].subgroups[ i ];

                        if (newSubGroup && newSubGroup.hasOwnProperty( 'social' )) {
                            delete( newSubGroup.social.follow );
                            break;
                        }
                    }
                } );
            }

            this.servicePersona = newPersona;
        },

        mapServicePersonaTopics( persona: Persona ) {
            let selectedTopics: Array<Topic> = persona.topics;
            let newPersona: any = cloneDeep( this.servicePersona );

            if ( selectedTopics.length > 0 ) {
                newPersona.topics = [];

                if ( !newPersona.hasOwnProperty( 'topics' ) ) {
                    newPersona.topics = [];
                }

                for ( let j = 0; j < selectedTopics.length; j++ ) {
                    newPersona.topics.push( selectedTopics[ j ].id );
                }
            }

            this.servicePersona = newPersona;
        },

        setConglomerateRfmBrands(brands: Array<ConglomerateRfmBrand>) {
            this.persona.conglomerateRfmBrand.brands = brands;
        },

        setConglomerateRfmBrandsOperator(operator: string) {
            this.persona.conglomerateRfmBrand.operator = operator;
        },

        setConglomerateRfmMarkets(markets: Array<ConglomerateRfmMarket>) {
            if (markets.length) {
                this.persona.conglomerateRfm.markets = markets;
            } else {
                delete this.persona.conglomerateRfm.markets;
            }
        },

        setConglomerateRfmOperator(operator: string) {
            this.persona.conglomerateRfm.operator = operator;
        },

        setConsumerIndicator( indicator: ConsumerIndicator ) {
            if ( this.persona.consumer.hasOwnProperty( 'spendingIndicators' ) ) {
                const existingIndex = this.persona.consumer.spendingIndicators.findIndex( existingIndicator => existingIndicator.id === indicator.id );

                if ( existingIndex > -1 ) {
                    this.persona.consumer.spendingIndicators[existingIndex] = indicator;
                } else {
                    this.persona.consumer.spendingIndicators.push( indicator );
                }
            } else {
                this.persona.consumer.spendingIndicators = [indicator];
            }
        },

        deleteConsumerIndicator( indicator: ConsumerIndicator ) {
            if ( this.persona.consumer.hasOwnProperty( 'spendingIndicators' ) ) {
                const existingIndex = this.persona.consumer.spendingIndicators.findIndex( existingIndicator => existingIndicator.id === indicator.id );

                if ( existingIndex > -1 ) {
                    this.persona.consumer.spendingIndicators.splice(existingIndex, 1);
                }
            }
        },

        setDemographicFilter( filterContainer: any) {
            const { field, filter } = filterContainer;
            let filterValueArray: any = [];

            if ( this.persona.demographics[ field ] ) {
                for ( const demographicData of this.persona.demographics[ field ] ) {
                    if ( demographicData.value === filter.value ) {
                        // Ignore repeated values in case of select/omit
                        continue;
                    }

                    filterValueArray.push( demographicData );
                }

                delete this.persona.demographics[this.persona.demographics[field]]
            }

            if ( filter.selected || filter.omitted ) {
                filterValueArray.push( filter );
                this.persona.demographics[field.toString()] = filterValueArray;
            }
        },

        setGeographicFilter(filterContainer: any) {
            const {field, filter} = filterContainer;
            let filterValueArray: any = [];

            if (this.persona.geographics[field]) {
                for (const geographicData of this.persona.geographics[field]) {
                    if (geographicData.value === filter.value) {
                        // Ignore repeated values in case of select/omit
                        continue;
                    }

                    filterValueArray.push(geographicData);
                }

                delete this.persona.geographics[this.persona.geographics[field]]
            }

            if (filter.selected || filter.omitted) {
                filterValueArray.push(filter);
                this.persona.geographics[field.toString()] = filterValueArray;
            }
        },

        async setHighLevelRfmFilter( filterContainer: any ) {
            const { field, filter } = filterContainer;
            let filterValueArray: any = [];

            if ( this.persona.highLevelRfm[ field ] ) {
                for ( const rfmData of this.persona.highLevelRfm[ field ] ) {
                    if ( rfmData.value === filter.value ) {
                        // Ignore repeated values in case of select/omit
                        continue;
                    }
                    filterValueArray.push( rfmData );
                }

                delete this.persona.highLevelRfm[this.persona.highLevelRfm[field]];
            }

            if ( filter.selected || filter.omitted ) {
                filterValueArray.push( filter );
                this.persona.highLevelRfm[field.toString()] = filterValueArray;
            }
        },

        async setListFile( params: any ) {
            // Check to see if the file is still active
            const fileInfo = await useFileStore().getFileById( params as any );
            params.file.isActive = ( fileInfo !== false );

            if ( this.persona.lists.hasOwnProperty( 'definitions' ) ) {
                const existingIndex = this.persona.lists.definitions.findIndex( listFile => listFile.id === params.file.id );

                if ( existingIndex > -1 ) {
                    this.persona.lists.definitions[existingIndex] = params.file;
                } else {
                    let lists: FileMeta[] = cloneDeep( this.persona.lists.definitions );
                    lists.push( params.file );
                    this.persona.lists.definitions = lists;
                }
            } else {
                this.persona.lists.definitions = [ params.file ];
            }
        },

        setOriginalPersonaDemographics( filters: Array<any> ) {
            for (const context of ['include', 'exclude']) {
                const filterContext = context === 'include' ? 'selected' : 'omitted';
                let subgroup = this.originalPersona.groupDefinition[context]?.subgroups
                    .find(subgroup => subgroup && subgroup.hasOwnProperty('offline'));

                if (subgroup) {
                    subgroup.offline.demographics = filters.map(filter => filter[filterContext] === true);
                }
            }
        },

        setOriginalPersonaGeographics(filters: Array<any>) {
            for (const context of ['include', 'exclude']) {
                const filterContext = context === 'include' ? 'selected' : 'omitted';
                let subgroup = this.originalPersona.groupDefinition[context]?.subgroups
                    .find(subgroup => subgroup && subgroup.hasOwnProperty('offline'));

                if (subgroup) {
                    subgroup.offline.geographics = filters.map(filter => filter[filterContext] === true);
                }
            }
        },

        setOriginalPersonaHighLevelRfm( filters: Array<any> ) {
            for (const context of ['include', 'exclude']) {
                const filterContext = context === 'include' ? 'selected' : 'omitted';
                let subgroup = this.originalPersona.groupDefinition[context]?.subgroups
                    .find(subgroup => subgroup && subgroup.hasOwnProperty('highLevelRfm'));

                if (subgroup) {
                    subgroup.highLevelRfm = filters.map(filter => filter[filterContext] === true);
                }
            }
        },

        setPersonaEdit( persona: Persona ) {
            this.persona.fetchedAt = currentTimestamp().toString();
            this.persona.id = persona.id;
            this.persona.name = persona.name;
            this.persona.mode = persona.mode;
            this.persona.isDraft = persona.isDraft;
            this.persona.userPhoto = persona.userPhoto;
        },

        setPhotoCharacteristics() {
            if ( this.getOriginalPersona.hasOwnProperty( 'photoCharacteristics' ) ) {
                this.persona.photoCharacteristics = this.getOriginalPersona.photoCharacteristics;
            }
        },

        setRetiredCharacteristics( characteristic: string ) {
            if ( this.persona.retiredCharacteristics && characteristic ) {
                this.persona.retiredCharacteristics[ characteristic ] = true;
            } else {
                this.persona.retiredCharacteristics = clone( defaultRetiredCharacteristics );
            }
        },

        setSelectableConsumerIndicatorsParameters( params: ListParameters ) {
            if ( isEmptyObject( params ) ) {
                this.selectableConsumerIndicators.params = defaultSelectableConsumerIndicatorsParameters;
            } else {
                this.selectableConsumerIndicators.params = params;
            }
        },

        setSelectableTopic( topic: Topic ) {
            const existingIndex = this.selectableTopics.topics.findIndex( filter => filter.id === topic.id );
            this.selectableTopics.topics[existingIndex] = topic;
        },

        setSelectableTopicsParameters( params: ListParameters ) {
            if ( isEmptyObject( params ) ) {
                this.selectableTopics.params = defaultSelectableTopicsParameters;
            } else {
                this.selectableTopics.params = params;
            }
        },

        setSocialContent( socialContent: SocialContent ) {
            if ( socialContent.selected ) {
                if ( 'content' in this.persona.social && 'seeds' in this.persona.social.content ) {
                    const existingIndex = this.persona.social.content.seeds.findIndex( content => content?.content === socialContent?.content );
                    if ( existingIndex > -1 ) {
                        this.persona.social.content.seeds[existingIndex] = socialContent;
                    } else {
                        this.persona.social.content.seeds.push( socialContent );
                    }
                } else {
                    this.persona.social.content.seeds = [socialContent];
                }
            }
        },

        async setSocialContentAtLeastAutoAdjusted( adjusted: boolean ) {
            this.socialContentAtLeastAutoAdjusted = adjusted;
        },

        async setSocialContentStartDate( startDate: string ) {
            this.persona.social.content.startDate = startDate;
        },

        async setSocialContentEndDate( endDate: string ) {
            this.persona.social.content.endDate = endDate;
        },

        async setSocialFollow( socialFollow: SocialFollow ) {
            if ( socialFollow.selected ) {
                if ( this.persona.social && ('follow' in this.persona.social && 'seeds' in this.persona.social.follow) ) {
                    const existingIndex = this.persona.social.follow.seeds.findIndex( follow => follow && follow.twitterHandle === socialFollow.twitterHandle );

                    if ( existingIndex > -1 ) {
                        this.persona.social.follow.seeds[existingIndex] = socialFollow;
                    } else {
                        this.persona.social.follow.seeds.push( socialFollow );
                    }
                } else {
                    this.persona.social.follow.seeds = [socialFollow];
                }
            }
        },

        async setSocialFollowsOperator( operator: Operator ) {
            this.persona.social.follow.operator = operator.type;
            this.persona.social.follow.minimum = operator.minimum;
        },

        async setSocialContentOperator( operator: Operator ) {
            this.persona.social.content.operator = operator.type;
            this.persona.social.content.minimum = operator.minimum;
        },

        setSocialOperator( operator: Operator ) {
            this.persona.social.operator = operator.type;
        },

        setGroupDefinitionOperator( operatorType: string ) {
            this.persona.operator = operatorType;
        },

        async setSocialFollowsAtLeastAutoAdjusted( adjusted: boolean ) {
            this.socialFollowsAtLeastAutoAdjusted = adjusted;
        },

        setConsumerIndicatorsAtLeastAutoAdjusted( adjusted: boolean ) {
            this.consumerIndicatorsAtLeastAutoAdjusted = adjusted;
        },

        async setSelectableConsumerIndicators( indicators: Array<ConsumerIndicator> ) {
            this.selectableConsumerIndicators.indicators = indicators;
        },

        sortSelectedCharacteristics(characteristics: any[]) {
            let characteristicArray: any[] = [];

            try {
                // Sort characteristic values by position
                let keys = Object.keys(characteristics);
                for (let key of keys) {
                    if (!isUndefinedOrNullOrEmpty(key)) {
                        let items = characteristics[key];

                        if (!isEmptyArray(items)) {
                            items.footprint = {description: '', values: ''};
                            items = sortByProperty(items, 'position', 'asc');
                        }
                    }
                }

                // Map parent characteristic types to array for sorting by value position
                characteristicArray = keys.map((index) => characteristics[index]);
            } catch (error) {
                throw error;
            }

            return sortByProperty(characteristicArray, ['parentPosition', 'position'], 'asc');

            // return characteristicArray.sort((a, b) =>
            //     a[0]?.parentPosition > b[0]?.parentPosition ? 1 : -1
            // );
        },

        async sortSelectedHighLevelRfmCharacteristics(characteristic: Array<any>) {
            let characteristicArray: any[] = [];

            try {
                let keys = Object.keys(characteristic);

                // Sort characteristic values by position
                for (let i = 0; i < keys.length; i++) {
                    let key = keys[i];
                    let rfm = characteristic[key];

                    rfm.footprint = {description: '', values: ''};
                    rfm.sort((a, b) => (a.position > b.position) ? 1 : -1);
                }

                // Map parent characteristic types to array for sorting by value position
                characteristicArray = keys.map((index) => characteristic[index]);
            } catch (error) {
                throw error;
            }

            return characteristicArray.sort((a, b) =>
                a[0].parentPosition > b[0].parentPosition ? 1 : -1
            );
        },

        setDemographics(demographics: Array<any>) {
            try {
                this.persona.demographics = this.getCharacteristicObject(this.sortSelectedCharacteristics(demographics));
            } catch (error) {
                // TODO: Need to add logger
                console.error(error);
            }
        },

        setGeographics(geographics: Array<any>) {
            try {
                this.persona.geographics = this.getCharacteristicObject(this.sortSelectedCharacteristics(geographics));
            } catch (error) {
                // TODO: Need to add logger
                console.error(error);
            }
        },

        setHighLevelRfm(highLevelRfm: Array<any>) {
            try {
                this.persona.highLevelRfm = this.getCharacteristicObject(this.sortSelectedCharacteristics(highLevelRfm));
            } catch (error) {
                // TODO: Need to add logger
                console.error(error);
            }
        },

        setDemographicFilterDefaults( fields: object ) {
            try {
                let filtersArray = Object.entries(fields)
                    .filter(([, fieldData]) => fieldData.selectablePersona && fieldData.dataSource?.toLowerCase() === 'standard')
                    .map(([fieldName, selectableFilter]) => {
                        selectableFilter.name = fieldName;
                        selectableFilter.values = sortByProperty(selectableFilter.values, 'position', 'asc');
                        if (selectableFilter.iconName) {
                            selectableFilter.iconName = selectableFilter.iconName.replace('fa-', '');
                        }

                        for (let filterValue of selectableFilter.values) {
                            let demographicsDefined: boolean = false;
                            filterValue.allowOmit = selectableFilter.omittablePersona;
                            filterValue.omitted = false;
                            filterValue.selected = false;
                            filterValue.parent = selectableFilter.name;
                            filterValue.parentPosition = selectableFilter.position;
                            filterValue.footprintDescription = fields[fieldName].footprintDescription ? fields[fieldName].footprintDescription : null;

                            // If exists in original persona, mark any matching demographic values appropriately
                            for (const selectContext of ['include', 'exclude']) {
                                const demographicContext = selectContext === 'include' ? 'selected' : 'omitted';
                                let subgroup: any = this.originalPersona.groupDefinition[selectContext]?.subgroups
                                    .find(subgroup => subgroup && subgroup.hasOwnProperty('offline'));
                                if (subgroup) {
                                    demographicsDefined = true;
                                    let originalPersonaDefinition: any = cloneDeep(subgroup);

                                    if (originalPersonaDefinition.offline && originalPersonaDefinition.offline.demographics) {
                                        let originalPersonaDemographicKey: string[] = Object.keys(originalPersonaDefinition.offline.demographics);

                                        for (let j = 0; j < originalPersonaDemographicKey.length; j++) {
                                            // If selectable filter and selected demographic match, set filter selected on value match
                                            if (selectableFilter.name === originalPersonaDemographicKey[j]) {
                                                let demographicName: any = originalPersonaDemographicKey[j];

                                                for (let k = 0; k < originalPersonaDefinition.offline.demographics[demographicName].length; k++) {
                                                    let originalPersonaDemographicValue = originalPersonaDefinition.offline.demographics[demographicName][k];

                                                    filterValue[demographicContext] = (filterValue.value === originalPersonaDemographicValue);
                                                    if (filterValue[demographicContext]) {
                                                        break;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            if (!demographicsDefined) {
                                // No group definition
                                filterValue.omitted = false;
                                filterValue.selected = false;
                            }
                        }

                        return selectableFilter;
                    });

                filtersArray = sortByProperty(filtersArray, 'position', 'asc');
                this.selectableDemographics = filtersArray;
            } catch (error) {
                // TODO: Need to add logger
                console.error(error);
            }
        },

        setGeographicFilterDefaults(fields: object) {
            try {
                let filtersArray = Object.entries(fields)
                    .filter(([fieldName, fieldData]) => geographicSelectionData.hasOwnProperty(fieldName) && fieldData.dataSource?.toLowerCase() === 'standard')
                    .map(([fieldName, selectableFilter]) => {
                        selectableFilter.name = fieldName;
                        selectableFilter.values = sortByProperty(selectableFilter.values, 'position', 'asc');
                        if (selectableFilter.iconName) {
                            selectableFilter.iconName = selectableFilter.iconName.replace('fa-', '');
                        }

                        for (let filterValue of selectableFilter.values) {
                            let geographicsDefined: boolean = false;
                            filterValue.allowOmit = selectableFilter.omittablePersona;
                            filterValue.omitted = false;
                            filterValue.selected = false;
                            filterValue.parent = selectableFilter.name;
                            filterValue.parentPosition = selectableFilter.position;
                            filterValue.footprintDescription = fields[fieldName].footprintDescription || filterValue.shortDescription;

                            // If exists in original persona, mark any matching geographic values appropriately
                            for (const selectContext of ['include', 'exclude']) {
                                const geographicContext = selectContext === 'include' ? 'selected' : 'omitted';
                                let subgroup: any = this.originalPersona.groupDefinition[selectContext]?.subgroups
                                    .find(subgroup => subgroup && subgroup.hasOwnProperty('offline'));
                                if (subgroup) {
                                    geographicsDefined = true;
                                    let originalPersonaDefinition: any = cloneDeep(subgroup);

                                    if (originalPersonaDefinition.offline && originalPersonaDefinition.offline.demographics) {
                                        for (const key of Object.keys(originalPersonaDefinition.offline.demographics)) {
                                            // If selectable filter and selected geographic match, set filter selected on value match
                                            if (selectableFilter.name === key) {
                                                for (const originalPersonaGeographicValue of originalPersonaDefinition.offline.demographics[key]) {
                                                    filterValue[geographicContext] = (filterValue.value === originalPersonaGeographicValue);
                                                    if (filterValue[geographicContext]) {
                                                        break;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            if (!geographicsDefined) {
                                // No group definition
                                filterValue.omitted = false;
                                filterValue.selected = false;
                            }
                        }

                        return selectableFilter;
                    });

                filtersArray = sortByProperty(filtersArray, 'position', 'asc');
                this.selectableGeographics = filtersArray;
                // console.debug('🌎 Selectable Persona geographics:', this.selectableGeographics);
            } catch (error) {
                // TODO: Need to add logger
                console.error(error);
            }
        },

        setHighLevelRfmFilterDefaults( fields: object ) {
            try {
                let filtersArray = Object.entries(fields)
                    .filter(([, fieldData]) => fieldData.selectablePersona && fieldData.dataSource?.toLowerCase() === 'highlevelrfm')
                    .map(([fieldName, selectableFilter]) => {
                        selectableFilter.name = fieldName;
                        selectableFilter.values = sortByProperty(selectableFilter.values, 'position', 'asc');
                        if (selectableFilter.iconName) {
                            selectableFilter.iconName = selectableFilter.iconName.replace('fa-', '');
                        }

                        for (let filterValue of selectableFilter.values) {
                            let highLevelRfmDefined: boolean = false;
                            filterValue.allowOmit = selectableFilter.omittablePersona;
                            filterValue.omitted = false;
                            filterValue.selected = false;
                            filterValue.parent = selectableFilter.name;
                            filterValue.parentPosition = selectableFilter.position;
                            filterValue.footprintDescription = fields[fieldName].footprintDescription ? fields[fieldName].footprintDescription : null;

                            // If exists in original persona, mark any matching rfm values appropriately
                            // TODO: Need to move this into a function
                            for (const selectContext of ['include', 'exclude']) {
                                const highLevelRfmContext = selectContext === 'include' ? 'selected' : 'omitted';
                                let subgroup: any = this.originalPersona.groupDefinition[selectContext]?.subgroups
                                    .find(subgroup => subgroup && subgroup.hasOwnProperty('highLevelRfm'));
                                if (subgroup) {
                                    highLevelRfmDefined = true;
                                    let originalPersonaDefinition: any = cloneDeep(subgroup);

                                    if (originalPersonaDefinition.highLevelRfm && originalPersonaDefinition.highLevelRfm.variables) {
                                        let originalPersonaHighLevelRfmKeys: string[] = Object.keys(originalPersonaDefinition.highLevelRfm.variables);

                                        for (let j = 0; j < originalPersonaHighLevelRfmKeys.length; j++) {
                                            // If selectable filter and selected rfm match, set filter selected on value match
                                            if (selectableFilter.name === originalPersonaHighLevelRfmKeys[j]) {
                                                let highLevelRfmName: any = originalPersonaHighLevelRfmKeys[j];

                                                for (let k = 0, l = originalPersonaDefinition.highLevelRfm.variables[highLevelRfmName].length; k < l; ++k) {
                                                    let originalPersonaHighLevelRfmValue = originalPersonaDefinition.highLevelRfm.variables[highLevelRfmName][k];

                                                    filterValue[highLevelRfmContext] = (filterValue.value === originalPersonaHighLevelRfmValue);
                                                    if (filterValue[highLevelRfmContext]) {
                                                        break;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            if (!highLevelRfmDefined) {
                                // No group definition
                                filterValue.omitted = false;
                                filterValue.selected = false;
                            }
                        }

                        return selectableFilter;
                    });
                filtersArray = sortByProperty(filtersArray, 'position', 'asc');
                this.selectableHighLevelRfm = filtersArray;
            } catch (error) {
                // TODO: Need to add logger
                console.error(error);
            }
        },

        setTopics( topics: Array<any> ) {
            this.persona.topics = topics;
        },

        setTopicFilter( topicFilter: Topic ) {
            if ( this.persona.topics.length > 0 ) {
                const existingIndex = this.persona.topics.findIndex( topic => topic.id === topicFilter.id );
                if ( existingIndex === -1 ) {
                    this.persona.topics.push( topicFilter );
                } else {
                    this.persona.topics[existingIndex] = topicFilter;
                }
            } else {
                this.persona.topics.push( topicFilter );
            }
        },

        setTopicFilterDefaults( topics: Array<Topic> ) {
            let parameters: ListParameters = cloneDeep( this.selectableTopics.params );
            let filtersArray: Array<any> = ( parameters.append ) ? cloneDeep( this.selectableTopics.topics ) : [];

            if (!isEmptyArray(topics)) {
                for ( let i = 0; i < topics.length; i++ ) {
                    const topic: any = topics[ i ];
                    const existingIndex = filtersArray.findIndex( filter => filter.id === topic.id );
                    let topicFollows: Array<SocialFollow> = [];
                    let topicMentions: Array<SocialContent> = [];
                    let topicHashtags: Array<SocialContent> = [];
                    let topicTerms: Array<SocialContent> = [];

                    if ( topic.definition.hasOwnProperty( 'follows' ) ) {
                        for ( let j = 0; j < topic.definition.follows.length; j++ ) {
                            const follow: any = topic.definition.follows[j];

                            if (follow) {
                                let socialFollow: SocialFollow = {
                                    name: follow.name,
                                    twitterHandle: follow.twitterHandle,
                                    instagramInterest: follow.instagramInterest,
                                    instagramTitle: follow.instagramTitle,
                                    positive: true,
                                    selected: false
                                };

                                topicFollows.push( socialFollow );
                            }
                        }
                    }

                    if ( topic.definition.hasOwnProperty( 'content' ) && topic.definition.content.hasOwnProperty( 'mentions' ) ) {
                        for ( let k = 0; k < topic.definition.content.mentions.length; k++ ) {
                            const content: any = topic.definition.content.mentions[k];
                            const mention: any = ( typeof content === 'string' ) ? content : ( content.content || content.twitterContent );

                            if (mention) {
                                let socialContent: SocialContent = {
                                    content: mention,
                                    positive: true,
                                    selected: false,
                                    icon: socialContentIcon( mention )
                                };

                                topicMentions.push(socialContent);
                            }
                        }
                    }

                    if ( topic.definition.hasOwnProperty( 'content' ) && topic.definition.content.hasOwnProperty( 'hashtags' ) ) {
                        for ( let l = 0; l < topic.definition.content.hashtags.length; l++ ) {
                            const content: any = topic.definition.content.hashtags[ l ];
                            const hashtag: any = ( typeof content === 'string' ) ? content : ( content.content || content.twitterContent );

                            let socialContent: SocialContent = {
                                content: hashtag,
                                positive: true,
                                selected: false,
                                icon: socialContentIcon( hashtag )
                            };

                            topicHashtags.push( socialContent );
                        }
                    }

                    if ( topic.definition.hasOwnProperty( 'content' ) && topic.definition.content.hasOwnProperty( 'terms' ) ) {
                        for ( let m = 0; m < topic.definition.content.terms.length; m++ ) {
                            const content: any = topic.definition.content.terms[ m ];
                            const term: any = ( typeof content === 'string' ) ? content : ( content.content || content.twitterContent );

                            let socialContent: SocialContent = {
                                content: term,
                                positive: true,
                                selected: false,
                                icon: socialContentIcon( term )
                            };

                            topicTerms.push( socialContent );
                        }
                    }

                    let topicFilter: Topic = {
                        id: topic.id,
                        name: topic.name,
                        public: false,
                        selected: false,
                        definition: {
                            follows: topicFollows,
                            content: {
                                startDate: '',
                                endDate: '',
                                mentions: topicMentions,
                                hashtags: topicHashtags,
                                terms: topicTerms
                            },
                        },
                        tags: topic.tags,
                        classification: topic.classification,
                        category: topic.category,
                        subCategory: topic.subCategory,
                        sentenceStyle: topic.sentenceStyle,
                        verb: topic.verb,
                        indexStyle: topic.indexStyle,
                        shortDescription: topic.shortDescription,
                        longDescription: topic.longDescription,
                        iconStyle: topic.iconStyle,
                        iconName: topic.iconName,
                        defaultTopic: topic.defaultTopic,
                        created: topic.created,
                        updated: topic.updated,
                        fetchedAt: topic.updated.timestamp,
                        lastUsed: topic.lastUsed
                    };

                    if ( !topic.account ) {
                        topicFilter.public = true;
                    } else {
                        topicFilter.public = false;
                        topicFilter.account = topic.account;
                    }

                    if ( this.getTopics.length > 0 ) {
                        for ( let n = 0; n < this.getTopics.length; n++) {
                            const selectedTopic: Topic = this.getTopics[ n ];

                            if ( selectedTopic.id === topicFilter.id ) {
                                topicFilter.selected = true;
                                break;
                            }
                        }
                    }

                    if ( existingIndex !== -1 ) {
                        filtersArray[existingIndex] = topicFilter;
                    } else {
                        filtersArray.push( topicFilter );
                    }
                }

                const limit: number = defaultSelectableTopicsParameters.limit || 0;
                const nextOffset: number = ( topics.length < limit ) ? filtersArray.length + ( limit - topics.length ) : filtersArray.length;
                parameters.offset = ( topics.length === 1 ) ? ( nextOffset + 1 ) : nextOffset;
                filtersArray = sortArray( filtersArray, parameters );
                this.setSelectableTopicsParameters( parameters );
            }

            this.selectableTopics.topics = filtersArray;
        },

        setConsumerIndicatorsFilterDefaults( indicators: Array<ConsumerIndicator> ) {
            let parameters: ListParameters = cloneDeep( this.getSelectableConsumerIndicatorsParameters );
            let filtersArray: Array<any> = ( parameters.append ) ? cloneDeep( this.getSelectableConsumerIndicators ) : [];

            for ( let i = 0; i < indicators.length; i++ ) {
                const indicator: any = indicators[ i ];

                let indicatorFilter: ConsumerIndicator = {
                    id: indicator.id,
                    name: indicator.name,
                    isActive: indicator.isActive,
                    shortDescription: indicator.shortDescription,
                    longDescription: indicator.longDescription,
                    type: indicator.type,
                    category: indicator.category,
                    subCategory: indicator.subCategory,
                    selected: false,
                    positive: true
                };

                if ( this.getConsumerIndicators.length > 0 ) {
                    for ( let n = 0; n < this.getConsumerIndicators.length; n++) {
                        const selectedIndicator: ConsumerIndicator = this.getConsumerIndicators[ n ];

                        if ( selectedIndicator.id === indicatorFilter.id ) {
                            indicatorFilter.selected = true;
                            break;
                        }
                    }
                }

                filtersArray.push( indicatorFilter );
            }

            const limit: number = defaultSelectableConsumerIndicatorsParameters.limit || 0;
            const nextOffset: number = ( indicators.length < limit ) ? filtersArray.length + ( limit - indicators.length ) : filtersArray.length;
            parameters.offset = ( indicators.length === 1 ) ? ( nextOffset + 1 ) : nextOffset;

            this.setSelectableConsumerIndicatorsParameters( parameters );
            this.selectableConsumerIndicators.indicators = filtersArray;
        },

        setOriginalDemographicFilters() {
            for (const defaultedFilter of this.selectableDemographics) {
                for (const filterItem of defaultedFilter.values) {
                    if (filterItem.selected || filterItem.omitted) {
                        let field: string = defaultedFilter.name;
                        let filter: any = filterItem;

                        this.setDemographicFilter({field, filter});
                    }
                }
            }

            this.setDemographics(this.persona.demographics);
        },

        setOriginalGeographicFilters(subgroup: any) {
            for (const defaultedFilter of this.selectableGeographics) {
                for (const filterItem of defaultedFilter.values) {
                    if (filterItem.selected || filterItem.omitted) {
                        let field: string = defaultedFilter.name;
                        let filter: any = filterItem;

                        this.setGeographicFilter({field, filter});
                    }
                }
            }

            this.setGeographics(this.persona.geographics);
        },

        setOriginalHighLevelRfmFilters() {
            for ( const defaultedFilter of this.selectableHighLevelRfm ) {
                for ( const filterItem of defaultedFilter.values ) {
                    if ( filterItem.selected || filterItem.omitted ) {
                        let field: string = defaultedFilter.name;
                        let filter: any = filterItem;

                        this.setHighLevelRfmFilter( { field, filter } );
                    }
                }
            }

            this.setHighLevelRfm( this.persona.highLevelRfm );
        },

        async searchConsumerIndicators( params: ListParameters ) {
            this.setSelectableConsumerIndicatorsParameters( params );

            try {
                const consumerIndicatorsParams: Object = { sort: params.sort, order: params.order, limit: params.limit, offset: params.offset, search: params.search };
                const response: any = await api.getAxiosInstance.get( `/api/meta/characteristics/consumerSpend`, { params: consumerIndicatorsParams } );
                const consumerIndicators = response.data;

                if ( consumerIndicators.data ) {
                    this.setConsumerIndicatorsFilterDefaults( consumerIndicators.data.indicators );
                    return consumerIndicators.data;
                } else {
                    throw 'No consumer spending indicators';
                }
            } catch ( error ) {
                // TODO: Need to add logger
                console.error( error );
            }
        },

        setConsumerIndicatorsOperator( operator: Operator ) {
            this.persona.consumer.operator = operator.type;
            this.persona.consumer.minimum = operator.minimum;
        },

        setPersonaUpdatedDate( updated: UserTimestamp ) {
            this.persona.updated = updated;
        },

        setPersonaCreatedDate( created: UserTimestamp ) {
            this.persona.created = created;
        },

        setPersonaPhotos( photos: PhotoAssignment[] ) {
            this.persona.photos = photos;
        },

        setOriginalConglomerateRfm() {
            const account: Account = useAccountStore().getAccount;
            if (!account.allowConglomerateRFMVariables) {
                return false;
            }

            for (const subgroup of this.originalPersona['groupDefinition']?.include.subgroups || []) {
                if (account.allowConglomerateRFMVariables && subgroup && subgroup.hasOwnProperty('conglomerateRfm')) {
                    const conglomerateRfm: any = subgroup['conglomerateRfm'];
                    this.setConglomerateRfmMarkets(conglomerateRfm.markets);
                    this.setConglomerateRfmOperator(conglomerateRfm.operator);
                }
            }
        },

        setOriginalConglomerateRfmBrands() {
            const account: Account = useAccountStore().getAccount;
            if (!account.allowConglomerateRFMBrandVariables) {
                return false;
            }

            for (const subgroup of this.originalPersona['groupDefinition']?.include.subgroups || []) {
                if (account.allowConglomerateRFMBrandVariables && subgroup && subgroup.hasOwnProperty('conglomerateRfmBrand')) {
                    const conglomerateRfmBrand: any = subgroup['conglomerateRfmBrand'];
                    this.setConglomerateRfmBrands(conglomerateRfmBrand.brands);
                    this.setConglomerateRfmBrandsOperator(conglomerateRfmBrand.operator);
                }
            }
        },

        async setOriginalConsumerIndicators() {
            const account: Account = useAccountStore().getAccount;
            if (!account.allowConsumerSpend) {
                return false;
            }

            for ( let i = 0; i < this.originalPersona[ 'groupDefinition' ]?.include.subgroups?.length; i++ ) {
                let subgroup: any = this.originalPersona[ 'groupDefinition' ]?.include.subgroups[ i ];

                if ( account.allowConsumerSpend && subgroup && subgroup.hasOwnProperty( 'consumerSpend' ) ) {
                    const consumer: any = subgroup[ 'consumerSpend' ];
                    const originalConsumerIndicators: Array<ConsumerIndicator> = consumer.spendingIndicators;
                    let retiredIndicators: ConsumerIndicator[] = [];

                    if ( !isEmptyArray( originalConsumerIndicators ) ) {
                        const personaId: string = this.originalPersona?.id ? this.originalPersona?.id : this.originalPersona?.copiedFromId;
                        const operator: Operator = { type: consumer.operator, minimum: consumer.minimum };
                        const upToDateIndicators: ConsumerIndicator[] = await this.getConsumerIndicatorsByPersonaSelections( personaId );

                        for ( let i = 0; i < originalConsumerIndicators.length; i++ ) {
                            let originalIndicator: ConsumerIndicator = originalConsumerIndicators[ i ];
                            let upToDateIndicator = upToDateIndicators?.find( indicator => indicator.id === originalIndicator.id );

                            if ( upToDateIndicator && upToDateIndicator.isActive ) {
                                originalIndicator.selected = true;
                                originalIndicator.positive = true;

                                this.setConsumerIndicator( originalIndicator );
                            } else {
                                originalIndicator.isActive = false;
                                retiredIndicators.push( originalIndicator );
                            }
                        }

                        this.setConsumerIndicatorsOperator(operator);
                    }

                    if ( !isEmptyArray( retiredIndicators ) ) {
                        this.setRetiredCharacteristics( PersonaCharacteristics.SPENDING_INDICATORS );
                    }
                }
            }
        },

        setOriginalSocial() {
            for ( const type of groupDefinitionTypes ) {
                if ( this.originalPersona[ 'groupDefinition' ] ) {
                    for (let i = 0; i < this.originalPersona[ 'groupDefinition' ][ type ].subgroups?.length; i++ ) {
                        let subgroup: any = this.originalPersona[ 'groupDefinition' ][ type ].subgroups[ i ];

                        if (subgroup && subgroup.hasOwnProperty( 'social' )) {
                            if ( type !== GroupDefinitionType.EXCLUDE ) {
                                this.setSocialOperator( { type: subgroup.social.operator } );
                            }

                            if ( subgroup.social.hasOwnProperty( 'follow' ) ) {
                                this.setOriginalSocialFollows( { type, follows: subgroup.social.follow } );
                            }

                            if ( subgroup.social.hasOwnProperty( 'content' ) ) {
                                this.setOriginalSocialContent( { type, content: subgroup.social.content } );
                            }
                        }
                    }
                }
            }
        },

        setOriginalSocialContent( socialContent: any ) {
            if ( socialContent ) {
                let content: any = socialContent.content;

                if ( !isEmptyObject( content ) ) {
                    let positive: boolean = socialContent.type === GroupDefinitionType.INCLUDE;

                    for ( const seed of content.seeds ) {
                        let selectedContent: SocialContent = {
                            content: seed.twitterContent,
                            positive: positive,
                            selected: true,
                            icon: socialContentIcon( seed.twitterContent )
                        }

                        this.setSocialContent( selectedContent );
                    }

                    if ( positive ) {
                        let operator: Operator = {
                            type: content.operator,
                            minimum: content.minimum
                        };

                        this.persona.social.content.operator = operator.type;
                        this.persona.social.content.minimum = operator.minimum;
                    }
                }
            }
        },

        setOriginalSocialFollows( socialFollows: any ) {
            if ( socialFollows ) {
                let follows: any = socialFollows.follows;

                if ( !isEmptyObject( follows ) ) {
                    let positive: boolean = socialFollows.type === GroupDefinitionType.INCLUDE;

                    for ( let j = 0; j < follows.seeds.length; j++ ) {
                        let seed: any = follows.seeds[ j ];

                        let selectedFollow: SocialFollow = {
                            name: seed.name,
                            twitterHandle: seed.twitterHandle,
                            instagramInterest: seed.instagramInterest,
                            instagramTitle: seed.instagramTitle,
                            positive: positive,
                            selected: true
                        }

                        this.setSocialFollow( selectedFollow );
                    }

                    if ( positive ) {
                        let operator: Operator = {
                            type: follows.operator,
                            minimum: follows.minimum
                        };

                         this.persona.social.follow.operator = operator.type;
                         this.persona.social.follow.minimum = operator.minimum;
                    }
                }
            }
        },

        setOriginalSubmitParameters() {
            const accountSubmitParameters: SubmitParameter[] = useAccountStore().getAccount.submitParameters;
            const originalPersona: any = cloneDeep( this.originalPersona );

            if ( originalPersona.hasOwnProperty( 'submitParameters' ) && originalPersona.submitParameters  ) {
                let originalPersonaSubmitParameters: SubmitParameter[] = [];
                // We currently only support 1 submitParameter and seem to be getting 1 obj vs array with 1 obj from db so check for either
                if ( Array.isArray( originalPersona.submitParameters ) ) {
                    originalPersonaSubmitParameters.push( cloneDeep( originalPersona.submitParameters )[ 0 ] );
                } else {
                    originalPersonaSubmitParameters = [ cloneDeep( originalPersona.submitParameters ) ];
                }

                if ( Array.isArray( originalPersonaSubmitParameters ) && originalPersonaSubmitParameters.length > 0 ) {
                    let originalPersonaSubmitParameter: SubmitParameter = originalPersonaSubmitParameters[ 0 ];

                    // Assume original submit parameter is obsolete then check if its not
                    originalPersonaSubmitParameter.obsolete = true;

                    if ( accountSubmitParameters && Array.isArray( accountSubmitParameters ) ) {
                        for ( let i = 0; i < accountSubmitParameters.length; i++ ) {
                            const accountSubmitParameter: SubmitParameter = accountSubmitParameters[ i ];
                            // Update to current account options
                            originalPersonaSubmitParameter.options = cloneDeep( accountSubmitParameter.options );

                            if ( ( originalPersonaSubmitParameter.id === accountSubmitParameter.id ) && accountSubmitParameter.options ) {
                                originalPersonaSubmitParameter.obsolete = false;

                                if ( originalPersonaSubmitParameter.submitOption ) {
                                    for ( let j = 0; j < accountSubmitParameter.options.length; j++ ) {
                                        // Assume original submit parameter submit option is obsolete then check if its not
                                        originalPersonaSubmitParameter.submitOption.obsolete = true;

                                        if ( originalPersonaSubmitParameter.submitOption.id === accountSubmitParameter.options[ j ].id ) {
                                            originalPersonaSubmitParameter.submitOption.obsolete = false;
                                            break;
                                        }
                                    }
                                }

                                if ( !originalPersonaSubmitParameter.submitOption || originalPersonaSubmitParameter.submitOption.obsolete ) {
                                    originalPersonaSubmitParameter.submitOption = cloneDeep( useAccountStore().getAccountDefaultSubmitParameter.submitOption );

                                    if ( originalPersonaSubmitParameter.submitOption ) {
                                        originalPersonaSubmitParameter.submitOption.autoDefaulted = true;
                                    }
                                }

                                delete( originalPersonaSubmitParameter.submitOption?.obsolete );
                                break;
                            }
                        }
                    }

                    if ( originalPersonaSubmitParameter.obsolete ) {
                        if ( useAccountStore().getAccountDefaultSubmitParameter ) {
                            originalPersonaSubmitParameter = cloneDeep( useAccountStore().getAccountDefaultSubmitParameter );
                            originalPersonaSubmitParameter.autoDefaulted = true;
                        }
                    }

                    if ( originalPersonaSubmitParameter ) {
                        delete( originalPersonaSubmitParameter.obsolete );
                    }

                    this.setSubmitParameters( [ originalPersonaSubmitParameter ] );
                }
            } else {
                let defaultParameter: SubmitParameter = cloneDeep( useAccountStore().getAccountDefaultSubmitParameter );
                this.setSubmitParameters( [ defaultParameter ] );
            }
        },

        setOriginalTopics() {
            if ( this.getOriginalPersona.hasOwnProperty( 'topics' ) ) {
                const originalTopics: Array<any> = this.getOriginalPersona[ 'topics' ];

                if ( originalTopics.length > 0 ) {
                    for ( let i = 0; i < originalTopics.length; i++ ) {
                        let originalTopic: Topic = originalTopics[ i ];

                        if ( this.persona.topics.length > 0 ) {
                            const existingIndex = this.persona.topics.findIndex( topic => topic.id === originalTopic.id );
                            if ( existingIndex === -1 ) {
                                this.persona.topics.push( originalTopic );
                            } else {
                                this.persona.topics[existingIndex] = originalTopic;
                            }
                        } else {
                            this.persona.topics.push( originalTopic );
                        }
                    }

                    this.setAvailableTopics();
                }
            }
        },

        async setOriginalLists( params: any = { shared: false } ) {
            for (const type of groupDefinitionTypes) {
                if ( this.originalPersona[ 'groupDefinition' ] ) {
                    for ( let i = 0; i < this.originalPersona[ 'groupDefinition' ][ type ].subgroups?.length; i++ ) {
                        let subgroup: any = this.originalPersona[ 'groupDefinition' ][ type ].subgroups[ i ];

                        if (subgroup && subgroup.hasOwnProperty( 'lists' )) {
                            const originalListFiles: Array<any> = subgroup.lists.definitions;

                            if ( !isEmptyArray( originalListFiles ) ) {
                                for ( let i = 0; i < originalListFiles.length; i++ ) {
                                    const originalListFile: any = originalListFiles[ i ];
                                    let originalFileMeta: FileMeta = originalListFile;
                                    originalFileMeta.type = originalListFile.fileType;
                                    originalFileMeta.selected = true;
                                    originalFileMeta.positive = type === GroupDefinitionType.INCLUDE;

                                    await this.setListFile( Object.assign( {}, params, { file: originalFileMeta } ) );
                                }
                            }
                        }
                    }
                }
            }
        },

        setAvailableDemographics(mode?: string) {
            const accountDictionary: any = useAccountStore().getAccountDictionary;

            if (accountDictionary.hasOwnProperty('standard')) {
                const standardDictionary = structuredClone(toRaw(accountDictionary.standard));
                this.setGeographicFilterDefaults(standardDictionary); // Geography is technically demographic at the time being
                this.setDemographicFilterDefaults(standardDictionary);

                if ([PersonaMode.COPY, PersonaMode.EDIT, PersonaMode.PREPEDIT].includes(mode || this.originalPersona.mode)) {
                    let originalFiltersApplied: boolean = false;
                    for (const context of ['include', 'exclude']) {
                        if (originalFiltersApplied) {
                            break;
                        }

                        if (this.originalPersona['groupDefinition'][context].hasOwnProperty('subgroups')) {
                            for (let i = 0; i < this.originalPersona['groupDefinition'][context].subgroups?.length; i++) {
                                let subgroup: any = this.originalPersona['groupDefinition'][context].subgroups[i];
                                if (subgroup && subgroup.hasOwnProperty('offline')) {
                                    this.setOriginalDemographicFilters();
                                    this.setOriginalGeographicFilters(subgroup);
                                    originalFiltersApplied = true;
                                }
                            }
                        } else {
                            throw 'Invalid group definition';
                        }
                    }
                }
            } else {
                throw 'No field dictionary';
            }
        },

        setAvailableHighLevelRfm(mode?: string) {
            const account: Account = useAccountStore().getAccount;
            if (!account.allowHighLevelRFMVariables) {
                return false;
            }

            const accountDictionary: any = useAccountStore().getAccountDictionary;

            if (accountDictionary.hasOwnProperty('highLevelRFM')) {
                this.setHighLevelRfmFilterDefaults(structuredClone(toRaw(accountDictionary.highLevelRFM)));

                if ([PersonaMode.COPY, PersonaMode.EDIT, PersonaMode.PREPEDIT].includes(mode || this.originalPersona.mode)) {
                    let originalFiltersApplied: boolean = false;
                    for (const context of ['include', 'exclude']) {
                        if (originalFiltersApplied) {
                            break;
                        }

                        if (this.originalPersona['groupDefinition'][context].hasOwnProperty('subgroups')) {
                            for (let i = 0; i < this.originalPersona['groupDefinition'][context].subgroups?.length; i++) {
                                let subgroup: any = this.originalPersona['groupDefinition'][context].subgroups[i];
                                if (subgroup && subgroup.hasOwnProperty('highLevelRfm')) {
                                    this.setOriginalHighLevelRfmFilters();
                                    originalFiltersApplied = true;
                                }
                            }
                        } else {
                            throw 'Invalid group definition';
                        }
                    }
                }
            } else {
                throw 'No High-Level RFM field dictionary found';
            }
        },

        async setAccountTopics( params: ListParameters ) {
            this.setSelectableTopicsParameters( params );

            try {
                const currentUser: User = useUserStore().getUser;
                const response: any = await api.getAxiosInstance.get( `/api/accounts/${currentUser.currentAccountId}/topics`, { params: this.getSelectableTopicsParameters } );

                if ( response.data?.data ) {
                    this.accountTopics = response.data.data.topics;
                    this.topicCount = response.data.data.count;

                    return response.data.data;
                } else {
                    throw 'No account topics';
                }
            } catch ( error ) {
                throw error;
            }
        },

         // TODO: Need to remove this and just call setTopicFilterDefaults after last refactor
        setAvailableTopics() {
            try {
                if ( this.accountTopics ) {
                    this.setTopicFilterDefaults( this.accountTopics );
                } else {
                    throw 'No account topics';
                }
            } catch ( error ) {
                throw error;
            }
        },
        setPersona( persona: Persona ) {
            this.persona = persona;
        },

        setSelectableHighLevelRfm( filters: Array<any> ) {
            this.selectableHighLevelRfm = filters;
        },

        setPersonaName( name: string ) {
            this.persona.name = name;
        },

        async savePersonaName( name: string ) {
            this.persona.name = name;

            // Hit the API with the new name
            const accountId = useUserStore().getUserCurrentAccountId;
            const endpoint = `/api/accounts/${accountId}/personas/${this.persona.id}`;
            const body = { name: this.persona.name };

            return await api.getAxiosInstance.patch( endpoint, body );
        },

        async savePersonaUserPhoto( file ) {
            try {
                // Hit the API with the new photo
                const accountId = useUserStore().getUserCurrentAccountId;
                const response = await api.getAxiosInstance.patch(
                    `/api/accounts/${accountId}/personas/${this.persona.id}`,
                    {
                        userPhotoId: file?.id || null
                    }
                );
                const patchResult = response.data?.data;

                if (patchResult) {
                    const photoData = patchResult.userPhoto || null;
                    this.persona.userPhoto = photoData;

                    return photoData;
                } else {
                    return response.data;
                }
            } catch (error) {
                // TODO: Need to add logger
                console.error(error);
            }
        },

        setPersonaTags( tags: string[] ) {
            this.persona.tags = tags;
        },

        setSubmitParameters( parameters: Array<any> ) {
            // Note: API defines array but ui only supports selecting 1 at this time
            this.persona.submitParameters = parameters;
        },

        getPersonaIdByMode(persona: Persona) {
            switch (persona.mode) {
                case PersonaMode.DRAFT:
                    if (!persona.isDraft) {
                        // Save new draft from existing persona (clear id) or continue (no id) to save new draft from scratch
                        return !isEmptyString(persona.id) ? '' : persona.id;
                    } else {
                        // Overwrite existing draft
                        return persona.id;
                    }
                case PersonaMode.COPY:
                    return '';
                default:
                    return persona.id;
            }
        },

        async savePersona(persona: Persona) {
            const currentUser: User = useUserStore().getUser;
            let newPersona: any = cloneDeep(persona);
            newPersona.accountId = currentUser.currentAccountId;
            newPersona.containsSensitiveData = false; // Any sensitive data should have already been stripped out of the groupDefinition
            newPersona.id = this.getPersonaIdByMode(newPersona);
            newPersona.userId = currentUser.id;
            // newPersona.name = (newPersona.mode === PersonaMode.DRAFT && !newPersona.name)
            //     ? `Draft ${dateFormat(new Date(), {format: 'fileDate'})}`
            //     : newPersona.name;
            newPersona.fetchedAt = (newPersona.isDraft || newPersona.mode === PersonaMode.EDIT) ? newPersona.fetchedAt : '';
            this.setPersona(newPersona);
            this.mapServicePersona(newPersona);
            this.mapServicePersonaConglomerateRfmValues(newPersona);
            this.mapServicePersonaConglomerateRfmBrandValues(newPersona);
            this.mapServicePersonaDefinition(newPersona);
            this.mapServicePersonaDemographics(newPersona);
            this.mapServicePersonaGeographics(newPersona);
            this.mapServicePersonaHighLevelRfmValues(newPersona);
            this.mapServicePersonaSocialOperators(newPersona);
            this.mapServicePersonaSocialFollow(newPersona);
            this.mapServicePersonaSocialContent(newPersona);
            this.mapServicePersonaTopics(newPersona);
            this.mapServicePersonaConsumerIndicators(newPersona);
            this.mapServicePersonaListFiles(newPersona);
            this.mapServicePersonaSubmitParameters(newPersona);
            this.clearServicePersonaEmptyDefinitionSubgroups();
            // console.debug('Service persona after mapping:', JSON.stringify(this.servicePersona));

            try {
                const accountId: string = newPersona.accountId;
                const body: ServicePersona = this.servicePersona;
                let response: AxiosResponse<any> = axiosResponse();
                // console.warn('PERSONA SUBMIT BODY:', body);
                // throw `ERROR: Persona submit disabled during development`;

                if (isUndefinedOrNullOrEmpty(newPersona.id)) {
                    response = await api.getAxiosInstance.post(`/api/accounts/${accountId}/personas`, body);
                } else {
                    response = await api.getAxiosInstance.put(`/api/accounts/${accountId}/personas/${newPersona.id}`, body);
                }

                if (response.data) {
                    return response.data;
                } else {
                    throw `Error: ${RequestError.INVALID_RESPONSE} (savePersona)`;
                }
            } catch (error) {
                // TODO: Need to add logger
                console.error(error);
                return false;
            }
        },

        async submitPersonaJob( persona: Persona ) {
            const currentUser: User = useUserStore().getUser;
            let submitJobResponse: any = {};
            let newPersona: any = cloneDeep( this.persona );
            newPersona.id = persona.id;
            newPersona.created.user.id = persona.userId;

            try {
                const body: any = { userId: currentUser.id };
                const response = await api.getAxiosInstance.post( `/api/accounts/${currentUser.currentAccountId}/personas/${newPersona.id}/jobs`, body );

                submitJobResponse = response.data;

                return submitJobResponse;
            } catch ( error ) {
                // TODO: Need to add logger
                console.error( error );
                return false;
            }
        },

        async setOriginalPersona( params: any ) {
            try {
                let originalPersona: any;

                if (params.refresh) {
                    const persona = await this.getPersonaById( params );

                    if ( originalPersona !== null ) {
                        originalPersona = persona;
                        originalPersona.name = persona.name;
                        originalPersona.mode = params.mode;

                        if ( params.mode === PersonaMode.COPY ) {
                            let baseName = persona.name.replace( /(copy\s(.*)\sof|copy\sof\s)/gi, '' ).trim();
                            let personaCopyName: string = `Copy of ${baseName}`;
                            let copyNameValidation = await this.getPersonaNameValidation( personaCopyName );

                            if (!copyNameValidation.valid) {
                                let iteration: number = 0;

                                do {
                                    personaCopyName = `Copy ${iteration + 1} of ${baseName}`;
                                    copyNameValidation = await this.getPersonaNameValidation( personaCopyName );
                                    iteration++;
                                }
                                while ( !copyNameValidation.valid )
                            }

                            originalPersona.name = personaCopyName;
                            originalPersona.copiedFromId = originalPersona.id;
                            originalPersona.id = '';
                        }
                    }
                } else if (typeof params.persona === 'object') {
                    // Persona object has already been supplied
                    originalPersona = params.persona;
                }

                this.originalPersona = originalPersona;
                this.setPersonaEdit( originalPersona );
                this.setGroupDefinitionOperator( this.originalPersona.groupDefinition?.include.operator );
                this.setOriginalSubmitParameters();
                this.setOriginalSocial();
                this.setOriginalConglomerateRfm();
                this.setOriginalConglomerateRfmBrands();
                await this.setOriginalConsumerIndicators();
                this.setOriginalTopics();
                await this.setOriginalLists( params );
                this.setAvailableHighLevelRfm( PersonaMode.COPY );
                this.setPersonaTags( this.getOriginalPersona.tags ? this.getOriginalPersona.tags : [] );
                this.setPhotoCharacteristics();
                this.setPersonaPhotos( this.getOriginalPersona.photos ? this.getOriginalPersona.photos : [] );
                this.setPersonaCreatedDate( this.getOriginalPersona.created || defaultUserTimestamp );
                this.setPersonaUpdatedDate( this.getOriginalPersona.updated || defaultUserTimestamp );

            } catch (error) {
                // TODO: Need to add logger
                console.error(error);
            }
        },

        updateSelectableTopics( topic: Topic ) {
            let params: ListParameters = this.getSelectableTopicsParameters;
            params.append = true;

            this.setSelectableTopicsParameters( params );
            this.setTopicFilterDefaults( [ topic ] );
        }
    }
} )

