<template>
    <article class="container-fluid p-3" data-content-type="PERSONA">
        <section class="bg-white">
            <div class="container-fluid p-0">
                <div v-if="ready && !insights.is_data_available"
                     class="p-5 text-center"
                >
                    <p>PersonaBuilder encountered an error while loading report data. Would you like to try again?</p>

                    <button
                        class="btn btn-success btn-arrow-right"
                        type="button"
                        @click="$router.go()"
                    >
                        <font-awesome-icon :icon="['solid', 'redo']"/>
                        Reload Page
                    </button>
                </div>

                <div v-if="ready"
                     class="report-container"
                     :class="{'bg-primary': !printMode}"
                >
                    <div class="report-section bg-white">
                        <!-- Header -->
                        <div v-if="!printMode" class="row p-2">
                            <div class="col-auto position-relative align-self-center"
                                 data-intent="representative-photo"
                            >
                                <Suspense>
                                    <choose-representative-photo
                                        v-memo="[persona.userPhoto?.id, persona.photoCharacteristics]"
                                        :insights="insights"
                                        :object="persona"
                                        @update-user-photo="updateUserPhoto"
                                    />
                                    <template #fallback>
                                        <div role="status" class="text-primary spinner-border spinner-border-sm p-2 mx-3">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </template>
                                </Suspense>
                            </div>

                            <div class="col ps-0 align-self-center">
                                <form @submit.prevent="updatePersonaName">
                                    <div class="text-primary header-title d-flex">
                                        <div class="py-0 align-self-center">
                                            <strong>Persona:</strong>
                                        </div>
                                        <div class="py-0 px-1 flex-grow-1 align-self-center">
                                            <input class="form-control mx-n1 px-1 w-100"
                                                   :class="{'is-invalid': personaNameValidation.error ? personaNameValidation.error.message : false, 'bg-white': sharedMode}"
                                                   data-action="update-build-name"
                                                   :disabled="sharedMode ? true : null || personaNameLoading ? true : null"
                                                   maxlength=100
                                                   type="text"
                                                   v-model="editablePersonaName"
                                                   @blur="blurUpdatePersonaName"
                                                   @focus="allowUpdatePersonaName = true"
                                            />
                                        </div>
                                        <div class="py-0 px-1 align-self-center text-danger"
                                             v-show="personaNameValidation.error.message.length"
                                        >
                                            {{ personaNameValidation.error.message }}
                                        </div>
                                    </div>
                                </form>

                                <div class="d-flex flex-row">
                                    <ul class="list-inline d-inline mb-0 me-3">
                                        <li class="list-inline-item">
                                            <a href @click.prevent="showSummaryInfo">
                                                <font-awesome-icon :icon="['solid', 'circle-info']" fixed-width/>
                                            </a>
                                        </li>

                                        <li class="list-inline-item">
                                            <div class="spinner-border spinner-border-sm" role="status" v-if="pdfSharing">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                            <a v-else
                                               href
                                               :title="`Download or Share PDF`" v-tooltip
                                               @click.prevent="shareModal('pdf', 'show')"
                                            >
                                                <font-awesome-icon :icon="['regular', 'file-pdf']" fixed-width/>
                                            </a>
                                        </li>

                                        <li class="list-inline-item">
                                            <div class="spinner-border spinner-border-sm" role="status" v-if="reportSharing">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                            <a v-else
                                               href
                                               :title="`Share this Report`" v-tooltip
                                               @click.prevent="shareModal('report', 'show')"
                                            >
                                                <font-awesome-icon :icon="['solid', 'share-from-square']" fixed-width/>
                                            </a>
                                        </li>

                                        <li class="list-inline-item">
                                            <router-link
                                                :disabled="sharedMode ? true : null"
                                                :event="!sharedMode ? 'click' : ''"
                                                :title="'Build this Audience'" v-tooltip
                                                :to="{
                                                    name: 'audienceBuild',
                                                    params: {
                                                        id: persona.id,
                                                        tab: audienceTabs.CONFIRMATION,
                                                    },
                                                    query: {
                                                        mode: audienceMode.NEW,
                                                        // subnav: false,
                                                    }
                                                }"
                                            >
                                                <font-awesome-icon :icon="['regular', 'bullseye-arrow']" fixed-width/>
                                            </router-link>
                                        </li>
                                    </ul>
                                    <em class="header-detail small">Built on {{ buildDate }}</em>
                                    <div class="ms-auto">
                                        <div v-if="allowUpdatePersonaName" class="ms-auto me-2">
                                            <a href="#" class="no-color text-success"
                                               :disabled="personaNameLoading ? true : null"
                                               @click.prevent="updatePersonaName"
                                            >
                                                <font-awesome-icon :icon="['regular', 'circle-check']" size="sm"/>
                                            </a>

                                            <a href="#" class="ms-1 no-color text-danger"
                                               :disabled="personaNameLoading ? true : null"
                                               @click.prevent="updatePersonaNameCancel"
                                            >
                                                <font-awesome-icon :icon="['regular', 'circle-xmark']" size="sm"/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-auto my-auto">
                                <img class="brand-logo"
                                     :onerror="Utils.imageFallback(defaultBrandLogoPath)"
                                     :src="logoPath"
                                />
                            </div>
                        </div>
                        <!-- END header -->

                        <!-- Subnav moved per SDSPOT-73 -->
                        <subnav v-if="!printMode"
                            class="border-top"
                            :nav-links="subnavLinks"
                            placement="page"
                            :show-disabled-links="false"
                            v-memo="[subnavLinks]"
                        />

                        <section class="bg-white">
                            <div v-if="reportReady">
                                <div v-if="!printMode" class="section-title h3 text-center my-3">
                                    {{ sectionTitle }}

                                    <div v-if="pageContextMenu" class="d-inline-block" data-bs-toggle="dropdown">
                                        <div class="dropdown dropdown-bubble">
                                            <a aria-expanded="false"
                                            aria-haspopup="true"
                                            class="d-block hover-orange-75 show-orange"
                                            data-bs-toggle="dropdown"
                                            href
                                            id="pageContextMenu"
                                            role="button"
                                            >
                                                {{ pageContextLabel }}
                                                <font-awesome-icon
                                                    class="ms-2 align-middle"
                                                    icon="angle-down"
                                                    fixed-width
                                                />
                                            </a>
                                            <div
                                                aria-labelledby="pageContextMenu"
                                                class="dropdown-menu dropdown-menu-end"
                                            >
                                                <a v-for="(option, index) of pageContextMenu" :key="index"
                                                class="dropdown-item"
                                                :class="{'router-link-active active disabled': option.context === pageContext}"
                                                href
                                                @click.prevent="pageContext = option.context"
                                                >
                                                    {{ option.label }}
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <on-page-help :context="pageContext"/>
                                </div>

                                <div class="d-flex flex-column-reverse">
                                    <!-- Charts -->
                                    <div>
                                        <!-- Non-standard custom layout component, e.g. for geographic data -->
                                        <component
                                            v-bind:is="customLayout.component"
                                            :chart-data="customLayout.chartData"
                                            :detail="customLayout.detail || {}"
                                            :key="customComponentKey"
                                            :params="customLayout.params || {}"
                                            ref="customComponent"
                                            :report-ready="reportReady"
                                            @set-sort-group="sortGroup = $event"
                                        >
                                            <template #afterChart v-if="customLayout.afterChart">
                                                <div v-html="customLayout.afterChart"></div>
                                            </template>
                                        </component>
                                    </div>

                                    <chart-grid
                                        v-if="!customLayout"
                                        :chart-wrapper-class="{
                                            'offset-lg-3': layoutColumns === 1,
                                            'col-lg-6': layoutColumns > 0,
                                            'col': layoutColumns < 0
                                        }"
                                        :charts="chartListData"
                                        :charts-associated-data="chartListAssociatedData"
                                        :key="sectionKey"
                                    />

                                    <!-- TODO: factor out into a component if needed -->
                                    <template v-if="sortGroupOptions.length">
                                        <div class="sort-options text-center col-10 col-lg-8 offset-1 offset-lg-2 mt-3">
                                            <div class="btn-group select-group d-flex" role="group">
                                                <template v-for="(button, index) of sortGroupOptions" :key="index">
                                                    <button v-if="button.hasOwnProperty('group')"
                                                            class="btn media-body w-100"
                                                            :class="[`select-${button.color}`, {active: sortGroup === button.group}]"
                                                            @click="sortGroup = button.group"
                                                    >
                                                        {{ button.label }}
                                                    </button>

                                                    <!-- Dropdown filters -->
                                                    <div v-if="button.hasOwnProperty('items')"
                                                        class="btn-group dropdown-bubble"
                                                        role="group"
                                                    >
                                                        <div class="dropdown">
                                                            <button
                                                                :id="button.id"
                                                                :class="`select-${button.color}`"
                                                                :disabled="chartDataLoading"
                                                                class="btn btn-secondary dropdown-toggle media-body"
                                                                type="button"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false">
                                                                {{ button.label }}
                                                            </button>
                                                            <ul class="dropdown-menu" :aria-labelledby="button.id" v-show="!chartDataLoading">
                                                                <li v-for="(item, index) of button.items" :key="index">
                                                                    <a
                                                                        class="dropdown-item pointer"
                                                                        :href="undefined"
                                                                        @click.prevent="filterSettings.count = item.value">
                                                                        {{ item.label }}
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                            <div v-else>
                                <spinner text="Building..." />
                            </div>
                        </section>

                        <!-- Font Awesome elements for late-binding in Highcharts -->
                        <div class="d-none">
                            <font-awesome-icon
                                data-intent="font-awesome-icon"
                                data-type="twitterMention"
                                :icon="['solid', 'at']"
                            />
                            <font-awesome-icon
                                data-intent="font-awesome-icon"
                                data-type="twitterHashtag"
                                :icon="['solid', 'hashtag']"
                            />
                            <font-awesome-icon
                                data-intent="font-awesome-icon"
                                data-type="twitterTerm"
                                :icon="['solid', 'quote-left']"
                            />
                        </div>
                    </div>

                    <div v-if="!printMode"
                         class="row p-4"
                         :class="{'mt-n4': !params.tab}"
                    >
                        <div class="col-lg-2 offset-lg-5">
                            <img alt="Powered by GRAPHMASSIVE®"
                                 class="img-fluid"
                                 src="/assets/images/powered-by-graphmassive-gray-25.svg"
                            />
                        </div>
                    </div>
                </div>

                <!-- Error states -->
                <div v-else-if="containsInactiveMarket">
                    <div class="alert alert-warning">
                        This report was created with market affinity data that we have since purged from our system. If you want to view the report, you'll need to rebuild it.
                    </div>
                </div>
                <div v-else-if="containsSensitiveData">
                    <div class="alert alert-warning">
                        This report was created with sensitive data that we have since purged from our system. If you want to view the report, you'll need to rebuild it.
                    </div>
                </div>

                <spinner v-else id="personaReportMainLoader" text="Building..."/>
            </div>

            <bootstrap-modal
                body-class="text-normal"
                class="modal-xl"
                content-class="bg-blue-25 border-primary"
                :hide-on-backdrop="false"
                title-class="text-primary"
                :id="summaryInfoModalId"
            >
                <template #title class="text-primary">Persona Summary Information</template>
                <template #body>
                    <div class="row mb-3 text-primary">
                        <div class="col text-start">
                            Persona Built By:
                            <strong>{{ personaBuiltBy }}</strong>
                        </div>
                        <div class="col text-end">
                            Persona based on
                            <strong>{{ personaCount }}</strong> people
                        </div>
                    </div>

                    <h6>
                        <i>Persona Characteristics</i>
                    </h6>
                    <div id="characteristics">
                        <characteristics
                            v-memo="[decoratedPersona]"
                            class="text-muted px-0"
                            inner-container-class="card-body mt-n3 bg-blue-10"
                            :mode="personaMode.REPORT"
                            :persona="decoratedPersona"
                        />
                    </div>
                </template>
            </bootstrap-modal>
        </section>

        <share
            ref="share"
            :content-title="editablePersonaName"
            content-type="Persona"
            :context="shareContext"
            :link="shareLink(shareContext)"
        />
    </article>
</template>

<script lang="ts">
    import {Component, Vue, Watch, toNative} from 'vue-facing-decorator';
    import {nextTick, toRaw, markRaw, defineAsyncComponent} from 'vue';
    import clone from 'lodash-es/clone';
    import forOwn from 'lodash-es/forOwn';
    import size from 'lodash-es/size';
    import Highcharts from 'highcharts';
    import HighchartsTreemap from 'highcharts/modules/treemap';
    import {Chart} from 'highcharts-vue';
    import BootstrapModal from 'Components/common/bootstrap/modal.vue';
    import FontAwesomeIcon from 'Components/common/font-awesome-icon.vue';
    import GeographicReport from 'Components/persona/report/custom-layout/geographic-report.vue';
    import SocialActivity from 'Components/persona/report/custom-layout/social-activity.vue';
    import WedgeChartSection from 'Components/common/report/wedge-chart-section.vue';
    import ChartGrid from 'Components/persona/report/chart-grid.vue';
    import PersonaSummary from 'Components/persona/report/custom-layout/persona-summary.vue';
    import {AudienceMode, AudienceTabs} from 'Stores/audience/audience';
    import {usePersonaStore, PersonaMode, PersonaLoaders} from 'Stores/persona';
    import {useAccountStore} from 'Stores/account';
    import {ActivityType} from 'Stores/activity';
    import {PhotoAssignment} from 'Stores/common/models';
    import {useInsightStore} from 'Stores/insight';
    import {useJobStore} from 'Stores/job';
    import {useAppStore} from 'Stores/common/app';
    import {UserPhoto} from 'Stores/user';
    import {Modal} from 'bootstrap';
    import {useHead} from '@unhead/vue';
    import {useRoute} from 'vue-router';
    import {isEmptyArray, isObject} from 'Utilities/inspect';
    import * as ReportUtilities from 'Utilities/reports';
    import * as Utils from 'Utilities/utils';
    import * as StaticUtils from 'Utilities/utils-static';
    import * as validators from 'Utilities/validators';
    import {Events} from 'Utilities/immutables';
    import PersonaSummaryPdf from 'Components/persona/report/custom-layout/persona-summary-pdf.vue';
    const Spinner = () => import(/* webpackChunkName: "Spinner" */ 'Components/common/spinner/spinner.vue');
    const Subnav = () => import(/* webpackChunkName: "Subnav" */ 'Components/app/navigation/subnav.vue');

    // https://www.highcharts.com/docs/chart-and-series-types/treemap
    HighchartsTreemap(Highcharts);

    @Component<PersonaReport>({
        emits: [Events.HIDE_TOOLTIPS],
        setup() {
            const route = useRoute();
            const appStore = useAppStore();
            const accountStore = useAccountStore();
            const personaStore = usePersonaStore();
            const jobStore = useJobStore();
            const insightStore = useInsightStore();

            useHead({
                bodyAttrs: {
                    class: route.name === 'personaReportPDF' ?
                        ['bg-white', 'letter', 'm-0'] :
                        ['bg-primary'],
                },
                title: `Persona: Loading...`,
            });

            return {appStore, accountStore, personaStore, jobStore, insightStore};
        },
        components: {
            highcharts: Chart,
            BootstrapModal,
            Characteristics: defineAsyncComponent(() =>
                import('Components/persona/characteristics.vue')
            ),
            ChartGrid,
            ChooseRepresentativePhoto: defineAsyncComponent(() =>
                import('Components/common/report/choose-representative-photo.vue')
            ),
            FontAwesomeIcon,
            GeographicReport,
            OnPageHelp: defineAsyncComponent(() =>
                import('Components/common/on-page-help.vue')
            ),
            Share: defineAsyncComponent(() =>
                import('Components/common/report/share.vue')
            ),
            SocialActivity,
            Spinner,
            Subnav,
            WedgeChartSection
        }
    })
    class PersonaReport extends Vue {
        allowUpdatePersonaName: boolean = false;
        asyncData: any = [];
        audienceMode = AudienceMode;
        audienceTabs = AudienceTabs;
        blankBrandLogoPath = '/assets/images/transparent.png';
        chartDataCache: any = {};
        customLayout: any = false;
        defaultBrandLogoPath = '/assets/images/wiland-logo.svg';
        editablePersonaName: string = '';
        // fieldDictionary: any = {};
        filterSettings: any = {
            count: 0
        };
        insights: any = {
            is_data_available: false,
        };
        pageContext: string = '';
        params = {
            id: '',
            tab: '',
            section: '',
        };
        persona: any = {};
        personaHasConglomerateRfmCategoryData: boolean = false;
        personaHasConglomerateRfmMarketData: boolean = false;
        personaHasHighLevelRfmData: boolean = false;
        personaHasSocialRollupData: boolean = false;
        personaMode = PersonaMode;
        personaNameValidationDefault = {
            valid: false,
            error: {
                message: ''
            }
        };
        personaNameValidation = Utils.dereference(this.personaNameValidationDefault);
        printedChartWidth: string = '1015'; // Annoying that this is necessary for print...
        ready: boolean = false;
        reportReady = false;
        sentences: any = {};
        shareContext: string = '';
        // shareLinkCopied: boolean = false;
        shareToken: any = {};
        sortGroup: string | null = '';
        summaryInfoModal: Modal | null = null;
        summaryInfoModalId: string = 'summaryInfoModal';
        personaReportLoader: string = `${PersonaLoaders.PREP}-page`;
        personaNameLoader: string = `${PersonaLoaders.SAVING}-name`;
        Utils = Utils;

        async mounted() {
            this.appStore.setLoader(this.personaReportLoader);
            this.editablePersonaName = this.persona.name;
            await this.initialize(true);
            this.reportReady = this.insights.is_data_available
                && !this.containsSensitiveData
                && !this.containsInactiveMarket;
            this.summaryInfoModal = new Modal(document.getElementById(this.summaryInfoModalId)!);
            this.appStore.clearLoader(this.personaReportLoader);
            this.ready = this.reportReady;
        }

        get account() {
            return this.accountStore.getAccount;
        }

        get buildDate(): string {
            return this.Utils.dateFormat(this.job.completedAt, {format: 'englishDate'});
        }

        get chartList(): string[] | any[] {
            switch (this.$route.name) {
                case 'personaReportPDF':
                    return ['dma_code', 'metro_area', 'region', 'state'];
            }

            switch (this.params.tab) {
                case undefined: // Summary (default tab)
                case '':
                    return ['dma_code'];

                case 'brands':
                case 'interests':
                    switch (this.params.section) {
                        case 'summary': {
                            let chartList = [`${this.params.section}_${this.params.tab}_twitter`];
                            if (this.personaHasSocialRollupData) {
                                // Newer Personas may have rollup data or alternate charts
                                chartList.push(`${this.params.section}_rollup_${this.params.tab}_twitter`);
                            }

                            return chartList;
                        }

                        case 'top':
                            return [
                                `${this.params.section}_${this.params.tab}_twitter`,
                                `${ReportUtilities.NON_CHART_PREFIX}unique_facts_${this.params.section}_${this.params.tab}_twitter`,
                            ];

                        default: {
                            // Affinities
                            if (this.pageContext) {
                                return [
                                    `affinity_${this.pageContext}_${this.params.tab}_twitter`,
                                    `${ReportUtilities.NON_CHART_PREFIX}unique_facts_affinity_${this.pageContext}_${this.params.tab}_twitter`,
                                ];
                            }

                            return [];
                        }
                    }

                case 'consumer-spending':
                    switch (this.params.section) {
                        case 'summary':
                            return ['consumer_spending_summary'];
                        case 'top':
                            return ['consumer_spending_top'];
                        default:
                            // Categories
                            if (this.pageContext) {
                                const category = this.insights.consumer_spend?.categories
                                    .find((category: any) => Utils.slug(category.name) === this.pageContext);

                                if (!category) {
                                    return [];
                                }

                                return Utils.sortByProperty(clone(category.subCategories), 'displayOrder', 'asc')
                                    .map(subcategory => `consumer_spending_category_${this.pageContext}_${subcategory.displayOrder}`);
                            }

                            return [];
                    }

                case 'custom-topics':
                    if (this.params.section === 'social-characteristics') {
                        return [
                            'social_engagement_twitter',
                            `${ReportUtilities.NON_CHART_PREFIX}unique_facts_social_engagement_twitter`,
                        ];
                    } else {
                        return [
                            `user_defined_social_engagement_twitter_${this.params.section}`,
                            `${ReportUtilities.NON_CHART_PREFIX}unique_facts_user_defined_social_engagement_twitter_${this.params.section}`,
                        ];
                    }

                case 'demographics':
                    switch (this.params.section) {
                        case 'age-income-home':
                            return ['age_range', 'income_range', 'net_worth_range', 'home_value_range'];
                        case 'gender-marriage-kids':
                            return ['gender', 'marital_status', 'children_age_range', 'children_present_in_hh', 'household_composition'];
                        case 'politics-job-residence':
                            return ['political_party', 'county_size', 'occupation_type', 'occupation_type_index', 'length_of_residence_range', 'dwelling_type'];
                    }
                    break;

                case 'geographic-areas':
                    switch (this.params.section) {
                        //         case 'media-markets':
                        //             return ['dma_code'];
                        case 'metro-areas':
                            return ['metro_area'];
                        //         case 'regions':
                        //             return ['region'];
                        //         case 'states':
                        //             return ['state'];
                    }
                    break;

                case 'geography':
                    switch (this.pageContext) {
                        case 'media-markets':
                        default:
                            return ['dma_code'];
                        case 'regions':
                            return ['region'];
                        case 'states':
                            return ['state'];
                    }

                case 'market-affinity':
                    switch (this.params.section) {
                        case 'all':
                        default:
                            return [`conglomerate_rfm_market_summary:${this.pageContext}`];

                        case 'markets':
                            return [
                                `conglomerate_rfm_market:${this.pageContext}:metrics`,
                                `conglomerate_rfm_market:${this.pageContext}:participation`,
                                `conglomerate_rfm_market:${this.pageContext}:ord_avg_amt`,
                                `conglomerate_rfm_market:${this.pageContext}:ord_lat_amt`,
                            ];

                        case 'overview':
                            return [
                                {chartId: 'conglomerate_rfm_market_overview_treemap', cols: 'lg-10 offset-lg-1'},
                                'conglomerate_rfm_market_overview_top',
                                'conglomerate_rfm_market_overview_standout',
                            ];
                    }

                case 'past-purchases':
                    switch (this.params.section) {
                        case 'clothing-children':
                            return ['purch_rollup_womens_fashion', 'purch_rollup_mens_fashion', 'purch_rollup_childrens_products'];
                        case 'finance-shopping-style':
                            return ['purch_rollup_where_they_spend', 'purch_rollup_how_they_spend', 'purch_rollup_financial_purchases'];
                        case 'interests-activities':
                            return ['purch_rollup_outdoor_sports', 'purch_rollup_homelife', 'purch_rollup_interests_pastimes', 'purch_rollup_soho_home_entertainment', 'purch_rollup_travel', 'purch_rollup_hobbies'];
                    }
                    break;

                case 'rfm':
                    switch (this.params.section) {
                        case 'credit-card':
                            return [
                                // {chartId: 'high_level_rfm:rfm_idx:gauge', cols: 'lg-6 offset-lg-3'},
                                {chartId: 'rfm_credit_all', cols: 'lg-10 offset-lg-1'},
                                'high_level_rfm:cc_amt',
                                'high_level_rfm:cc_ord_num',
                                {chartId: 'rfm_credit_major', cols: 'lg-10 offset-lg-1'},
                                'high_level_rfm:cc_major_amt',
                                'high_level_rfm:cc_major_ord_num',
                            ];
                        case 'response-rate':
                            return [
                                // 'high_level_rfm:recency_idx:gauge',
                                'high_level_rfm:ord_num',
                                'high_level_rfm:mem_buyer_num',
                                'high_level_rfm:lat_incep_months',
                                'high_level_rfm:incep_source_l12_num',
                                'high_level_rfm:recency'
                            ];
                        case 'revenue':
                            return [
                                {chartId: 'rfm_ord_all', cols: 'lg-10 offset-lg-1'},
                                // TODO: Untitled stacked bar chart with ord_avg_amt, ord_lrg_amt, ord_lat_amt
                                'high_level_rfm:ord_avg_amt',
                                'high_level_rfm:ord_lrg_amt',
                                'high_level_rfm:ord_lat_amt',
                                'high_level_rfm:tot_amt',
                                'high_level_rfm:tot_amt_per_mo',
                            ];
                    }
                    break;
            }

            return [];
        }

        get chartListAssociatedData() {
            let data: any[] = [];
            for (let chartId of this.chartList) {
                if (typeof chartId === 'object') {
                    chartId = chartId.chartId;
                }
                const associatedId = this.chartAssociatedId(chartId);
                if (typeof associatedId === 'string') {
                    data.push({
                        chartId,
                        associatedId,
                        data: ReportUtilities.chartAssociatedData(
                            associatedId,
                            this.insights,
                            {
                                params: this.params,
                                fieldDictionary: this.fieldDictionary,
                            }
                        ),
                    });
                }
            }

            return data;
        }

        get chartListData() {
            return this.chartList.map((chartId: string) => {
                let chartSettings: any = {};
                if (typeof chartId === 'object') {
                    chartSettings = clone(chartId);
                    chartId = chartSettings.chartId;
                }

                return Object.assign({}, chartSettings, {
                    chartId,
                    data: this.chartData(chartId),
                });
            });
        }

        get containsInactiveMarket(): boolean {
            return this.persona?.containsInactiveMarket;
        }

        get containsSensitiveData(): boolean {
            return this.persona?.containsSensitiveData;
        }

        get customComponentKey() {
            return `customcomp-${this.helpId}`;
        }

        get decoratedPersona() {
            return this.personaStore.getPersona;
        }

        get fieldDictionary() {
            return this.accountStore.getAccountDictionary;
        }

        get helpId() {
            let helpId = 'persona-report';
            if (this.params.tab) {
                helpId += `-${this.params.tab}`;

                // Handle special cases
                switch (this.params.tab) {
                    case 'brands':
                    case 'consumer-spending':
                    case 'interests':
                        switch (this.params.section) {
                            case 'categories':
                                return `${helpId}-${this.pageContext}`;
                        }
                        break;

                    case 'custom-topics':
                        switch (this.params.section) {
                            case 'social-characteristics':
                                // Use the default handling below
                                break;

                            default:
                                // Use the generic help content
                                return helpId;
                        }
                        break;

                    case 'geography':
                        return `${helpId}-${this.pageContext}`;

                    case 'market-affinity':
                        switch (this.params.section) {
                            case 'all':
                                return `${helpId}-${this.pageContext}`;
                        }
                        break;
                }

                // Default handling
                switch (this.params.section) {
                    case undefined:
                    case '':
                        // Nothing to add
                        break;

                    default:
                        helpId += `-${this.params.section}`;
                        break;
                }
            } else {
                // Summary
                helpId += '-summary';
            }

            return helpId;
        }

        get job() {
            return this.jobStore.getJob;
        }

        get layoutColumns() {
            switch (this.params.tab) {
                case 'brands':
                case 'interests':
                    switch (this.params.section) {
                        case 'summary':
                            return this.personaHasSocialRollupData ? 2 : 1;
                    }

                    break;

                case 'consumer-spending':
                    return ['summary', 'top'].includes(this.params.section) ?
                        -1 : // Full width column
                        2;

                // case 'custom-topics':
                // if (this.params.section === 'social-characteristics') {
                //     return size(this.insights.social.customTopics.groupDefinitionTopic) > 2 ? 2 : 1;
                // }
                //
                // return 1;

                // case 'geographic-areas':
                case 'geography':
                    return 0;

                case 'market-affinity':
                    return ['all'].includes(this.params.section) ?
                        -1 : // Full width column
                        2;
                    // return -1; // Full width column
            }

            return 2;
        }

        get logoPath(): string {
            return this.persona.account?.logoFile?.filePathUri?.length > 0 ?
                this.blankBrandLogoPath :
                this.defaultBrandLogoPath;
        }

        get pageContextLabel() {
            if (!this.pageContextMenu) {
                return '';
            }

            return this.pageContextMenu!.find((item: any) => item.context === this.pageContext)?.label || 'UNKNOWN CONTEXT';
        }

        get pageContextMenu(): any[] | false {
            this.pageContext = '';
            if (!this.ready) {
                return false;
            }

            let menuItems: any[] = [];
            switch (this.params.tab) {
                case 'brands':
                case 'interests':
                    switch (this.params.section) {
                        case 'categories':
                            // Get data from API
                            const defaultTopics = this.insights.social[this.params.tab].defaultTopics;
                            for (const context in defaultTopics) {
                                for (const topic of defaultTopics[context]) {
                                    const label = topic.name;
                                    // Check for duplicate entry
                                    if (!menuItems?.some(checkTopic => checkTopic.label === label)) {
                                        menuItems?.push({
                                            pageId: `${this.params.tab}_${this.params.section}`,
                                            label,
                                            context: Utils.slug(label),
                                        })
                                    }
                                }
                            }
                            break;

                        default:
                            return false;
                            // return menuItems;
                    }
                    break;

                case 'consumer-spending':
                    switch (this.params.section) {
                        case 'summary':
                        case 'top':
                            return false;

                        default:
                            // Categories
                            menuItems = this.insights.consumer_spend?.categories
                                .map((category: any) => {
                                    const label = category.name,
                                        context = Utils.slug(label);

                                    return {
                                        pageId: `${this.params.tab}_${this.params.section}`,
                                        label,
                                        context,
                                    }
                                });
                            menuItems = Utils.sortByProperty(menuItems, 'label', 'asc');
                    }
                    break;

                case 'geography':
                    menuItems = ['Media Markets', 'States', /*'Metro Areas',*/ 'Regions'].map(label => {
                        const context = Utils.slug(label);
                        return {
                            pageId: `${this.params.tab}_${context}`,
                            label,
                            context,
                        }
                    });
                    break;

                case 'market-affinity':
                    switch (this.params.section) {
                        case 'all':
                            // Get data from API
                            for (const market of this.insights.conglomerate_rfm.markets) {
                                // const market = this.insights.conglomerate_rfm.markets[key];
                                // TODO: decorate category/subcategory ID on market level?
                                const category = this.insights.conglomerate_rfm.categories
                                    .find((c: any) => c.subCategories.some((s: any) => s.markets.some((m: any) => market.id === m.id)));
                                const label = market.category;
                                const context = category.id;

                                if (menuItems.find(menuItem => menuItem.context === context)) {
                                    continue;
                                }

                                menuItems?.push({
                                    pageId: `${this.params.tab}_${this.params.section}`,
                                    label,
                                    context,
                                })
                            }
                            break;

                        case 'markets':
                            for (const market of this.personaConglomerateRfmMarketSelections) {
                                const label = `${market.category} > ${market.name}`;
                                // const context = `${market.id}:${Utils.slug(label)}`;
                                const context = market.id;

                                if (menuItems.find(menuItem => menuItem.context === context)) {
                                    continue;
                                }

                                menuItems?.push({
                                    pageId: `${this.params.tab}_${this.params.section}`,
                                    label,
                                    context,
                                })
                            }
                            // menuItems = Utils.sortByProperty(menuItems, 'label', 'asc');
                            break;
                    }

                    if (menuItems.length) {
                        menuItems = Utils.sortByProperty(menuItems, 'label', 'asc');
                    }
                    break;
            }

            if (menuItems.length) {
                this.pageContext = menuItems[0].context;

                return menuItems;
            }

            // this.pageContext = '';

            return false;
        }

        get personaReportLoading(): boolean {
            return !this.appStore.getLoaderActive(this.personaReportLoader);
        }

        get personaBuiltBy(): string {
            return `${this.persona.updated?.user?.firstName || 'unknown'} ${this.persona.updated?.user?.lastName || 'unknown'}`;
        }

        get personaCount(): string {
            // return <string>Utils.formatValue(this.insights.social?.groupMetrics?.consumerCount || 0, 'separated'); // Old - Twitter/Instagram count
            return <string>Utils.formatValue(this.insights.social?.groupMetrics?.groupCount || 0, 'separated');
        }

        get personaConglomerateRfmMarketSelections(): any[] {
            if (!this.personaHasConglomerateRfmMarketData) {
                return [];
            }

            return Utils.sortByProperty(
                this.persona.groupDefinition.include.subgroups
                    .find((subgroup: any) => subgroup.hasOwnProperty('conglomerateRfm'))?.conglomerateRfm.markets,
                'name',
                'asc'
            );
        }

        get personaNameLoading(): boolean {
            return this.appStore.getLoaderActive(this.personaNameLoader);
        }

        get printMode(): boolean {
            return this.$route.name === 'personaReportPDF';
        }

        get sectionKey() {
            let sectionKey = this.params.tab;
            if (this.params.section) {
                sectionKey += `-${this.params.section}`;
            }

            return sectionKey;
        }

        get sectionTitle() {
            let title = 'UNKNOWN';

            // const defaultAffinityTitleCallback = () => {
            //     let titleSuffix = ': <ERROR: UNKNOWN AFFINITY>';
            //     const defaultTopics = this.insights.social[this.params.tab].defaultTopics;
            //     for (const context in defaultTopics) {
            //         if (!defaultTopics[context].some(topic => {
            //             if (this.params.section === Utils.slug(topic.category)) {
            //                 titleSuffix = `: ${topic.category}`;
            //                 if (topic.subCategory?.length) {
            //                     titleSuffix = `: ${topic.subCategory}`;
            //                 }
            //                 return true;
            //             } else if (this.params.section === Utils.slug(topic.name)) {
            //                 titleSuffix = `: ${topic.name}`;
            //                 return true;
            //             }
            //         })) {
            //             console.error(`NO TOPIC FOUND FOR ${this.params.tab} / ${this.params.section}`);
            //         }
            //     }
            //
            //     return titleSuffix;
            // };

            switch (this.params.tab) {
                case undefined: // Summary (default tab)
                case '':
                    title = `${this.persona.name}: At A Glance`;
                    break;

                case 'brands':
                    title = 'Brand Affinity: ';

                    switch (this.params.section) {
                        case 'summary':
                            title += `Summary by Category`;
                            break;
                        case 'top':
                            title += `Full List of Top Brands`;
                            break;
                    }
                    break;

                case 'consumer-spending':
                    title = 'Consumer Spending: ';

                    switch (this.params.section) {
                        case 'summary':
                            title += `Summary by Category`;
                            break;
                        case 'top':
                            title += `Top 25`;
                            break;
                        // default:
                        //     const categoryName = this.insights.consumer_spend?.categories
                        //         .find(category => Utils.slug(category.name) === this.params.section)
                        //         .name;
                        //     title += `: ${categoryName}`;
                        //     break;
                    }
                    break;

                case 'custom-topics':
                    switch (this.params.section) {
                        case 'social-characteristics':
                            title = 'Social Characteristics Used to Define this Persona';
                            break;
                        default:
                            const topicName = this.insights?.social?.customTopics?.userDefinedTopics
                                .find(topic => Utils.slug(topic.category || topic.name) === this.params.section)
                                .name;
                            title = `Added Chart: ${topicName}`;
                            break;
                    }
                    break;

                case 'demographics':
                    title = 'Persona Demographics';

                    switch (this.params.section) {
                        case 'age-income-home':
                            title += `: Age, Income, & Home Value`;
                            break;
                        case 'gender-marriage-kids':
                            title += `: Gender, Marriage, & Kids`;
                            break;
                        case 'politics-job-residence':
                            title += `:  Politics, Job, & Residence`;
                            break;
                    }
                    break;

                case 'geographic-areas':
                case 'geography':
                    title = 'Persona Geography: ';

                    switch (this.params.section) {
                        case 'metro-areas':
                            title += `Distribution by Metro Area`;
                            break;
                    }
                    break;

                case 'interests':
                    title = 'Interest Affinity';

                    switch (this.params.section) {
                        case 'summary':
                            title += `: Summary by Category`;
                            break;
                        case 'top':
                            title += `: Full List of Top Interests`;
                            break;
                        default:
                            title += ': ';
                            break;
                    }
                    break;

                case 'market-affinity':
                    switch (this.params.section) {
                        case 'all':
                        default:
                            title = `All Markets:`;
                            break;

                        case 'markets':
                            title = `Markets in Persona:`;
                            break;

                        case 'overview':
                            title = `Market Affinity Overview`;
                            break;
                    }
                    break;

                case 'past-purchases':
                    title = 'Past Purchases';

                    switch (this.params.section) {
                        case 'clothing-children':
                            title += `: Clothing and Children Goods`;
                            break;
                        case 'finance-shopping-style':
                            title += `: Finance and Shopping Styles`;
                            break;
                        case 'interests-activities':
                            title += `: Interests and Activities`;
                            break;
                    }
                    break;

                case 'rfm':
                    title = 'RFM: ';

                    switch (this.params.section) {
                        case 'credit-card':
                            title += `Credit Card Usage`;
                            break;
                        case 'response-rate':
                            title += `Response Rate Drivers`;
                            break;
                        case 'revenue':
                            title += `Revenue Drivers`;
                            break;
                    }
                    break;

                case 'social-activity':
                    title = 'Persona Social Activity: Social Graph, Day Parts, Top Content';
                    break;
            }

            return title;
        }

        get sharedMode() {
            return this.$route.path.indexOf('/share/') > -1 && this.$route.params.token?.length > 0;
        }

        get sortGroupOptions() {
            const defaultSortGroups = [
                {
                    group: 'index',
                    color: 'green',
                    label: `Sort by ${ReportUtilities.indexLabel}`,
                },
                {
                    group: 'groupRatio',
                    color: 'blue',
                    label: `Sort by ${ReportUtilities.groupRatioLabel}`,
                },
            ];

            switch (this.params.tab) {
                case 'brands':
                case 'interests':
                    switch (this.params.section) {
                        case 'summary':
                            this.sortGroup = this.sortGroup || 'index';

                            return defaultSortGroups.concat([
                                // {
                                //     group: 'index',
                                //     color: 'green',
                                //     label: `Top 20 by ${ReportUtilities.indexLabel}`,
                                // },
                                // {
                                //     group: 'groupRatio',
                                //     color: 'blue',
                                //     label: `Top 20 by ${ReportUtilities.groupRatioLabel}`,
                                // },
                                {
                                    group: 'groupPenetrationRatio',
                                    color: 'yellow',
                                    label: 'Sort by Depth of Interest',
                                },
                            ]);

                        case 'top':
                            this.sortGroup = this.sortGroup || 'index';
                            this.filterSettings.count = this.filterSettings?.count || 100;

                            return [
                                {
                                    id: 'topFilter',
                                    color: 'orange',
                                    label: `Top ${this.filterSettings.count}`,
                                    items: [
                                        {
                                            label: 'Top 100',
                                            value: 100,
                                            callback: () => {
                                                this.filterSettings.count = 100;
                                            }
                                        },
                                        {
                                            label: 'Top 500',
                                            value: 500,
                                            callback: () => {
                                                this.filterSettings.count = 500;
                                            }
                                        },
                                    ]
                                },
                            ].concat(defaultSortGroups);

                        default:
                            // Affinities
                            this.sortGroup = this.sortGroup || 'index';

                            return defaultSortGroups;
                    }

                case 'consumer-spending':
                case 'market-affinity':
                    switch (this.params.section) {
                        case 'markets':
                        case 'overview':
                            return [];

                        default:
                            this.sortGroup = this.sortGroup || 'index';

                            return [
                                {
                                    group: 'index',
                                    color: 'green',
                                    label: `Sort by ${ReportUtilities.indexLabel}`,
                                },
                                {
                                    group: 'groupRatio',
                                    color: 'blue',
                                    // label: 'Sort by Count',
                                    label: `Sort by ${ReportUtilities.groupRatioLabel}`,
                                },
                            ]
                    }

                // }

                case 'custom-topics':
                    this.sortGroup = this.sortGroup || 'index';
                    let sortGroupOptions = [
                        {
                            group: 'index',
                            color: 'green',
                            label: `Sort by ${ReportUtilities.indexLabel}`,
                        },
                        {
                            group: 'groupRatio',
                            color: 'blue',
                            label: `Sort by ${ReportUtilities.groupRatioLabel}`,
                        },
                    ];

                    let sortByTweets = false;
                    if (this.params.section === 'social-characteristics') {
                        sortByTweets = ReportUtilities.hasTweets(this.insights.social?.customTopics?.groupDefinitionTopic) > 0;
                    } else {
                        // User-defined topics
                        sortByTweets = ReportUtilities.hasTweets(this.insights.social?.customTopics?.userDefinedTopics.find(topic => Utils.slug(topic.category || topic.name) === this.params.section)) > 0;
                    }

                    if (sortByTweets) {
                        sortGroupOptions.push({
                            group: 'tweetCount',
                            color: 'teal',
                            label: 'Sort by Number of Tweets',
                        })
                    }

                    return sortGroupOptions;

                // switch (this.params.section) {
                //     case 'social-characteristics':
                //         // Generic topic summary
                //         this.sortGroup = this.sortGroup || 'index';
                //
                //         return [
                //             // {
                //             //     group: 'index',
                //             //     color: 'green',
                //             //     label: 'Top 20 by Index',
                //             // },
                //             // {
                //             //     group: 'groupRatio',
                //             //     color: 'blue',
                //             //     label: 'Top 20 by Breadth of Interest',
                //             // },
                //         ];
                //
                //     default: {
                //         // User-defined topics
                //         this.sortGroup = this.sortGroup || 'index';
                //
                //         let sortGroupOptions = [
                //             {
                //                 group: 'index',
                //                 color: 'green',
                //                 label: 'Sort by Index',
                //             },
                //             {
                //                 group: 'groupRatio',
                //                 color: 'blue',
                //                 label: 'Sort by % of People in Persona',
                //             },
                //         ];
                //
                //         const tweetItems = this.hasTweets(this.insights.social.customTopics.userDefinedTopics.find(topic => Utils.slug(topic.category) === this.params.section));
                //         if (tweetItems > 1) { // TODO: fill in logic using API data!
                //             sortGroupOptions.push({
                //                 group: 'tweetCount',
                //                 color: 'teal',
                //                 label: 'Sort by Number of Tweets',
                //             })
                //         }
                //
                //         return sortGroupOptions;
                //     }
                // }
            }

            return [];
        }

        get subnavLinks() {
            const routeName = this.$route.name;
            /***** Determine whether there are custom topics available *****/
            const customTopicsData = this.insights?.social?.customTopics;
            let customTopics = {
                id: 'custom-topics',
                label: 'Added Charts',
                name: routeName,
                params: {tab: 'custom-topics'},
            };
            let customTopicLinks = [];

            // Summary topic
            if (customTopicsData?.hasOwnProperty('groupDefinitionTopic')) {
                customTopicLinks.push({
                    label: 'Persona Social Characteristics',
                    name: routeName,
                    params: {tab: 'custom-topics', section: 'social-characteristics'},
                })
            }

            // User-defined topics
            if (size(customTopicsData?.userDefinedTopics)) {
                const userTopics = Utils.sortByProperty(
                    clone(customTopicsData?.userDefinedTopics),
                    'name',
                    'asc'
                );

                if (!isEmptyArray(userTopics)) {
                    for (const topic of userTopics) {
                        customTopicLinks.push({
                            label: `User named topic "${topic.name}"`, // Still uses topic.name since this is user-defined
                            name: routeName,
                            params: {tab: 'custom-topics', section: Utils.slug(topic.name)}, // Still uses topic.name since this is user-defined
                        });
                    }
                }
            }

            if (size(customTopicLinks)) {
                // If any topics exist, add them
                Object.assign(customTopics, {links: customTopicLinks});
            } else {
                // Otherwise, disable this nav item
                Object.assign(customTopics, {
                    disabled: true,
                    title: 'No Topics were analyzed',
                });
            }
            /***** END custom topic handling *****/

            let conglomerateRfmMarket: any | null = null;
            let pastPurchases: any | null = null;
            if (this.personaHasConglomerateRfmMarketData) {
                conglomerateRfmMarket = {
                    id: 'market-affinity',
                    label: 'Market Affinity',
                    name: routeName,
                    params: {tab: 'market-affinity'},
                    links: [
                        {
                            label: 'All Markets',
                            name: routeName,
                            params: {tab: 'market-affinity', section: 'all'},
                        },
                    ],
                };

                if (this.personaHasConglomerateRfmCategoryData) {
                    conglomerateRfmMarket.links.unshift({
                        label: 'Overview',
                        name: routeName,
                        params: {tab: 'market-affinity', section: 'overview'},
                    });
                }

                if (this.personaConglomerateRfmMarketSelections?.length) {
                    conglomerateRfmMarket.links.push({
                        label: 'Markets in Persona',
                        name: routeName,
                        params: {tab: 'market-affinity', section: 'markets'},
                    });
                }
            } else {
                pastPurchases = {
                    label: 'Past Purchases',
                        name: routeName,
                    params: {tab: 'past-purchases'},
                    links: [
                        {
                            label: 'Clothing and Children',
                            name: routeName,
                            params: {tab: 'past-purchases', section: 'clothing-children'},
                        },
                        {
                            label: 'Interests and Activities',
                            name: routeName,
                            params: {tab: 'past-purchases', section: 'interests-activities'},
                        },
                        {
                            label: 'Finance and Shopping Style',
                            name: routeName,
                            params: {tab: 'past-purchases', section: 'finance-shopping-style'},
                        },
                    ],
                };
            }

            let consumerSpending: any | null = null;
            if (this.account.allowConsumerSpend) {
                let consumerSpendLinks = [
                    {
                        label: 'Spending Summary',
                        name: routeName,
                        params: {tab: 'consumer-spending', section: 'summary'},
                    },
                ];
                if (this.insights?.consumer_spend?.hasOwnProperty('topSpendingIndicators')) {
                    consumerSpendLinks.push({
                        label: 'Spending Uncategorized',
                        name: routeName,
                        params: {tab: 'consumer-spending', section: 'top'},
                    });
                }

                if (!isEmptyArray(this.insights?.consumer_spend?.categories)) {
                    consumerSpendLinks.push({
                        label: 'Spending Categories',
                        name: routeName,
                        params: {tab: 'consumer-spending', section: 'categories'},
                    });
                    // for (const category of this.insights?.consumer_spend?.categories) {
                    //     const displayCategory = category.subCategories?.some(subcategory => subcategory.displayPersona === 1);
                    //     if (displayCategory) {
                    //         consumerSpendLinks.push({
                    //             label: category.name,
                    //             name: routeName,
                    //             params: {tab: 'consumer-spending', section: Utils.slug(category.name)},
                    //         });
                    //     }
                    // }
                }

                consumerSpending = {
                    id: 'consumer-spending',
                    label: 'Consumer Spending',
                    name: routeName,
                    params: {tab: 'consumer-spending'},
                    links: consumerSpendLinks,
                };
            }

            let highlevelRfm = null
            if (this.personaHasHighLevelRfmData) {
                highlevelRfm = {
                    id: 'rfm',
                    label: 'RFM',
                    name: routeName,
                    params: {tab: 'rfm'},
                    links: [
                        {
                            label: 'Credit Card Usage',
                            name: routeName,
                            params: {tab: 'rfm', section: 'credit-card'},
                        },
                        {
                            label: 'Response Rate Drivers',
                            name: routeName,
                            params: {tab: 'rfm', section: 'response-rate'},
                        },
                        {
                            label: 'Revenue Drivers',
                            name: routeName,
                            params: {tab: 'rfm', section: 'revenue'},
                        },
                    ],
                };
            }

            let navLinks = {
                placement: 'page',
                links: [
                    {
                        label: 'Summary',
                        name: routeName,
                        params: {tab: ''},
                        default: true
                    },

                    {
                        label: 'Demographics',
                        name: routeName,
                        params: {tab: 'demographics'},
                        links: [
                            {
                                label: 'Age, Income, & Home',
                                name: routeName,
                                params: {tab: 'demographics', section: 'age-income-home'},
                            },
                            {
                                label: 'Gender, Marriage, & Kids',
                                name: routeName,
                                params: {tab: 'demographics', section: 'gender-marriage-kids'},
                            },
                            {
                                label: 'Politics, Job, & Residence',
                                name: routeName,
                                params: {tab: 'demographics', section: 'politics-job-residence'},
                            },
                            {
                                label: 'Geography',
                                name: routeName,
                                params: {tab: 'geography'},
                            },
                            // {
                            //     label: 'Geography: Media Markets',
                            //     name: routeName,
                            //     params: {tab: 'geographic-areas', section: 'media-markets'},
                            // },
                            // {
                            //     label: 'Geography: States',
                            //     name: routeName,
                            //     params: {tab: 'geographic-areas', section: 'states'},
                            // },
                            {
                                label: 'Geography: Metro Areas',
                                name: routeName,
                                params: {tab: 'geographic-areas', section: 'metro-areas'},
                            },
                            // {
                            //     label: 'Geography: Regions',
                            //     name: routeName,
                            //     params: {tab: 'geographic-areas', section: 'regions'},
                            // },
                        ],
                    },

                    {
                        label: 'Social Activity',
                        name: routeName,
                        params: {tab: 'social-activity'},
                        links: [
                            {
                                label: 'Overview',
                                name: routeName,
                                params: {tab: 'social-activity'},
                            },

                            {
                                label: 'Brands Summary',
                                name: routeName,
                                params: {tab: 'brands', section: 'summary'},
                            },
                            {
                                label: 'Brands Uncategorized',
                                name: routeName,
                                params: {tab: 'brands', section: 'top'},
                            },
                            {
                                label: 'Brand Categories',
                                name: routeName,
                                params: {tab: 'brands', section: 'categories'},
                            },

                            {
                                label: 'Interests Summary',
                                name: routeName,
                                params: {tab: 'interests', section: 'summary'},
                            },
                            {
                                label: 'Interests Uncategorized',
                                name: routeName,
                                params: {tab: 'interests', section: 'top'},
                            },
                            {
                                label: 'Interest Categories',
                                name: routeName,
                                params: {tab: 'interests', section: 'categories'},
                            },
                        ],
                    },

                    // Boolean market data: Only ONE of the following will be active, depending on account settings and available data
                    conglomerateRfmMarket,
                    pastPurchases,
                    // End boolean market data

                    // TODO: future expansion
                    // {
                    //     label: 'Autobahn',
                    //     name: routeName,
                    //     params: {tab: 'UNKNOWN', section: 'UNKNOWN'},
                    // },
                    // {
                    //     label: 'CoCo Scores',
                    //     name: routeName,
                    //     params: {tab: 'UNKNOWN', section: 'UNKNOWN'},
                    // },
                    // TODO: end future expansion

                    highlevelRfm,

                    consumerSpending,

                    customTopics,
                ].filter(link => link !== null),
            };

            return navLinks;
        }

        get pdfSharing() {
            return this.appStore.getLoaderActive(`${ReportUtilities.ReportLoaders.SHARING_REPORT}-pdf`);
        }

        get reportSharing() {
            return this.appStore.getLoaderActive(`${ReportUtilities.ReportLoaders.SHARING_REPORT}-report`);
        }

        blurUpdatePersonaName(event: Event) {
            // Re-focus the persona name input if there are unsaved changes
            if (this.persona.name !== this.editablePersonaName) {
                const target = <HTMLElement>event.target;
                target.focus();
            } else {
                this.allowUpdatePersonaName = false;
            }
        }

        chartAssociatedId(chartId: any) {
            if (typeof chartId === 'object') {
                chartId = chartId.chartId;
            }

            switch (chartId) {
                case 'age_range':
                    return 'age_avg';

                // case 'cc_amt':
                // case 'cc_major_amt':
                // case 'cc_major_ord_num':
                // case 'cc_ord_num':
                // case 'rfm_idx':
                //     return chartId;

                case 'children_age_range':
                    return 'children_age_range_index';

                case 'children_present_in_hh':
                    return 'children_present_in_hh_index';

                case 'gender':
                    return 'gender_index';

                case 'home_value_range':
                    return 'home_value_avg';

                case 'length_of_residence_range':
                    return 'length_of_residence_avg';

                case 'marital_status':
                    return 'marital_status_index';

                case 'occupation_type':
                    return 'occupation_type_highest_count';

                case 'occupation_type_index':
                    return 'occupation_type_highest_index';

                case 'persona_social_graph_profile':
                    return 'typical_social_following_activity';

                case 'twitter_follow_frequency':
                    return 'persona_influence';

                case (chartId.match(ReportUtilities.conglomerateRfmMarketPattern) || {}).input:
                case (chartId.match(ReportUtilities.consumerSpendingPattern) || {}).input:
                case (chartId.match(ReportUtilities.highLevelRfmPattern) || {}).input: {
                    return chartId;
                }

                case (chartId.match(ReportUtilities.pastPurchaseRollupPattern) || {}).input: {
                    return `past_purchases_${chartId}`;
                }

                default:
                    return false
            }
        }

        /**
         * Convenience method to retrieve and cache chart data for display
         */
        chartData(chartId: string, returnData: boolean = true) {
            if (!chartId || !this.persona?.id) {
                console.error(`🔴 Cannot retrieve chart data "${chartId}" (return data: ${returnData})`);
                return false;
            }

            let cacheChartId = `${this.persona.id}-${chartId}`;
            // if (this.params.tab === 'geographic-areas') {
            //     cacheChartId = `${cacheChartId}-${this.sortGroup || 'index'}`;
            // }
            if (['geographic-areas', 'geography'].includes(this.params.tab)) {
                cacheChartId = `${cacheChartId}-${this.sortGroup || 'index'}`;
            }

            if (!this.chartDataCache?.hasOwnProperty(cacheChartId) || !this.chartDataCache[cacheChartId]) {
                // console.debug(`🟡 CHART DATA CACHE MISS: ${cacheChartId}`);
                let chartData: any;
                if (chartId.indexOf(ReportUtilities.NON_CHART_PREFIX) > -1) {
                    // Special handling for elements which will appear in the chart grid, but are NOT handled by Highcharts - e.g. a WedgeChartSection
                    chartData = this.prepareNonChartData(chartId);
                } else {
                    chartData = ReportUtilities.prepareChartData({
                        asyncData: this.asyncData,
                        chartId,
                        fieldDictionary: this.fieldDictionary,
                        filterSettings: this.filterSettings,
                        Highcharts,
                        insights: structuredClone(toRaw(this.insights)),
                        // params: Object.assign({}, this.params, {pageContext: this.pageContext}),
                        params: this.params,
                        returnData,
                        sortGroup: this.sortGroup,
                    });
                    if (!(chartData.cache || true)) {
                        return chartData;
                    }
                }
                this.chartDataCache[cacheChartId] = chartData;
            // } else {
            //     console.debug(`🟢 CHART DATA CACHE HIT: ${cacheChartId}`);
            }

            return this.chartDataCache[cacheChartId];
        }

        chartDetail(chartId: string) {
            const chartData = this.chartData(chartId),
                sortByGroupRatio = Utils.sortByProperty(clone(chartData.rawData), 'groupRatio', 'desc'),
                sortByIndex = Utils.sortByProperty(clone(chartData.rawData), 'index', 'desc');

            if (isObject(chartData) && !isEmptyArray(sortByGroupRatio) && !isEmptyArray(sortByIndex)) {
                switch (chartId) {
                    case 'dma_code': {
                        const getDmaName = dmaCode => {
                            return chartData.rawData.find(feature => parseInt(feature.value) === dmaCode).name;
                        };
                        return [
                            {
                                name: 'Top Media Markets',
                                items: {
                                    index: sortByIndex.slice(0, 25).map((item, i) => {
                                        return {
                                            index: i + 1,
                                            label: getDmaName(+item.value),
                                            value: `${item.index.toFixed(2)} times`,
                                        }
                                    }),
                                    groupRatio: sortByGroupRatio.slice(0, 25).map((item, i) => {
                                        return {
                                            index: i + 1,
                                            label: getDmaName(+item.value),
                                            value: `${item.groupRatio.toFixed(2)}%`,
                                        }
                                    }),
                                },
                            },
                            {
                                name: 'Bottom Media Markets',
                                items: {
                                    index: sortByIndex.reverse().slice(0, 25).map((item, i) => {
                                        return {
                                            index: sortByIndex.length - i,
                                            label: getDmaName(+item.value),
                                            value: `${item.index.toFixed(2)} times`,
                                        }
                                    }),
                                    groupRatio: sortByGroupRatio.reverse().slice(0, 25).map((item, i) => {
                                        return {
                                            index: sortByGroupRatio.length - i,
                                            label: getDmaName(+item.value),
                                            value: `${item.groupRatio.toFixed(2)}%`,
                                        }
                                    }),
                                },
                            },
                        ];
                    }

                    case 'metro_area': {
                        const displayCount = Math.min(25, sortByGroupRatio.length / 2);
                        return [
                            {
                                name: 'Top Metros',
                                items: {
                                    index: sortByIndex.slice(0, Math.floor(displayCount)).map((item, i) => {
                                        return {
                                            index: i + 1,
                                            label: item.value,
                                            value: `${item.index.toFixed(2)} times`,
                                        }
                                    }),
                                    groupRatio: sortByGroupRatio.slice(0, Math.floor(displayCount)).map((item, i) => {
                                        return {
                                            index: i + 1,
                                            label: item.value,
                                            value: `${item.groupRatio.toFixed(2)}%`,
                                        }
                                    }),
                                },
                            },
                            {
                                name: 'Bottom Metros',
                                items: {
                                    index: sortByIndex.reverse().slice(0, Math.ceil(displayCount)).map((item, i) => {
                                        return {
                                            index: sortByIndex.length - i,
                                            label: item.value,
                                            value: `${item.index.toFixed(2)} times`,
                                        }
                                    }),
                                    groupRatio: sortByGroupRatio.reverse().slice(0, Math.ceil(displayCount)).map((item, i) => {
                                        return {
                                            index: sortByGroupRatio.length - i,
                                            label: item.value,
                                            value: `${item.groupRatio.toFixed(2)}%`,
                                        }
                                    }),
                                },
                            },
                        ];
                    }

                    case 'region': {
                        return [
                            {
                                name: 'Top Regions',
                                items: {
                                    index: sortByIndex.slice(0, 25).map((item, i) => {
                                        return {
                                            index: i + 1,
                                            label: item.value,
                                            value: `${item.index.toFixed(2)} times`,
                                        }
                                    }),
                                    groupRatio: sortByGroupRatio.slice(0, 25).map((item, i) => {
                                        return {
                                            index: i + 1,
                                            label: item.value,
                                            value: `${item.groupRatio.toFixed(2)}%`,
                                        }
                                    }),
                                },
                            },

                        ];
                    }

                    case 'state': {
                        const getStateName = stateCode => {
                            return chartData.rawData.find(feature => feature.value === stateCode).name
                        };
                        return [
                            {
                                name: 'Top States',
                                items: {
                                    index: sortByIndex.slice(0, 25).map((item, i) => {
                                        return {
                                            index: i + 1,
                                            label: getStateName(item.value),
                                            value: `${item.index.toFixed(2)} times`,
                                        }
                                    }),
                                    groupRatio: sortByGroupRatio.slice(0, 25).map((item, i) => {
                                        return {
                                            index: i + 1,
                                            label: getStateName(item.value),
                                            value: `${item.groupRatio.toFixed(2)}%`,
                                        }
                                    }),
                                },
                            },
                            {
                                name: 'Bottom States',
                                items: {
                                    index: sortByIndex.reverse().slice(0, 25).map((item, i) => {
                                        return {
                                            index: sortByIndex.length - i,
                                            label: getStateName(item.value),
                                            value: item.index.toFixed(2),
                                        }
                                    }),
                                    groupRatio: sortByGroupRatio.reverse().slice(0, 25).map((item, i) => {
                                        return {
                                            index: sortByGroupRatio.length - i,
                                            label: getStateName(item.value),
                                            value: `${item.groupRatio.toFixed(2)}%`,
                                        }
                                    }),
                                },
                            },
                        ];
                    }
                }
            }
        }

        clearChartDataCache() {
            this.chartDataCache = {};
        }

        // async copyShareLink() {
        //     this.shareLinkCopied = await Utils.copyText(document.querySelector('.modal.show [action="share-link"]').textContent);
        //     window.setTimeout(_ => {
        //         this.shareLinkCopied = false
        //     }, 3000);
        // }

        currentPhoto(persona) {
            if (persona.photos && persona.photos.length > 0) {
                for (let i = 0; i < persona.photos.length; i++) {
                    const photo: PhotoAssignment = persona.photos[i];

                    if (photo.type === 'current') {
                        return photo;
                    }
                }
            }

            return null;
        }

        async handleCustomLayout() {
            if (this.containsSensitiveData) {
                return false;
            }

            this.customLayout = false;
            if (!this.insights.is_data_available) {
                // Data is not ready - why are we even calling this?
                return this.customLayout;
            }

            switch (this.$route.name) {
                // Route-based custom layouts
                case 'personaReportPDF': {
                    let chartData: any = {
                        persona: this.decoratedPersona,
                        account: this.persona.account,
                        insights: this.insights,

                        summary: {
                            brandSummary: {
                                twitter: this.chartData('brief_summary_brands_twitter'),
                            },
                            demographics: this.summaryCharts('demographic', {
                                includeTitle: true,
                            }),
                            conglomerateRfmMarket: this.summaryCharts('conglomerateRfmMarket'),
                            consumerSpendActivity: this.summaryCharts('consumerSpendActivity'),
                            geography: {
                                chart: Highcharts.merge(this.chartData('dma_code'), {
                                    chart: {
                                        width: this.printedChartWidth,
                                        height: '80%',
                                    }
                                }),
                                detail: this.chartDetail('dma_code')?.map(detailItem => {
                                    detailItem.items.index = detailItem.items.index?.slice(0, 15);

                                    return detailItem;
                                }),
                            },
                            highLevelRfm: this.summaryCharts('highLevelRfm'),
                            interestSummary: {
                                twitter: this.chartData('brief_summary_interests_twitter'),
                            },
                            pastPurchaseActivity: this.summaryCharts('pastPurchaseActivity'),
                            politicalPartyAffiliation: Highcharts.merge(this.chartData('political_party_summarized'), {
                                chart: {
                                    width: this.printedChartWidth,
                                },
                            }),
                            politicalSocialSummary: {
                                twitter: this.chartData('political_summary_twitter'),
                            },
                            socialTopics: this.summaryCharts('socialTopics'),
                            socialWeeklyActivity: Highcharts.merge(this.chartData('social_weekly_activity'), {
                                chart: {
                                    width: this.printedChartWidth,
                                    height: '70%',
                                }
                            }),
                            uniqueFacts: this.summaryCharts('uniqueFacts'),
                        },
                    };

                    // Demographics
                    const demographicCharts = [
                        'age_range',
                        'income_range',
                        'net_worth_range',
                        'home_value_range',
                        'gender',
                        'marital_status',
                        'children_age_range',
                        'children_present_in_hh',
                        'household_composition',
                        'political_party',
                        'county_size',
                        'occupation_type',
                        'occupation_type_index',
                        'length_of_residence_range',
                        'dwelling_type'
                    ];
                    let demographics: any = {
                        charts: [],
                        associatedData: [],
                    };
                    for (const chartId of demographicCharts) {
                        demographics.charts.push({
                            chartId,
                            data: chartId ? this.chartData(chartId) : {}
                        })

                        // Associated data
                        const associatedId = this.chartAssociatedId(chartId);
                        if (chartId && associatedId) {
                            const data = ReportUtilities.chartAssociatedData(
                                `${associatedId}`,
                                this.insights,
                                {fieldDictionary: this.fieldDictionary}
                            );
                            if (data) {
                                demographics.associatedData.push({
                                    chartId,
                                    associatedId,
                                    data,
                                });
                            }
                        }
                    }
                    Object.assign(chartData, {demographics});

                    // Brand and Interest Engagement
                    const brandInterestEngagementCharts = [
                        'summary_brands_twitter',
                        'summary_interests_twitter',
                    ];
                    let brandInterestEngagement = {
                        charts: [],
                    };

                    for (const chartId of brandInterestEngagementCharts) {
                        this.sortGroup = 'index';
                        let data = this.chartData(chartId);

                        // Trim to the top 10 items
                        for (const i in data.series) {
                            data.series[i].data = data.series[i].data.slice(0, 10);
                        }
                        data = Highcharts.merge(data, {
                            chart: {
                                height: '110%',
                            }
                        })

                        brandInterestEngagement.charts.push({
                            chartId,
                            data,
                        })
                    }
                    Object.assign(chartData, {brandInterestEngagement});

                    // Custom topics
                    const customTopicsData = this.insights?.social?.customTopics;

                    if (customTopicsData?.hasOwnProperty('groupDefinitionTopic')) {
                        // Always-on topics
                        const chartId = 'social_engagement_twitter';
                        let data = this.chartData(chartId);
                        for (const i in data.series) {
                            data.series[i].data = data.series[i].data.slice(0, 20);
                        }

                        let socialEngagement: any = {
                            charts: [
                                {
                                    chartId,
                                    data,
                                },
                            ]
                        };

                        Object.assign(chartData, {socialEngagement});
                    }

                    if (size(customTopicsData?.userDefinedTopics)) {
                        // User-defined topics
                        const userDefinedTopicCharts = customTopicsData?.userDefinedTopics
                            .map(topic => {
                                return {
                                    chartId: `user_defined_social_engagement_twitter_${Utils.slug(topic.name)}`,
                                    topicName: topic.name,
                                }
                            });

                        let userDefinedTopics = {
                            charts: []
                        };

                        for (const {chartId, topicName} of userDefinedTopicCharts) {
                            this.sortGroup = 'index';
                            let data = this.chartData(chartId);

                            // Trim to the top 10 items
                            for (const i in data.series) {
                                data.series[i].data = data.series[i].data.slice(0, 20);
                            }
                            data = Highcharts.merge(data, {
                                chart: {
                                    height: '110%',
                                }
                            })

                            userDefinedTopics.charts.push({
                                chartId,
                                topicName,
                                data,
                            })
                        }
                        Object.assign(chartData, {userDefinedTopics});
                    }

                    // Consumer Spending
                    this.sortGroup = 'index';
                    Object.assign(chartData, {consumerSpendingSummary: this.chartData('consumer_spending_summary')});

                    // Conglomerate RFM (Market Affinity)
                    if (this.personaHasConglomerateRfmMarketData) {
                        const conglomerateRfmMarketCharts = [
                            'conglomerate_rfm_market_overview_top',
                            'conglomerate_rfm_market_overview_standout',
                        ];
                        let conglomerateRfmMarket: any = {
                            charts: [],
                            selectedMarkets: null,
                        };

                        for (const chartId of conglomerateRfmMarketCharts) {
                            conglomerateRfmMarket.charts.push({
                                chartId,
                                data: this.chartData(chartId),
                            });
                        }

                        // Selected markets
                        const marketChartPatterns = [
                            `conglomerate_rfm_market:{{ context }}:metrics`,
                            `conglomerate_rfm_market:{{ context }}:participation`,
                            `conglomerate_rfm_market:{{ context }}:ord_avg_amt`,
                            `conglomerate_rfm_market:{{ context }}:ord_lat_amt`,
                        ]
                        if (this.personaConglomerateRfmMarketSelections?.length) {
                            conglomerateRfmMarket.selectedMarkets = [];

                            for (const selectedMarket of this.personaConglomerateRfmMarketSelections) {
                                const marketContext = selectedMarket.id;
                                let marketCharts = {
                                    name: `${selectedMarket.category} > ${selectedMarket.subCategory} > ${selectedMarket.name}`,
                                    charts: [],
                                    associatedData: [],
                                };
                                for (const chartPattern of marketChartPatterns) {
                                    const chartId = chartPattern.replace('{{ context }}', marketContext);
                                    marketCharts.charts.push({
                                        chartId,
                                        data: this.chartData(chartId),
                                    });

                                    // Associated data
                                    const associatedId = this.chartAssociatedId(chartId);
                                    if (associatedId) {
                                        marketCharts.associatedData.push({
                                            chartId,
                                            associatedId,
                                            data: ReportUtilities.chartAssociatedData(
                                                `${associatedId}`,
                                                this.insights,
                                                {fieldDictionary: this.fieldDictionary}
                                            ),
                                        });
                                    }
                                }
                                conglomerateRfmMarket.selectedMarkets.push(marketCharts);
                            }
                        }

                        Object.assign(chartData, {conglomerateRfmMarket});
                    }

                    // High-Level RFM
                    const highLevelRfmCharts = [
                        // 'high_level_rfm:rfm_idx:gauge',
                        // 'rfm_credit_all',
                        'high_level_rfm:cc_amt',
                        'high_level_rfm:cc_ord_num',
                        // 'rfm_credit_major',
                        'high_level_rfm:cc_major_amt',
                        'high_level_rfm:cc_major_ord_num',

                        // 'high_level_rfm:recency_idx:gauge',
                        'high_level_rfm:ord_num',
                        'high_level_rfm:mem_buyer_num',
                        'high_level_rfm:lat_incep_months',
                        'high_level_rfm:incep_source_l12_num',
                        'high_level_rfm:recency',

                        'high_level_rfm:ord_avg_amt',
                        'high_level_rfm:ord_lrg_amt',
                        'high_level_rfm:ord_lat_amt',
                        'high_level_rfm:tot_amt',
                        'high_level_rfm:tot_amt_per_mo',
                    ];
                    let highLevelRfm = {
                        charts: [],
                        associatedData: [],
                    };
                    for (const chartId of highLevelRfmCharts) {
                        highLevelRfm.charts.push({
                            chartId,
                            data: this.chartData(chartId),
                        })

                        // Associated data
                        const associatedId = this.chartAssociatedId(chartId);
                        if (associatedId) {
                            highLevelRfm.associatedData.push({
                                chartId,
                                associatedId,
                                data: ReportUtilities.chartAssociatedData(
                                    `${associatedId}`,
                                    this.insights,
                                    {fieldDictionary: this.fieldDictionary}
                                ),
                            });
                        }
                    }
                    Object.assign(chartData, {highLevelRfm});

                    // Past Purchases
                    let pastPurchases = {
                        pages: [
                            {
                                title: 'Clothing and Children',
                                chartIds: ['purch_rollup_womens_fashion', 'purch_rollup_mens_fashion', 'purch_rollup_childrens_products'],
                            },
                            {
                                title: 'Interests and Activities',
                                chartIds: ['purch_rollup_outdoor_sports', 'purch_rollup_homelife', 'purch_rollup_interests_pastimes', 'purch_rollup_soho_home_entertainment'],
                            },
                            {
                                title: 'Interests and Activities',
                                chartIds: ['purch_rollup_travel', 'purch_rollup_hobbies'],
                            },
                            {
                                title: 'Finance and Shopping Style',
                                chartIds: ['purch_rollup_where_they_spend', 'purch_rollup_how_they_spend', 'purch_rollup_financial_purchases'],
                            },
                        ],
                        associatedData: [],
                    };

                    let pastPurchasePageIndex = 0;
                    for (const page of pastPurchases.pages as any) {
                        // page.page = pastPurchasePageIndex;
                        page.charts = [];
                        for (const chartId of page.chartIds) {
                            page.charts.push({
                                chartId,
                                data: this.chartData(chartId),
                            })

                            // Associated data
                            const associatedId = this.chartAssociatedId(chartId);
                            if (associatedId) {
                                pastPurchases.associatedData.push({
                                    chartId,
                                    associatedId,
                                    data: ReportUtilities.chartAssociatedData(
                                        associatedId,
                                        this.insights,
                                        {
                                            params: this.params,
                                            fieldDictionary: this.fieldDictionary,
                                        }
                                    ),
                                });
                            }
                        }

                        ++pastPurchasePageIndex;
                    }
                    Object.assign(chartData, {pastPurchases});

                    // Social activity
                    let socialActivity: any = {
                        engagement: {},
                        weeklyActivity: {
                            chart: Highcharts.merge(chartData.summary.socialWeeklyActivity, {
                                chart: {
                                    height: '66%',
                                }
                            }),
                            detail: [
                                {
                                    name: `Most Active (<span style="color: ${ReportUtilities.reportColors.heatmap.stops[ReportUtilities.reportColors.heatmap.stops.length - 1][1]}">HOTTEST</span>) Times`,
                                    items: this.chartData('social_weekly_activity')?.sidebarItems?.topRanked
                                },
                                {
                                    name: `Least Active (<span style="color: ${ReportUtilities.reportColors.heatmap.stops[0][1]}">COLDEST</span>) Times`,
                                    items: this.chartData('social_weekly_activity')?.sidebarItems?.bottomRanked
                                },
                            ]
                        },
                        // topContent: ['hashtags', 'mentions', 'retweets'].map(contentType => {
                        //     return {
                        //         chart: this.chartData(`social_top_content_${contentType}`),
                        //         detail: {
                        //             contentTypeLabel: Utils.titleCase(contentType === 'retweets' ? 'reposts' : contentType),
                        //             items: this.chartData(`social_top_content_${contentType}`).sidebarItems
                        //         },
                        //     };
                        // })
                    };

                    socialActivity.engagement.charts = [];
                    socialActivity.engagement.associatedData = [];
                    for (const chartId of ['persona_social_graph_profile', 'twitter_follow_frequency']) {
                        socialActivity.engagement.charts.push({
                            chartId,
                            data: this.chartData(chartId),
                        })

                        // Associated data
                        const associatedId = this.chartAssociatedId(chartId);
                        if (associatedId) {
                            socialActivity.engagement.associatedData.push({
                                chartId,
                                associatedId,
                                data: ReportUtilities.chartAssociatedData(associatedId, this.insights),
                            });
                        }
                    }
                    Object.assign(chartData, {socialActivity});

                    // Geographic Areas
                    const geographicCharts = [
                        {
                            chartId: 'region',
                            title: 'Regions'
                        },
                        {
                            chartId: 'state',
                            title: 'States',
                        },
                        {
                            chartId: 'metro_area',
                            title: 'Metro Areas',
                        },
                    ];
                    let geographicAreas = [];
                    for (const {chartId, title} of geographicCharts) {
                        geographicAreas.push({
                            chartId,
                            title,
                            chart: Highcharts.merge(this.chartData(chartId), {
                                chart: {
                                    width: this.printedChartWidth,
                                },
                            }),
                            detail: this.chartDetail(chartId),
                        })

                        // Associated data
                        const associatedId = this.chartAssociatedId(chartId);
                        if (associatedId) {
                            socialActivity.engagement.associatedData.push({
                                chartId,
                                associatedId,
                                data: ReportUtilities.chartAssociatedData(associatedId, this.insights),
                            });
                        }
                    }
                    Object.assign(chartData, {geographicAreas});

                    this.customLayout = {
                        component: markRaw(PersonaSummaryPdf),
                        chartData,
                    };
                }
                    break;
            }

            if (!this.customLayout) {
                switch (this.params.tab) {
                    case undefined: // Summary (default tab)
                    case '':
                        this.sortGroup = 'index';

                        /* Demographics charts */
                        const conglomerateRfmMarket = this.summaryCharts('conglomerateRfmMarket');
                        const consumerSpendActivity = this.summaryCharts('consumerSpendActivity');
                        const demographics = this.summaryCharts('demographic');
                        const geography = {
                            chart: this.chartData('dma_code'),
                            detail: this.chartDetail('dma_code'),
                        };
                        const highLevelRfm = this.summaryCharts('highLevelRfm');
                        const pastPurchaseActivity = this.summaryCharts('pastPurchaseActivity');
                        const socialTopics = this.summaryCharts('socialTopics');
                        const socialWeeklyActivity = this.chartData('social_weekly_activity');
                        const uniqueFacts = this.summaryCharts('uniqueFacts');

                        // ####################################
                        const chartData = {
                            persona: this.decoratedPersona,

                            conglomerateRfmMarket,
                            consumerSpendActivity,
                            demographics,
                            geography,
                            highLevelRfm,
                            pastPurchaseActivity,
                            socialTopics,
                            socialWeeklyActivity,
                            uniqueFacts,

                            brandSummary: {
                                twitter: this.chartData('brief_summary_brands_twitter'),
                            },
                            interestSummary: {
                                twitter: this.chartData('brief_summary_interests_twitter'),
                            },
                            politicalPartyAffiliation: this.chartData('political_party_summarized'),
                            politicalSocialSummary: {
                                twitter: this.chartData('political_summary_twitter'),
                            },
                        };

                        const detail = [];

                        this.customLayout = {
                            component: markRaw(PersonaSummary),
                            chartData,
                            detail,
                        };
                        break;

                    case 'geographic-areas':
                    case 'geography': {
                        // Map charts
                        const chartId = this.chartList[0];
                        const chartData = this.chartData(chartId);
                        const detail = this.chartDetail(chartId);
                        let afterChart = '';

                        // Prepare async chart map data
                        let asyncHandler = this.chartData(chartId, false);

                        // switch (this.params.section) {
                        switch (this.params.section) {
                            case 'metro-areas':
                                afterChart = `
                                    <div class="row">
                                        <div class="col-md-6 offset-md-6">
                                            <table class="table">
                                                <tbody>
                                                    <tr>
                                                        <td><svg version="1.1" class="index-indicator high-index"><circle cx="6" cy="9" r="9" zIndex="3" stroke-width="1"></circle></svg></td>
                                                        <td class="text-nowrap small"><strong>Index above National Baseline</strong> (more likely to live in)</td>
                                                    <tr>
                                                    <tr>
                                                        <td><svg version="1.1" class="index-indicator low-index"><circle cx="6" cy="9" r="5" zIndex="3" stroke-width="1"></circle></svg></td>
                                                        <td class="text-nowrap small"><strong>Index below National Baseline</strong> (less likely to live in)</td>
                                                    <tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                `;
                                break;
                        }

                        this.customLayout = {
                            component: markRaw(GeographicReport),
                            chartData,
                            detail,
                            afterChart: afterChart,
                            sortGroup: this.sortGroup
                        };
                    }
                        break;

                    case 'social-activity': {
                        const chartData = {
                            topSection: [
                                {
                                    chart: this.chartData('persona_social_graph_profile'),
                                    associatedData: ReportUtilities.chartAssociatedData(
                                        this.chartAssociatedId('persona_social_graph_profile'),
                                        this.insights
                                    ),
                                },
                                {
                                    chart: this.chartData('twitter_follow_frequency'),
                                    associatedData: ReportUtilities.chartAssociatedData(
                                        this.chartAssociatedId('twitter_follow_frequency'),
                                        this.insights
                                    ),
                                },
                            ],
                            middleSection: {
                                chart: Highcharts.merge(this.chartData('social_weekly_activity'), {
                                    chart: {
                                        height: '75%',
                                    },
                                    title: {
                                        text: 'Weekly Activity',
                                        margin: 0,
                                    },
                                }, ReportUtilities.defaultChartOptions),
                                detail: [
                                    {
                                        name: `Most Active (<span style="color: ${ReportUtilities.reportColors.heatmap.stops[ReportUtilities.reportColors.heatmap.stops.length - 1][1]}">HOTTEST</span>) Times`,
                                        items: this.chartData('social_weekly_activity')?.sidebarItems?.topRanked
                                    },
                                    {
                                        name: `Least Active (<span style="color: ${ReportUtilities.reportColors.heatmap.stops[0][1]}">COLDEST</span>) Times`,
                                        items: this.chartData('social_weekly_activity')?.sidebarItems?.bottomRanked
                                    },
                                ]
                            },
                            // bottomSection: ['hashtags', 'mentions', 'retweets'].map(contentType => {
                            //     const chart = this.chartData(`social_top_content_${contentType}`);
                            //     return chart ?
                            //         {
                            //             chart,
                            //             detail: {
                            //                 contentTypeLabel: Utils.titleCase(contentType === 'retweets' ? 'reposts' : contentType),
                            //                 items: chart.sidebarItems
                            //             },
                            //         } :
                            //         {
                            //             chart,
                            //             detail: false
                            //         };
                            // })
                        };
                        const detail = [];

                        this.customLayout = {
                            component: markRaw(SocialActivity),
                            chartData,
                            detail,
                        };
                    }
                        break;

                    default:
                        this.customLayout = false;
                }

            }
        }

        // handlePageContext() {
        //     if (this.pageContextMenu) {
        //         // Pull first item from the context menu
        //         this.pageContext = this.$route.query?.context || this.pageContextMenu[0].context;
        //     }
        // }

        hasImage(job) {
            return job.photoUri?.length;
        }

        // hasTweets(topic) {
        //     return topic.twitter?.topByCount?.reduce((total, account) => {
        //         return total + ((account.tweetCount > 0 || false) ? 1 : 0);
        //     }, 0) || false;
        // }

        /**
         * Functions to run on "page load"-whether via mounted() or inline route change
         * @param isInitial
         */
        async initialize(isInitial: boolean = false) {
            this.clearChartDataCache();
            this.params = this.$route.params;
            this.filterSettings.count = 0;
            await this.prepareAsyncData(isInitial);
            await this.handleCustomLayout();
            // this.handlePageContext();

            this.sortGroup = null; // Reset sort group elements
            const sgo = this.sortGroupOptions; // Call once to ensure default settings are ready
        }

        async prepareAsyncData(initial: boolean = false) {
            this.asyncData = [];

            if (initial) {
                await this.retrievePersonaData();
                if (this.containsSensitiveData) {
                    return false;
                }
                await this.retrieveReportData();
            }

            if (!this.insights.is_data_available) {
                return false;
            }

            // Retrieve any asynchronous data necessary for the active report page
            const forLoop = async () => {
                for (let chartId of this.chartList) {
                    if (typeof chartId === 'object') {
                        chartId = chartId.chartId;
                    }

                    let retrieveAsyncData = chartId.indexOf(ReportUtilities.NON_CHART_PREFIX) === -1;
                    if (!retrieveAsyncData) {
                        // Check other reasons to retrieve async data...
                        if (this.params.tab === 'geography') {
                            retrieveAsyncData = true;
                        }
                    }

                    if (retrieveAsyncData) {
                        // this.asyncDataReady = false;
                        const chartInfo = ReportUtilities.prepareChartData({
                            asyncData: this.asyncData,
                            chartId,
                            fieldDictionary: this.fieldDictionary,
                            filterSettings: this.filterSettings,
                            Highcharts,
                            insights: this.insights,
                            params: this.params,
                            returnData: false,
                            sortGroup: this.sortGroup,
                        });
                        if (typeof chartInfo === 'object' && chartInfo.asyncData !== undefined) {
                            const asyncData = await chartInfo.asyncData();

                            // If any data is returned, store it for later use
                            forOwn(asyncData, (data, key) => {
                                this.asyncData[key] = data;
                            });
                        }
                    }
                }
            };
            await forLoop();
        }

        prepareNonChartData(chartId: string): any {
            switch (chartId) {
                case (chartId.match(ReportUtilities.affinitySocialUniqueFactsPattern) || {}).input:
                case (chartId.match(ReportUtilities.topSocialUniqueFactsPattern) || {}).input:
                case (chartId.match(ReportUtilities.userDefinedSocialEngagementUniqueFactsPattern) || {}).input:
                case (chartId.match(ReportUtilities.socialEngagementUniqueFactsPattern) || {}).input: {
                    let userDefined = false,
                        affinity = false,
                        platform,
                        topicName = null;
                    if (chartId.match(ReportUtilities.affinitySocialUniqueFactsPattern)) {
                        [, affinity, platform] = chartId.match(ReportUtilities.affinitySocialUniqueFactsPattern);
                    } else if (chartId.match(ReportUtilities.topSocialUniqueFactsPattern)) {
                        [, platform] = chartId.match(ReportUtilities.topSocialUniqueFactsPattern);
                    } else if (chartId.match(ReportUtilities.socialEngagementUniqueFactsPattern)) {
                        topicName = 'UNDEFINED';
                        [, platform] = chartId.match(ReportUtilities.socialEngagementUniqueFactsPattern);
                    } else if (chartId.match(ReportUtilities.userDefinedSocialEngagementUniqueFactsPattern)) {
                        userDefined = true;
                        [, platform, topicName] = chartId.match(ReportUtilities.userDefinedSocialEngagementUniqueFactsPattern);
                    }

                    const context = this.params.tab;
                    const sortContext = 'topByIndex'; // Hard-coded unless we implement a group count version

                    let rootContext;
                    if (this.insights.social) {
                        const insightsSocialContext = this.insights.social[context];
                        const insightsSocialCustomTopics = this.insights.social.customTopics;

                        if (affinity) {
                            const defaultTopic = insightsSocialContext.defaultTopics[platform].find(topic => Utils.slug(topic.name) === affinity || Utils.slug(topic.category) === affinity);

                            rootContext = insightsSocialContext && insightsSocialContext.defaultTopics
                                ? defaultTopic
                                    ? defaultTopic[sortContext]
                                    : undefined
                                : undefined;
                        } else if (topicName) {
                            const userDefinedTopic = insightsSocialCustomTopics?.userDefinedTopics.find(topic => Utils.slug(topic.name) === topicName);

                            rootContext = userDefined
                                ? userDefinedTopic && userDefinedTopic[platform]
                                    ? userDefinedTopic[platform][sortContext]
                                    : undefined
                                : insightsSocialCustomTopics?.groupDefinitionTopic[platform]
                                    ? insightsSocialCustomTopics?.groupDefinitionTopic[platform][sortContext]
                                        ? insightsSocialCustomTopics?.groupDefinitionTopic[platform][sortContext]
                                        : undefined
                                    : undefined;
                        } else {
                            rootContext = insightsSocialContext && insightsSocialContext[sortContext]
                                ? insightsSocialContext[sortContext][platform]
                                : undefined;
                        }
                    }

                    let items = [];
                    const maxPerRange = 3;
                    let indexRanges = Utils.sortByProperty(
                        clone(this.sentences.indexText)
                            .filter(indexTextItem => indexTextItem.style === '5')
                            .map(indexRange => {
                                indexRange.items = [];
                                return indexRange;
                            }),
                        'min',
                        'desc'
                    );
                    rootContext?.forEach(item => {
                        // Find the matching index range
                        let targetRange = indexRanges.find(indexRange => item.index >= indexRange.min);
                        if (targetRange.items.length >= maxPerRange) {
                            return;
                        }
                        targetRange.items.push(item);
                    });

                    let sequenceKey = 0;
                    const getSocialItemLabel = (account) => {
                        let itemLabel = account.displayName;
                        const itemUrl = ReportUtilities.socialActionUrl(account);
                        if (itemUrl) {
                            itemLabel = `<a href="${itemUrl}" target="_new">${itemLabel}</a>`;
                        }

                        return itemLabel;
                    };

                    for (const indexRange of indexRanges.filter(ir => ir.items.length)) {
                        const value = indexRange.items.map(item => item.index).reduce((a, b) => a + b) / indexRange.items.length;
                        const indexPercent = value * 100,
                            highValue = 1000;
                        const wedgeStop = Utils.formatValue(
                            indexPercent <= 100 ?
                                indexPercent / 2 :
                                Math.min(((indexPercent / highValue) * 50) + 50, 96),
                            'percent',
                            2
                        );

                        let itemNames = indexRange.items
                            .slice(0, indexRange.items.length > 1 ? -1 : 1)
                            .map(account => getSocialItemLabel(account))
                            .join(', ');
                        if (indexRange.items.length > 1) {
                            const comma = indexRange.items.length > 2 ? ',' : ''
                            itemNames = `${itemNames}${comma} and ${getSocialItemLabel(indexRange.items.slice(-1)[0])}`;
                        }

                        let chartData = {
                            description: `These consumers have engaged ${indexRange.text} with ${itemNames}.`,
                            key: `${sequenceKey}`,
                            value,
                            wedgeStop,
                            tooltip: {
                                graph: <string>Utils.formatValue(value, 'indexLikelihood'),
                            }
                        }
                        items.push(chartData);
                        ++sequenceKey;
                    }

                    return {
                        component: markRaw(WedgeChartSection),
                        params: {
                            color: ReportUtilities.reportColors.barIndex.index,
                            items,
                        }
                    }
                }
            }

            return false;
        }

        async retrievePersonaData() {
            // Get a decorated persona for use in characteristics
            this.persona = await this.personaStore.getPersonaById(this.params);
            if (this.containsSensitiveData) {
                return false;
            }

            this.persona.mode = PersonaMode.REPORT;
            this.personaStore.setPersonaEdit(this.persona);

            // Get a decorated persona for use in characteristics
            this.personaStore.clearPersonaState();
            await this.personaStore.setOriginalPersona({
                persona: this.persona,
                shared: this.$route.params.shared,
                refresh: false
            });
            this.personaStore.setAvailableDemographics(PersonaMode.EDIT);
            this.personaStore.setAvailableHighLevelRfm(PersonaMode.EDIT);
        }

        async retrieveReportData() {
            await this.insightStore.clearInsightState();
            this.insightStore.setOriginatorRoot('personas');
            this.insightStore.setOriginatorId(this.persona.id);
            let personaJobId: string | null = null;
            if (this.sharedMode) {
                this.shareToken = await this.accountStore.getAccountShareToken({
                    accountId: this.$route.params.accountId,
                    token: this.$route.params.token
                });
                personaJobId = this.shareToken ? this.shareToken.personaJobId : null;
            } else if (this.$route.query?.hasOwnProperty('jobId')) {
                personaJobId = this.$route.query.jobId;
            }
            if (personaJobId) {
                // Set persona job ID so that later API calls retrieve the correct instance of the data
                await this.jobStore.setJobId(personaJobId);
            }

            this.sentences = this.accountStore.getAccountSentences;

            // Get insights
            let insightsTypes: string[] = [
                'offline_insights_standard',
                'social_insights',
                'unique_insights',
                'consumer_spend_insights',
            ];
            if (this.account.allowConglomerateRFMVariables) {
                insightsTypes.push('conglomerate_rfm_insights');
            }
            if (this.account.allowHighLevelRFMVariables) {
                insightsTypes.push('high_level_rfm_insights');
            }

            let insightsData = await this.insightStore.getInsightsBatch(this.params, insightsTypes);
            for (let i = 0, end = insightsTypes.length; i < end; ++i) {
                const insightsType = insightsTypes[i];
                const insightsKey = insightsType.replace('_insights', '');
                let data: any = insightsData[insightsType];
                switch (insightsKey) {
                    // Apply data transformations
                    case 'conglomerate_rfm':
                        if (!StaticUtils.versionCheck(data?.reportFormatVersion || '0', '1.1.1', StaticUtils.versionMatchAtLeast)) {
                            data = null;
                            console.warn('No Conglomerate RFM data for this Persona');
                        }

                        break;
                }

                if (data !== null) {
                    this.insights[insightsKey] = data;
                }
            }

            // TODO: add more failure checks as necessary
            if (!this.insights?.offline_standard?.demographics?.hasOwnProperty('gender')) {
                console.error('INSIGHTS: OFFLINE STANDARD missing expected data!', this.insights?.offline_standard);
                this.insights.is_data_available = false;
                return;
            }
            if (this.insights?.social?.brands?.hasOwnProperty('topicRollupsSummary')) {
                this.personaHasSocialRollupData = true;
            }
            if (this.insights?.conglomerate_rfm?.hasOwnProperty('categories')) {
                this.personaHasConglomerateRfmCategoryData = true;
            }
            if (this.insights?.conglomerate_rfm?.hasOwnProperty('markets')) {
                this.personaHasConglomerateRfmMarketData = true;
            }
            if (this.insights?.high_level_rfm?.hasOwnProperty('highLevelRfmVariables')) {
                this.personaHasHighLevelRfmData = true;
            }

            // If we got this far, we have data now
            this.insights.is_data_available = true;
        }

        shareLink(context: string) {
            if (this.persona && this.persona.id) {
                let route: any = {
                    name: '',
                    params: {
                        accountId: this.account.id,
                        id: this.persona.id,
                    },
                };

                switch (context) {
                    case 'pdf':
                        if (!this.shareToken?.hasOwnProperty('token')) {
                            return '';
                        }
                        route.name = 'personaReportPDF';
                        route.params.token = this.shareToken?.token;
                        break;

                    case 'report':
                        if (!this.shareToken?.hasOwnProperty('token')) {
                            return '';
                        }
                        route.name = 'personaReportShare';
                        route.params.token = this.shareToken?.token;
                        break;
                }

                return Utils.siteRoot() + this.$router.resolve(route).href.replace(/^\//, '');
            }
        }

        async shareModal(context: string, action: string) {
            this.$eventBus.emit(Events.HIDE_TOOLTIPS);
            this.shareContext = context;
            this.appStore.setLoader(`${ReportUtilities.ReportLoaders.SHARING_REPORT}-${this.shareContext}`);

            switch (action) {
                case 'hide':
                    this.$refs['share'].shareModal.hide();
                    break;
                case 'show':
                    if (!this.shareToken.personaJobId || this.job.id !== this.shareToken.personaJobId) {
                        this.shareToken = await this.personaStore.generatePersonaShareToken(this.job);
                    }

                    await nextTick(_ => {
                        this.$refs['share'].shareModal.show();
                        this.appStore.clearLoader(`${ReportUtilities.ReportLoaders.SHARING_REPORT}-${this.shareContext}`);
                    })
                    break;
            }
        }

        async showSummaryInfo() {
            this.summaryInfoModal?.show();
        }

        summaryCharts(context: string, options: any = {}) {
            const chartOptions = Object.assign({
                includeTitle: false,
            }, options);

            switch (context) {
                case 'conglomerateRfmMarket':
                    return this.personaHasConglomerateRfmMarketData ?
                        this.chartData('conglomerate_rfm_market_summary:top') :
                        false;

                case 'consumerSpendActivity':
                    let consumerSpendActivity: any[] = [];

                    if (this.account.allowConsumerSpend) {
                        // Step 1: Get the top three subcategories by index
                        let consumerSpendSubcategories: any[] = [];

                        if (this.insights && this.insights.hasOwnProperty('consumer_spend') && !isEmptyArray(this.insights.consumer_spend.categories)) {
                            for (const category of this.insights.consumer_spend.categories) {
                                for (const subcategory of category.subCategories) {
                                    if (subcategory.displayPersona) {
                                        consumerSpendSubcategories.push(clone(subcategory));
                                    }
                                }
                            }
                        }

                        consumerSpendSubcategories = Utils.sortByProperty(consumerSpendSubcategories, 'index', 'desc')
                            .slice(0, 3);

                        // Step 2: Prepare chart data
                        // let spendIndex = 0;
                        for (const subcategory of consumerSpendSubcategories) {
                            const chartSourceData = Utils.sortByProperty(subcategory.spendingIndicators, 'index', 'desc')
                                .slice(0, 5);

                            const series = [{
                                colors: ReportUtilities.reportColors.donut.standard,
                                colorByPoint: true,
                                name: ReportUtilities.indexLabel,
                                showInLegend: false,
                                data: chartSourceData.map(item => item.index),
                            }];

                            consumerSpendActivity.push(Highcharts.merge(ReportUtilities.defaultChartOptions, {
                                chart: {
                                    height: '37%',
                                    type: 'bar',
                                },
                                title: {
                                    text: subcategory.name,
                                },
                                xAxis: {
                                    categories: chartSourceData.map(item => item.shortDescription),
                                },
                                yAxis: ReportUtilities.chartAxisOptions.sIndex,
                                series,
                                tooltip: {
                                    formatter: ReportUtilities.tooltipFormatter('flex', true),
                                },
                            }));

                            // ++spendIndex;
                        }
                    }

                    return consumerSpendActivity

                case 'demographic':
                    let demographics = [];
                    const demographicsSummaryCharts = [
                        'gender_summary',
                        'age_range_summary',
                        'marital_status_summary',
                        'children_present_in_hh_summary',

                        'income_range_summary',
                        'net_worth_range_summary',
                        'occupation_type_summary',
                        'county_size_summary', // Urbanicity,

                        'length_of_residence_range_summary',
                        'dwelling_type_summary',
                        'owner_renter_summary', // Home Owner/renter,
                        'region_summary', // Region
                    ];
                    for (const chartName of demographicsSummaryCharts) {
                        const chartData = this.chartData(chartName);

                        //console.debug(`🟢 CHART DATA :`, chartData);


                        if (chartData) {
                            let chart = Highcharts.merge(this.chartData(chartName), {
                                chart: {
                                    type: 'pie',
                                    height: '70%',
                                    events: {
                                        load: chart => {
                                            ReportUtilities.chartOverlayCallback(chart.target, 'icon');
                                        },
                                        redraw: chart => {
                                            ReportUtilities.chartOverlayCallback(chart.target, 'icon');
                                        },
                                    },
                                    marginLeft: 0,
                                    marginTop: 5,
                                    marginBottom: 5,
                                    // marginRight: 0,
                                },
                                title: chartOptions.includeTitle ?
                                    {text: chartData.series[0].name, align: 'left'} :
                                    false,
                                plotOptions: ReportUtilities.chartPlotOptions.donut,
                                legend: {
                                    align: 'right',
                                    layout: 'vertical',
                                    margin: 0,
                                    padding: 0,
                                    verticalAlign: 'middle',
                                    width: '50%',
                                    itemStyle: {
                                        fontSize: '.85em',
                                        fontWeight: 'normal',
                                        textOverflow: 'ellipsis',
                                    },
                                    labelFormatter: function () {
                                        // Make the label bold if it's the highest relative index in the series
                                        // Step 1: Get highest relativeIndex from linkedSeries
                                        const maxRelativeIndex = this.series.linkedSeries[0].data.reduce(
                                            (max, item) => (item.y > max ? item.y : max),
                                            this.series.linkedSeries[0].data[0].y
                                        );
                                        // Step 2: Bold the label if the current position matches the highest indexing position
                                        if (this.series.linkedSeries[0].data[this.index].y === maxRelativeIndex) {
                                            return `<strong>${this.name}</strong>`;
                                        }
                                        return this.name;
                                    }
                                },
                                tooltip: {
                                    formatter: ReportUtilities.tooltipFormatter('demographicSummary'),
                                    outside: true,
                                    useHTML: true,
                                },
                            });
                            demographics.push(chart);
                        }
                    }

                    return demographics;

                case 'highLevelRfm':
                    return this.personaHasHighLevelRfmData ?
                        this.chartData('high_level_rfm:ord_avg_amt:radial') :
                        false;

                case 'pastPurchaseActivity':
                    const pastPurchaseDictionaryKeys: string[] = [];
                    for (const [key, dictionaryItem] of Object.entries(this.fieldDictionary.standard)) {
                        if (dictionaryItem.displayPersona
                            && dictionaryItem.category === 'Purchase'
                            && dictionaryItem.children !== null) {
                            pastPurchaseDictionaryKeys.push(key);
                        }
                    }
                    // Order by index and filter to top three
                    let pastPurchaseData = [];
                    for (const key of pastPurchaseDictionaryKeys) {
                        if (this.insights?.offline_standard?.demographics?.hasOwnProperty(key)) {
                            pastPurchaseData.push(Highcharts.merge(clone(this.insights.offline_standard?.demographics[key][0]), {key}));
                        }
                    }
                    const pastPurchases = Utils.sortByProperty(pastPurchaseData, 'index', 'desc')
                        .slice(0, 3);

                    // Prepare chart data
                    let pastPurchaseActivity = [];
                    // let purchaseIndex = 0;
                    for (const purchaseCategory of pastPurchases) {
                        let children = [];
                        // const isPrimary = purchaseIndex === 0;
                        const parentDictionary = this.fieldDictionary.standard[purchaseCategory.key]
                        for (const childKey of parentDictionary.children) {
                            if (!this.insights.offline_standard?.demographics?.hasOwnProperty(childKey) || !this.fieldDictionary?.standard?.hasOwnProperty(childKey)) {
                                continue;
                            }
                            const childData = clone(this.insights?.offline_standard?.demographics[childKey][0]) || false;
                            if (childData) {
                                children.push(Highcharts.merge(childData, {dictionary: this.fieldDictionary.standard[childKey]}));
                            }
                        }
                        const chartSourceData = Utils.sortByProperty(children, 'index', 'desc')
                            .slice(0, 5)
                            .map(childData => {
                                childData.shortDescription = childData.dictionary.shortDescription;

                                return childData;
                            });
                        const series = [{
                            colors: ReportUtilities.reportColors.donut.standard,
                            colorByPoint: true,
                            name: ReportUtilities.indexLabel,
                            showInLegend: false,
                            data: chartSourceData.map(item => item.index),
                        }];

                        pastPurchaseActivity.push(Highcharts.merge(ReportUtilities.defaultChartOptions, {
                            chart: {
                                height: '37%',
                                type: 'bar',
                            },
                            title: {
                                text: parentDictionary.shortDescription,
                            },
                            xAxis: {
                                categories: chartSourceData.map(item => item.shortDescription),
                            },
                            yAxis: ReportUtilities.chartAxisOptions.sIndex,
                            series,
                            tooltip: {
                                formatter: ReportUtilities.tooltipFormatter('flex', true),
                            },
                        }));
                    }

                    return pastPurchaseActivity;

                case 'socialTopics':
                    const maxSocialTopics = 4;
                    let socialTopics = [];
                    // User defined topics
                    if (this.insights?.social?.customTopics?.userDefinedTopics.length) {
                        const userDefinedTopics = Utils.sortByProperty(
                            clone(this.insights.social?.customTopics?.userDefinedTopics),
                            'name',
                            'asc'
                        ).slice(0, 4);
                        for (let topic of userDefinedTopics) {
                            // Ensure that each topic has actual data in it - either in instagram or twitter
                            let itemCount = 0;
                            for (let context of ['twitter']) {
                                itemCount += topic[context]?.topByIndex.filter(item => item.groupCount > 0).length || 0;
                            }
                            if (itemCount) {
                                topic.tab = 'custom-topics';
                                topic.section = Utils.slug(topic.name); // Still uses topic.name since this is user-defined
                                socialTopics.push(topic);
                            }
                        }
                    }
                    // Default topics - hardcoded to enforce selection order
                    if (socialTopics.length < maxSocialTopics) {
                        let defaultTopicQueue: any[] = [
                            {tab: 'interests', topicCategory: 'TV Shows'},
                            {tab: 'brands', topicCategory: 'Nonprofit'},
                            {tab: 'brands', topicCategory: 'Restaurants'},
                            {tab: 'brands', topicCategory: 'Auto'},
                        ]
                            .filter(queueTopic => !socialTopics.some(socialTopic => socialTopic.name === queueTopic.topicCategory));

                        let qIndex = 0;
                        for (let index = socialTopics.length; index < maxSocialTopics; ++index) {
                            const {tab, topicCategory} = defaultTopicQueue[qIndex];

                            if (this.insights && this.insights.social && this.insights.social?.hasOwnProperty(tab)) {
                                for (const [platform, topics] of Object.entries(this.insights?.social[tab].defaultTopics)) {
                                    const topic = topics.find(topic => topic.category === topicCategory);
                                    let currentSocialTopic = socialTopics[index] || {};
                                    if (topic) {
                                        const topicSlug = Utils.slug(topic.category);
                                        currentSocialTopic.tab = tab;
                                        currentSocialTopic.section = topicSlug;
                                        currentSocialTopic.key = topic.key || topicSlug;
                                        currentSocialTopic.category = topic.category;
                                        currentSocialTopic[platform] = topic;
                                        socialTopics[index] = currentSocialTopic;
                                    }
                                }
                                ++qIndex;
                            }
                        }
                    }

                    return socialTopics;

                case 'uniqueFacts':
                    let uniqueFacts = [];
                    /*
                    UF #1: highest penetration ratio for a Brand sub category, chosen from the 20 highest by penetration counts (or whatever is selected for brand summary).
                    UF #2: highest penetration ratio for an interest sub category,  chosen from the 20 highest by penetration counts (or whatever is selected for interest summary).
                    UF #3: highest indexing demo not used in persona characteristics
                    UF #4: highest indexing Consumer Spend Intensity variable - the "Highest of the H values" in the Unique Facts json.
                        consumerSpendH
                    UF #5: highest indexing past "purchase" variable (not parent or rollup - only children) - `demographics.top[1]
                    */
                    const uniqueFactSource = [
                        {
                            sourceType: 'brandTopics',
                            index: 0,
                            property: 'zScore',
                        },
                        {
                            sourceType: 'interestTopics',
                            index: 0,
                            property: 'zScore',
                        },
                        {
                            sourceType: 'demographics',
                            index: 0,
                            property: 'index',
                        },
                        {
                            sourceType: 'consumerSpendH',
                            index: 0,
                            property: 'index',
                        },
                        {
                            sourceType: 'purchase',
                            index: 0,
                            property: 'index',
                        },
                    ]
                        .filter(factSource => {
                            // Only show consumersSpenduUniquefFacts if it's allowed at the account level
                            return this.account.allowConsumerSpend ?
                                true :
                                factSource.sourceType.indexOf('consumerSpend') === -1;
                        });

                    let i = 0;

                    for (const sourceParameters of uniqueFactSource) {
                        const {sourceType, index, property} = sourceParameters;

                        if (this.insights && this.insights.unique && this.insights.unique[sourceType]) {
                            const factData: any = this.insights.unique[sourceType],
                                factSource: any = factData.top[index];
                            const value: number = factSource[property],
                                key = Utils.slug(`${factSource.item?.fieldName || i}-${factSource.value?.value || i}`);
                            const sentence = this.sentences.sentences.find(sentence => sentence.style === factSource.sentence?.style);

                            let description: string;
                            if (sentence && sentence.hasOwnProperty('sentence')) {
                                const tokenPattern = /{{([^}]+)}}/g;
                                description = `${sentence.sentence}`; // Ensure this is not a pointer by creating a new template literal
                                Array.from(description.matchAll(tokenPattern))
                                    .forEach(match => {
                                        const [token, property] = match;
                                        let replacement: string;
                                        switch (property) {
                                            case 'likelyText':
                                                const indexText: any = Utils.sortByProperty(clone(this.sentences.indexText), 'min', 'desc')
                                                    .find(indexText => {
                                                        return indexText.style === factSource.sentence?.indexStyle && factSource.index >= indexText.min;
                                                    });
                                                replacement = indexText.text;
                                                break;

                                            default:
                                                replacement = Utils.descendentProp(factSource, property);
                                        }
                                        description = description.replace(token, replacement);
                                    });
                            } else {
                                description = `NO SENTENCE FOUND FOR STYLE "${factSource.sentence?.style}" - DATA TYPE: ${factSource.dataType}/${factSource.dataType === 'topic' ? factSource.item.topicName : 'UNKNOWN'}`
                            }
                            description = Utils.sentenceCase(description);

                            let graphTooltip: string,
                                includeFact: boolean = true,
                                infoTooltip: string,
                                wedgeStop: string;
                            switch (property) {
                                // case 'groupRatio':
                                //     wedgeStop = `${Math.round(value)}%`;
                                //     const verb = sourceType === 'brandTopics' ? 'engaged' : 'interested';
                                //     graphTooltip = `${wedgeStop} of this group is ${verb}`;
                                //     let infoArray: string[] = factSource.topByCount || [];
                                //
                                //     if (Utils.isEmptyArray(infoArray)) {
                                //         /*
                                //         TODO: Remove after we verify that all records properly decorate the unique fact topByCount data
                                //         - Using the `id` of the top brand or interest from `unique_insights`, retrieve the corresponding topic object from `social_insights`.[brands|interests].topicSummary.twitter.
                                //         - for the tooltip, using the `item.id` from the brand/interest in `unique_insights`, find the corresponding topic details
                                //           in social_insights ([brands|interests].topicsSummary.twitter) and use the topByCount array to retrieve a list of
                                //           `displayName` as the text to build the hover text
                                //         */
                                //         const socialContext = sourceType.replace('Topics', 's');
                                //         const tooltipDataSource = this.insights.social[socialContext].topicsSummary.twitter
                                //             .find(topic => topic.id === factSource.item.id);
                                //         if (tooltipDataSource) {
                                //             infoArray = tooltipDataSource.topByCount.map(item => item.displayName);
                                //         } else {
                                //             infoArray = [`[ERROR] No topic data found for source type "${sourceType}" ${socialContext}`];
                                //         }
                                //     }
                                //
                                //     break;

                                case 'index':
                                default:
                                    const descriptionSource = sourceType === 'demographics' ? 'value' : 'item';
                                    const indexPercent = value * 100,
                                        highValue = 1000;
                                    wedgeStop = <string>Utils.formatValue(
                                        indexPercent <= 100 ?
                                            indexPercent / 2 :
                                            Math.min(((indexPercent / highValue) * 50) + 50, 96),
                                        'percent',
                                        2
                                    );
                                    graphTooltip = <string>Utils.formatValue(value, 'indexLikelihood');
                                    infoTooltip = factSource[descriptionSource].longDescription;
                                    break;

                                case 'zScore': {
                                    const standardDeviation = factSource['stdDev'];
                                    if (!standardDeviation) {
                                        includeFact = false;
                                        break;
                                    }

                                    const deviatedValue = (3 * standardDeviation) + (value * standardDeviation);
                                    const maxDeviation = (6 * standardDeviation);
                                    const percentage = Math.round((deviatedValue / maxDeviation) * 100);

                                    wedgeStop = `${Math.max(0, Math.min(100, percentage))}%`;
                                    graphTooltip = '';
                                    let infoArray: string[] = factSource.topByCount || [];

                                    if (Utils.isEmptyArray(infoArray)) {
                                        const socialContext = sourceType.replace('Topics', 's');
                                        const tooltipDataSource = this.insights.social[socialContext].topicsSummary.twitter
                                            .find(topic => topic.id === factSource.item.id);
                                        if (tooltipDataSource) {
                                            infoArray = tooltipDataSource.topByCount.map(item => item.displayName);
                                        } else {
                                            infoArray = [`[ERROR] No topic data found for source type "${sourceType}" ${socialContext}`];
                                        }
                                    }

                                    infoTooltip = ReportUtilities.dataSourceItemToolTipFormatter(infoArray);
                                }
                                    break;
                            }

                            if (includeFact) {
                                uniqueFacts.push({
                                    description,
                                    key,
                                    value,
                                    wedgeStop,
                                    // colors: ReportUtilities.reportColors.wedge,
                                    tooltip: {
                                        graph: graphTooltip,
                                        info: infoTooltip,
                                    }
                                });
                            }
                            ++i;
                        }
                    }

                    return uniqueFacts;

                default:
                    return false;
            }
        }

        async updatePersonaName() {
            if (this.editablePersonaName !== this.persona.name) {
                this.appStore.setLoader(this.personaNameLoader);
                if (this.editablePersonaName.toLowerCase() === this.persona.name.toLowerCase()) {
                    // Skip validation - only the case has changed
                    this.personaNameValidation = Object.assign({}, this.personaNameValidationDefault, {valid: true});
                } else {
                    // Validate the name
                    this.personaNameValidation = await validators.isValidActivityName(this.editablePersonaName, ActivityType.persona);
                }

                if (this.personaNameValidation.valid) {
                    await this.jobStore.setJobName(this.editablePersonaName);
                    await this.personaStore.savePersonaName(this.editablePersonaName);
                    this.persona.name = this.editablePersonaName;
                } else {
                    return false;
                }
                this.appStore.clearLoader(this.personaNameLoader);
            } else {
                // Same name - clear any existing errors
                this.personaNameValidation = Object.assign({}, this.personaNameValidationDefault, {valid: true});
            }

            document.querySelector<HTMLInputElement>('input[data-action="update-build-name"]').blur();
            this.allowUpdatePersonaName = false;
        }

        updatePersonaNameCancel() {
            this.personaNameValidation.error.message = '';
            this.editablePersonaName = this.persona.name;
            this.allowUpdatePersonaName = false;
            document.querySelector<HTMLInputElement>('input[data-action="update-build-name"]').blur();
        }

        async updateUserPhoto(userPhoto: UserPhoto) {
            this.persona = Object.assign({}, clone(this.persona), {userPhoto});
        }

        @Watch('$route.params', {immediate: true})
        async onRouteParamsChanged() {
            this.reportReady = false;
            await this.initialize(false);
            this.reportReady = true;
        }

        @Watch('filterSettings', {deep: true})
        @Watch('sortGroup')
        async onFilterSettingsChanged() {
            // Handle special cases where charts need to update without re-routing
            if (this.params.tab === 'geography') {
                await this.handleCustomLayout();
            } else {
                this.clearChartDataCache();
            }
        }

        @Watch('pageContext')
        async onPageContextChanged() {
            if (this.chartList.length) {
                this.customLayout = false;
                await this.prepareAsyncData(false);
                await this.handleCustomLayout();
            }
            // nextTick(() => {
            //     setTimeout(() => {this.$eventBus.emit(Events.HIDE_TOOLTIPS)}, 250);
            // });
        }

        @Watch('persona.name')
        onPersonaNameChanged() {
            useHead({
                title: `Persona: ${this.persona ? this.persona.name : 'Loading...'}`,
            })
            this.editablePersonaName = this.persona.name;
        }
    }

    export default toNative(PersonaReport);
</script>

<style lang="scss" src="../../common/report/report.scss"/>
