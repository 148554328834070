<template>
    <article class="container-fluid p-3" data-content-type="COMPARISON" id="comparisonReport">
        <section class="bg-white">
            <div v-if="comparisonReportLoading">
                <spinner text="Building..."/>
            </div>
            <div v-else>
                <div v-if="ready" class="bg-comparison-report">
                    <div class="report-section bg-white">
                        <comparison-header
                            v-if="!printMode"
                            :comparison="comparison"
                            :insights="insights"
                            :mode="ComparisonMode.REPORT"
                            :share-token="shareToken"
                            @update-share-token="(token: any) => shareToken = token"
                        />

                        <!-- Subnav moved per SDSPOT-73 -->
                        <subnav
                            v-if="!printMode"
                            class="border-top"
                            :nav-links="subnavLinks"
                            placement="page"
                            :show-disabled-links="false"
                        />

                        <section v-if="reportReady">
                            <h1 v-if="!printMode"
                                class="section-title h3 text-center my-3">
                                {{ sectionTitle }}

                                <div v-if="pageContextMenu"
                                     v-memo="[pageContextMenu, pageContext]"
                                     class="d-inline-block"
                                     data-bs-toggle="dropdown"
                                >
                                    <div class="dropdown dropdown-bubble">
                                        <a aria-expanded="false"
                                           aria-haspopup="true"
                                           class="d-block hover-orange-75 show-orange"
                                           data-bs-toggle="dropdown"
                                           href
                                           id="pageContextMenu"
                                           role="button"
                                        >
                                            {{ pageContextLabel }}
                                            <font-awesome-icon
                                                class="ms-2 align-middle"
                                                icon="angle-down"
                                                fixed-width
                                            />
                                        </a>
                                        <div
                                            aria-labelledby="pageContextMenu"
                                            class="dropdown-menu dropdown-menu-end"
                                        >
                                            <a v-for="(option, index) in pageContextMenu" :key="index"
                                               class="dropdown-item"
                                               :class="{'router-link-active active disabled': option.context === pageContext}"
                                               href
                                               @click.prevent="pageContext = option.context"
                                            >
                                                {{ option.label }}
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <on-page-help :context="pageContext"/>
                            </h1>

                            <div class="d-flex flex-column-reverse">
                                <!-- Charts -->
                                <div v-if="hasCustomLayout">
                                    <!-- Non-standard custom layout component, e.g. for geographic data -->
                                    <component
                                        v-bind:is="customLayout.component"
                                        :chart-data="customLayout.chartData"
                                        :detail="customLayout.detail || {}"
                                        :key="customComponentKey"
                                        :params="customLayout.params || {}"
                                        ref="customComponent"
                                        :report-ready="reportReady"
                                    >
                                        <template #afterChart v-if="customLayout.afterChart">
                                            <div v-html="customLayout.afterChart"></div>
                                        </template>
                                    </component>
                                </div>

                                <div v-else>
                                    <chart-grid
                                        :chart-wrapper-class="{'offset-lg-3': layoutColumns === 1, 'col-lg-6': layoutColumns > 0, 'col': layoutColumns < 0}"
                                        :charts="chartListData"
                                        :charts-associated-data="chartListAssociatedData"
                                        :key="sectionKey"
                                    />
                                </div>

                                <!-- TODO: factor out into a component if needed -->
                                <section v-if="filterGroupOptions.length">
                                    <div class="filter-options text-center col-10 col-lg-8 offset-1 offset-lg-2 mt-3">
                                        <div class="btn-group select-group d-flex" role="group">
                                            <template v-for="(button, index) of filterGroupOptions" :key="index">
                                                <button
                                                    v-if="button.hasOwnProperty('group')"
                                                    class="btn media-body w-100"
                                                    :class="[`select-${button.color}`, {active: filterGroup === button.group}]"
                                                    @click="filterGroup = button.group"
                                                >
                                                    {{ button.label }}
                                                </button>

                                                <!-- Dropdown filters -->
                                                <div v-if="button.hasOwnProperty('items')"
                                                     class="btn-group dropdown-bubble"
                                                     role="group"
                                                >
                                                    <button
                                                        aria-haspopup="true" aria-expanded="false"
                                                        class="btn dropdown-toggle media-body"
                                                        :class="`select-${button.color}`"
                                                        data-bs-toggle="dropdown"
                                                        :id="button.id"
                                                    >
                                                        {{ button.label }}
                                                    </button>

                                                    <div class="dropdown-menu" :aria-labelledby="button.id">
                                                        <a v-for="(item, index) of button.items" :key="index"
                                                           class="dropdown-item" href
                                                           @click.prevent="item.callback"
                                                        >{{ item.label }}</a>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </section>

                                <section v-if="sortGroupOptions.length">
                                    <div class="sort-options text-center col-10 col-lg-8 offset-1 offset-lg-2 mt-3">
                                        <div class="btn-group select-group d-flex" role="group">
                                            <template v-for="(button, index) of sortGroupOptions" :key="index">
                                                <button
                                                    v-if="button.hasOwnProperty('group')"
                                                    class="btn media-body w-100"
                                                    :class="[`select-${button.color}`, {active: sortGroup === button.group}]"
                                                    @click="sortGroup = button.group"
                                                >
                                                    {{ button.label }}
                                                </button>

                                                <!-- Dropdown filters -->
                                                <div v-if="button.hasOwnProperty('items')"
                                                     class="btn-group dropdown-bubble"
                                                     role="group"
                                                >
                                                    <button
                                                        aria-haspopup="true" aria-expanded="false"
                                                        class="btn dropdown-toggle media-body"
                                                        :class="`select-${button.color}`"
                                                        data-bs-toggle="dropdown"
                                                        :id="button.id"
                                                    >
                                                        {{ button.label }}
                                                    </button>

                                                    <div class="dropdown-menu" :aria-labelledby="button.id">
                                                        <a v-for="(item, index) of button.items" :key="index"
                                                           class="dropdown-item" href
                                                           @click.prevent="item.callback"
                                                        >{{ item.label }}</a>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </section>
                        <section v-else>
                            <spinner text="Building..."/>
                        </section>
                    </div>

                    <div v-if="!printMode"
                         class="p-4 bg-white"
                    >
                        <div class="row">
                            <div class="col-lg-2 offset-lg-5">
                                <img alt="Powered by GRAPHMASSIVE®"
                                     class="img-fluid"
                                     src="/assets/images/powered-by-graphmassive-gray-50.svg"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else-if="containsSensitiveData">
                    <div class="alert alert-warning">
                        This report was created with sensitive data that we have since purged from our system. If you want to view the report, you'll need to rebuild it.
                    </div>
                </div>
            </div>
        </section>
    </article>
</template>

<script lang="ts">
    import {markRaw, nextTick, defineAsyncComponent} from 'vue';
    import {useRoute} from 'vue-router';
    import {Vue, Component, Watch, toNative} from 'vue-facing-decorator';
    import {useHead} from '@unhead/vue';
    import clone from 'lodash-es/clone';
    import forOwn from 'lodash-es/forOwn';
    import pick from 'lodash-es/pick';
    import size from 'lodash-es/size';
    import union from 'lodash-es/union';
    import Highcharts from 'highcharts';
    // import HighchartsBoost from 'highcharts/modules/boost';
    import FontAwesomeIcon from 'Components/common/font-awesome-icon.vue';
    const Spinner = () => import(/* webpackChunkName: "Spinner" */ 'Components/common/spinner/spinner.vue');
    const Subnav = () => import(/* webpackChunkName: "Subnav" */ 'Components/app/navigation/subnav.vue');
    import Characteristics from 'Components/comparison/characteristics/characteristics.vue';
    import ComparisonHeader from 'Components/comparison/report/comparison-header.vue';
    import ComparisonSummary from 'Components/comparison/report/custom-layout/comparison-summary.vue';
    import ComparisonSummaryPdf from 'Components/comparison/report/custom-layout/comparison-summary-pdf.vue';
    import GeographicComparisonReport from 'Components/comparison/report/custom-layout/geographic-comparison-report.vue';
    // import RfmComparison from 'Components/comparison/report/custom-layout/rfm-comparison.vue';
    import SocialActivityComparison from 'Components/comparison/report/custom-layout/social-activity-comparison.vue';
    import ChartGrid from 'Components/persona/report/chart-grid.vue';
    import {useComparisonStore, comparisonActivityCount, ComparisonMode} from 'Stores/comparison';
    import {Persona, usePersonaStore} from 'Stores/persona';
    import {NavLink} from 'Stores/common/navigation/navigation';
    import * as ReportUtilities from 'Utilities/reports';
    import * as Utils from 'Utilities/utils';
    import {chartAxisOptions, reportColors, segmentSeparator, tooltipFormatter} from 'Utilities/reports';
    import {useAccountStore} from 'Stores/account';
    import {useJobStore} from 'Stores/job';
    import {useInsightStore} from 'Stores/insight';
    import {useAppStore} from 'Stores/common/app';
    import {isArray} from 'Utilities/inspect';
    import * as StaticUtils from 'Utilities/utils-static';

    // HighchartsBoost(Highcharts);

    @Component<Report>({
        setup() {
            const route = useRoute();
            const appStore = useAppStore();
            const accountStore = useAccountStore();
            const personaStore = usePersonaStore();
            const insightStore = useInsightStore();
            const jobStore = useJobStore();
            const comparisonStore = useComparisonStore();
            const comparison = comparisonStore.getComparison;

            useHead({
                bodyAttrs: {
                    class: route.name === 'comparisonReportPDF' ?
                        ['bg-white', 'letter', 'm-0'] :
                        ['bg-comparison-report'],
                },
                title: `Comparison: ${comparison ? comparison.name : 'Loading...'}`
            });

            return {appStore, accountStore, personaStore, jobStore, insightStore, comparisonStore};
        },
        components: {
            Characteristics,
            ChartGrid,
            ComparisonHeader,
            ComparisonSummary,
            ComparisonSummaryPdf,
            FontAwesomeIcon,
            GeographicComparisonReport,
            OnPageHelp: defineAsyncComponent(() =>
                import('Components/common/on-page-help.vue')
            ),
            Share: defineAsyncComponent(() =>
                import('Components/common/report/share.vue')
            ),
            Spinner,
            Subnav,
        }
    })
    class ComparisonReport extends Vue {
        asyncData: any = [];
        chartDataCache: {};
        customLayout: any = false;
        // fieldDictionary: any = {};
        filterGroup: string | null = '';
        filterSettings = {
            count: 0
        };
        insights: any = {
            is_data_available: false,
            data: [],
        };
        pageContext: string = '';
        comparisonReportLoader = 'Comparison report loading';
        // params: any = {
        //     id: '',
        //     tab: '',
        //     section: '',
        // };
        printedChartWidth: string = '475'; // Annoying that this is necessary for print...
        printedChartFullWidth: string = '1015';
        printedChartGridWidth: string = '520';
        ready: boolean = false;
        reportReady = false;
        // sentences: any = {};
        shareToken: any = {};
        sortGroup: string | null = '';

        ComparisonMode = ComparisonMode;
        Utils = Utils;

        async mounted() {
            this.appStore.setLoader(this.comparisonReportLoader);
            await this.initialize(true);

            this.reportReady = this.insights.is_data_available && !this.containsSensitiveData;
            this.appStore.clearLoader(this.comparisonReportLoader);
            this.ready = this.reportReady;
        }

        get account() {
            return this.accountStore.getAccount;
        }

        get allowConsumerSpend() {
            return this.account.allowConsumerSpend;
        }

        get allPersonasHaveConglomerateRfmCategoryData() {
            return this.insights.data.every(
                (insights: any) => insights['conglomerate_rfm']?.hasOwnProperty('categories')
            );
        }

        get allPersonasHaveConglomerateRfmMarketData() {
            return this.insights.data.every(
                (insights: any) => StaticUtils.versionCheck(insights.conglomerate_rfm?.reportFormatVersion || '0', '1.1.1', StaticUtils.versionMatchAtLeast)
            );
        }

        get allPersonasHaveHighlevelRfmData() {
            return this.insights.data.every(
                (insights: any) => insights['high_level_rfm']?.hasOwnProperty('highLevelRfmVariables')
            );
        }

        get allPersonasHaveRollupData() {
            return this.insights.data.every(
                (insights: any) => insights.social['brands']?.hasOwnProperty('topicRollupsSummary')
            );
        }

        get allPersonasHaveZScoreData() {
            return this.insights.data.every(
                (insights: any) => insights.unique.brandTopics.top[0].hasOwnProperty('zScore')
            );
        }

        get chartList() {
            switch (this.$route.name) {
                case 'comparisonReportPDF':
                    const baseChartIds = ['dma_code', 'metro_area', 'region', 'state'];
                    if (!this.ready) {
                        // Just use the base chart IDs to generate the asynchronous data
                        return baseChartIds;
                    }

                    let chartIds: string[] = [];
                    for (let personaIndex = 0; personaIndex < comparisonActivityCount; ++personaIndex) {
                        for (const baseChartId of baseChartIds) {
                            chartIds.push(`${baseChartId}${ReportUtilities.segmentSeparator}${personaIndex}`);
                        }
                    }
                    return chartIds;
            }

            switch (this.params.tab) {
                case undefined: // Summary (default tab)
                case '':
                    let chartIds: string[] = [];
                    for (let personaIndex = 0; personaIndex < comparisonActivityCount; ++personaIndex) {
                        chartIds.push(`dma_code${ReportUtilities.segmentSeparator}${personaIndex}`);
                    }
                    return chartIds;

                case 'brands':
                case 'interests':
                    switch (this.params.section) {
                        case 'summary':
                        case 'top': {
                            // Summaries - summarySocialPattern
                            let chartList = [`${this.params.section}_${this.params.tab}_twitter_comparison`];
                            if (this.allPersonasHaveRollupData) {
                                // Newer Personas may have rollup data or alternate charts
                                switch (this.params.section) {
                                    case 'summary':
                                        chartList.push(`${this.params.section}_rollup_${this.params.tab}_twitter_comparison`);
                                        break;
                                    case 'top':
                                        // TODO: future replacement for top chart?
                                        break;
                                }
                            }

                            return chartList;
                        }
                        default: {
                            // Affinities
                            if (this.pageContext) {
                                return [`affinity_${this.pageContext}_${this.params.tab}_twitter_comparison`];
                            }

                            return [];
                        }
                    }

                case 'consumer-spending':
                    switch (this.params.section) {
                        case 'categories':
                            // Categories
                            if (!this.pageContext) {
                                return [];
                            }

                            const category = this.insights.data[0].consumer_spend.categories
                                .find((category: any) => Utils.slug(category.name) === this.pageContext);

                            return Utils.sortByProperty(category.subCategories, 'displayOrder', 'asc')
                                .map(subcategory => `consumer_spending_comparison_${this.params.section}_${subcategory.id}`);

                        case 'summary':
                            return ['consumer_spending_summary_comparison'];
                    }
                    break;

                case 'custom-topics':
                    if (this.params.section === 'social-characteristics') {
                        return ['social_engagement_twitter_comparison'];
                    } else {
                        return [`user_defined_social_engagement_comparison_twitter_${this.params.section}`];
                    }

                case 'demographics':
                    switch (this.params.section) {
                        case 'age-income-home':
                            return [
                                'demographic_age_range|compare_count',
                                'demographic_age_range|compare_index',
                                'demographic_income_range|compare_count',
                                'demographic_income_range|compare_index',
                                'demographic_net_worth_range|compare_count',
                                'demographic_net_worth_range|compare_index',
                                'demographic_home_value_range|compare_count',
                                'demographic_home_value_range|compare_index',
                            ];
                        case 'gender-marriage-kids':
                            return [
                                'demographic_gender|compare_count',
                                'demographic_gender|compare_index',
                                'demographic_marital_status|compare_count',
                                'demographic_marital_status|compare_index',
                                'demographic_children_age_range|compare_count',
                                'demographic_children_age_range|compare_index',
                                'demographic_children_present_in_hh|compare_count',
                                'demographic_children_present_in_hh|compare_index',
                                'demographic_household_composition|compare_count',
                                'demographic_household_composition|compare_index',
                            ];
                        case 'politics-job-residence':
                            return [
                                'demographic_political_party|compare_count',
                                'demographic_political_party|compare_index',
                                'demographic_county_size|compare_count',
                                'demographic_county_size|compare_index',
                                'demographic_occupation_type|compare_count',
                                'demographic_occupation_type|compare_index',
                                'demographic_length_of_residence_range|compare_count',
                                'demographic_length_of_residence_range|compare_index',
                                'demographic_dwelling_type|compare_count',
                                'demographic_dwelling_type|compare_index',
                            ];
                    }
                    break;

                case 'geographic-areas':
                    switch (this.params.section) {
                        // case 'media-markets':
                        //     return 'dma_code';
                        case 'metro-areas':
                            return ['metro_area'];
                        // case 'regions':
                        //     return 'region';
                        // case 'states':
                        //     return 'state';
                    }
                    break;

                case 'geography':
                    switch (this.pageContext) {
                        case 'media-markets':
                        default:
                            return ['dma_code'];
                        case 'regions':
                            return ['region'];
                        case 'states':
                            return ['state'];
                    }
                // break;

                case 'market-affinity':
                    switch (this.params.section) {
                        case 'all':
                            return this.pageContext ?
                                [`conglomerate_rfm_comparison:${this.pageContext}`] :
                                [];

                        case 'overview':
                            return [
                                // {chartId: 'conglomerate_rfm_market_overview_treemap', cols: 'lg-10 offset-lg-1'},
                                'conglomerate_rfm_comparison_market_overview_top',
                                'conglomerate_rfm_comparison_market_overview_standout',
                            ];
                    }
                    break;

                case 'past-purchases':
                    switch (this.params.section) {
                        case 'clothing-children':
                            return [
                                'past_purchase_womens_fashion|compare_count',
                                'past_purchase_womens_fashion|compare_index',
                                'past_purchase_mens_fashion|compare_count',
                                'past_purchase_mens_fashion|compare_index',
                                'past_purchase_childrens_products|compare_count',
                                'past_purchase_childrens_products|compare_index',
                            ];
                        case 'finance-shopping-style':
                            return [
                                'past_purchase_where_they_spend|compare_count',
                                'past_purchase_where_they_spend|compare_index',
                                'past_purchase_how_they_spend|compare_count',
                                'past_purchase_how_they_spend|compare_index',
                                'past_purchase_financial_purchases|compare_count',
                                'past_purchase_financial_purchases|compare_index',
                            ];
                        case 'interests-activities':
                            return [
                                'past_purchase_outdoor_sports|compare_count',
                                'past_purchase_outdoor_sports|compare_index',
                                'past_purchase_homelife|compare_count',
                                'past_purchase_homelife|compare_index',
                                'past_purchase_interests_pastimes|compare_count',
                                'past_purchase_interests_pastimes|compare_index',
                                'past_purchase_soho_home_entertainment|compare_count',
                                'past_purchase_soho_home_entertainment|compare_index',
                                'past_purchase_travel|compare_count',
                                'past_purchase_travel|compare_index',
                                'past_purchase_hobbies|compare_count',
                                'past_purchase_hobbies|compare_index',
                            ];
                    }
                    break;


                case 'rfm': {
                    switch (this.params.section) {
                        case 'credit-card':
                            return [
                                // 'high_level_rfm:rfm_idx:gauge',
                                // 'rfm_credit_all',
                                'high_level_rfm:cc_amt|compare_count',
                                'high_level_rfm:cc_amt|compare_index',
                                'high_level_rfm:cc_ord_num|compare_count',
                                'high_level_rfm:cc_ord_num|compare_index',
                                // 'rfm_credit_major',
                                'high_level_rfm:cc_major_amt|compare_count',
                                'high_level_rfm:cc_major_amt|compare_index',
                                'high_level_rfm:cc_major_ord_num|compare_count',
                                'high_level_rfm:cc_major_ord_num|compare_index',
                            ];
                        case 'response-rate':
                            return [
                                // 'high_level_rfm:recency_idx:gauge',
                                'high_level_rfm:ord_num|compare_count',
                                'high_level_rfm:ord_num|compare_index',
                                'high_level_rfm:mem_buyer_num|compare_count',
                                'high_level_rfm:mem_buyer_num|compare_index',
                                'high_level_rfm:lat_incep_months|compare_count',
                                'high_level_rfm:lat_incep_months|compare_index',
                                'high_level_rfm:incep_source_l12_num|compare_count',
                                'high_level_rfm:incep_source_l12_num|compare_index',
                                'high_level_rfm:recency|compare_count',
                                'high_level_rfm:recency|compare_index',
                            ];
                        case 'revenue':
                            return [
                                // 'rfm_ord_all',
                                'high_level_rfm:ord_avg_amt|compare_count',
                                'high_level_rfm:ord_avg_amt|compare_index',
                                'high_level_rfm:ord_lrg_amt|compare_count',
                                'high_level_rfm:ord_lrg_amt|compare_index',
                                'high_level_rfm:ord_lat_amt|compare_count',
                                'high_level_rfm:ord_lat_amt|compare_index',
                                'high_level_rfm:tot_amt|compare_count',
                                'high_level_rfm:tot_amt|compare_index',
                                'high_level_rfm:tot_amt_per_mo|compare_count',
                                'high_level_rfm:tot_amt_per_mo|compare_index',
                            ];

                    }
                }
            }

            return [];
        }

        get chartListAssociatedData() {
            let data = [];

            for (const chartId of this.chartList) {
                const associatedId = this.chartAssociatedId(chartId);
                if (associatedId) {
                    data.push({
                        chartId,
                        associatedId,
                        data: this.chartAssociatedData(associatedId),
                    });
                }
            }

            return data;
        }

        get chartListData() {
            if (this.ready) {
                return this.chartList.map((chartId: string) => {
                    return {
                        chartId,
                        data: this.chartData(chartId),
                    }
                });
            } else {
                return [];
            }
        }

        get comparisonBuiltBy(): string {
            return `${this.comparison.created?.user?.firstName || 'unknown'} ${this.comparison.created?.user?.lastName || 'unknown'}`;
        }

        get comparison() {
            return this.comparisonStore.getComparison;
        }

        get comparisonPersonas() {
            return this.comparisonStore.getPersonas;
        }

        get comparisonReportLoading() {
            return this.appStore.getLoaderActive(this.comparisonReportLoader);
        }

        get containsSensitiveData(): boolean {
            // return this.comparison?.sensitiveGroupData;
            return this.comparison?.containsSensitiveData;
        }

        get customComponentKey() {
            return `customcomp-${this.helpId}`;
        }

        get customTopic() {
            return this.sharedTopics.custom
                .find(sharedTopic => sharedTopic.params.section === this.params.section)
                .topic || null;
        }

        get fieldDictionary() {
            return this.accountStore.getAccountDictionary;
        }

        get filterGroupOptions() {
            switch (this.params.tab) {
                case 'brands':
                case 'interests':
                    switch (this.params.section) {
                        case 'summary':
                            this.filterGroup = this.filterGroup || 'index';

                            return [
                                {
                                    group: 'index',
                                    color: ReportUtilities.reportColors.comparison.filter.index,
                                    label: `Show ${ReportUtilities.indexLabel}`,
                                },
                                {
                                    group: 'groupRatio',
                                    color: ReportUtilities.reportColors.comparison.filter.groupRatio,
                                    label: 'Show Breadth of Interest',
                                },
                                {
                                    group: 'groupPenetrationRatio',
                                    color: ReportUtilities.reportColors.comparison.filter.groupPenetrationRatio,
                                    label: 'Show Depth of Interest',
                                },
                            ];

                        case 'top':
                            this.filterGroup = this.filterGroup || 'index';
                            // this.filterSettings.count = this.filterSettings?.count || 100;
                            this.filterSettings.count = 100;

                            return [
                                // {
                                //     id: 'topFilter',
                                //     color: 'orange',
                                //     label: `Top ${this.filterSettings.count}`,
                                //     items: [
                                //         {
                                //             label: 'Top 100',
                                //             value: 100,
                                //             callback: () => {
                                //                 this.filterSettings.count = 100;
                                //             }
                                //         },
                                //         {
                                //             label: 'Top 500',
                                //             value: 500,
                                //             callback: () => {
                                //                 this.filterSettings.count = 500;
                                //             }
                                //         },
                                //     ]
                                // },
                                {
                                    group: 'index',
                                    color: ReportUtilities.reportColors.comparison.filter.index,
                                    label: 'Show Index',
                                },
                                {
                                    group: 'groupRatio',
                                    color: ReportUtilities.reportColors.comparison.filter.groupRatio,
                                    label: 'Show Count',
                                },
                            ];

                        default:
                            // Affinities
                            this.filterGroup = this.filterGroup || 'index';

                            return [
                                {
                                    group: 'index',
                                    color: ReportUtilities.reportColors.comparison.filter.index,
                                    label: `Show ${ReportUtilities.indexLabel}`,
                                },
                                {
                                    group: 'groupRatio',
                                    color: ReportUtilities.reportColors.comparison.filter.groupRatio,
                                    label: `Show ${ReportUtilities.groupRatioLabel}`,
                                },
                            ]
                    }
                    break;

                case 'consumer-spending':
                    this.filterGroup = this.filterGroup || 'index';

                    return [
                        {
                            group: 'index',
                            color: 'green',
                            label: `Show ${ReportUtilities.indexLabel}`,
                        },
                        {
                            group: 'groupRatio',
                            color: 'blue',
                            label: `Show ${ReportUtilities.groupRatioLabel}`,
                        },
                    ];
                    break;

                case 'custom-topics':
                    this.filterGroup = this.filterGroup || 'index';
                    // this.filterSettings.count = 100;

                    return [
                        {
                            group: 'index',
                            color: 'green',
                            label: `Show ${ReportUtilities.indexLabel}`,
                        },
                        {
                            group: 'groupRatio',
                            color: 'blue',
                            label: `Show ${ReportUtilities.groupRatioLabel}`,
                        },
                    ];
                    break;

                case 'market-affinity':
                    switch (this.params.section) {
                        case 'all':
                            this.filterGroup = this.filterGroup || 'index';

                            return [
                                {
                                    group: 'index',
                                    color: 'green',
                                    label: `Show ${ReportUtilities.indexLabel}`,
                                },
                                {
                                    group: 'groupRatio',
                                    color: 'blue',
                                    label: `Show ${ReportUtilities.groupRatioLabel}`,
                                },
                            ];
                    }
                    break;
            }

            return [];
        }

        get hasCustomLayout() {
            return this.customLayout?.hasOwnProperty('component');
        }

        get layoutColumns() {
            switch (this.params.tab) {
                case 'brands':
                case 'custom-topics':
                case 'interests':
                    if (this.params.section === 'summary') {
                        if (this.allPersonasHaveRollupData) {
                            // If this is a NEW Persona with rollup data, or an OLD one with IG data, we can show two columns
                            return 2;
                        }
                    }

                    // Show the only existing chart in one column
                    return 1;

                case 'consumer-spending':
                    return this.params.section === 'summary' ?
                        -1 : // Full-width column
                        2;

                case 'geographic-areas':
                    return 0;

                case 'market-affinity':
                    return -1; // Full width column
            }

            return 2;
        }

        get pageContextLabel() {
            if (!this.pageContextMenu) {
                return '';
            }

            return this.pageContextMenu.find((item: any) => item.context === this.pageContext)?.label || 'UNKNOWN CONTEXT';
        }

        get pageContextMenu(): any[] | boolean {
            if (!this.ready) {
                return false;
            }

            let menuItems: any[] = [];

            switch (this.params.tab) {
                case 'brands':
                case 'interests':
                    switch (this.params.section) {
                        case 'categories':
                            for (const topic of this.sharedTopics.default[this.params.tab]) {
                                const label = topic.name;
                                // Check for duplicate entry
                                if (!menuItems?.some(checkTopic => checkTopic.label === label)) {
                                    menuItems?.push({
                                        pageId: `${this.params.tab}_${this.params.section}`,
                                        label,
                                        context: Utils.slug(label),
                                    })
                                }
                            }
                    }
                    break;

                case 'consumer-spending':
                    switch (this.params.section) {
                        case 'categories':
                            // Only display categories which are available on EVERY Persona in the comparison
                            const commonCategories = this.insights.data[0].consumer_spend.categories
                                .filter(category => {
                                    if (!category.subCategories[0].displayComparison) {
                                        // This one's exempt from Comparison display, remove it from the list
                                        return false;
                                    }

                                    // Ensure this category is available in ALL Personas within the comparison
                                    for (let i = 1, maxI = this.insights.data.length; i < maxI; ++i) {
                                        const matchingCategory = this.insights.data[i].consumer_spend.categories
                                            .find(personaCategory => personaCategory.id === category.id);
                                        if (!matchingCategory || !matchingCategory.subCategories[0].displayComparison) {
                                            // Remove from the list, it's missing from a Persona
                                            return false;
                                        }
                                    }

                                    return true;
                                });

                            for (const category of commonCategories) {
                                const label = category.name,
                                    context = Utils.slug(label);
                                menuItems.push({
                                    pageId: `${this.params.tab}_${context}`,
                                    label,
                                    context,
                                });
                            }
                            break;

                        case 'summary':
                        case 'top':
                            return false;
                    }
                    break;

                case 'geography':
                    menuItems = ['Media Markets', 'States', /*'Metro Areas',*/ 'Regions'].map(label => {
                        const context = Utils.slug(label);
                        return {
                            pageId: `${this.params.tab}_${context}`,
                            label,
                            context,
                        }
                    });
                    break;

                case 'market-affinity':
                    switch (this.params.section) {
                        case 'all':
                            for (const market of this.sharedConglomerateRfmMarkets || []) {
                                // TODO: decorate category/subcategory ID on market level?

                                // // OLD
                                // const label = market.category;
                                // // Check for duplicate entry
                                // if (!menuItems?.some(checkTopic => checkTopic.label === label)) {
                                //     menuItems?.push({
                                //         pageId: `${this.params.tab}_${this.params.section}`,
                                //         label,
                                //         context: Utils.slug(label),
                                //     })
                                // }

                                // NEW
                                const category = this.insights.data[0].conglomerate_rfm.categories
                                    .find((c: any) => c.subCategories.some((s: any) => s.markets.some((m: any) => market.id === m.id)));
                                const label = market.category;
                                const context = category.id;
                                // Check for duplicate entry
                                if (!menuItems?.some(checkTopic => checkTopic.context === context)) {
                                    menuItems?.push({
                                        pageId: `${this.params.tab}_${this.params.section}`,
                                        label,
                                        context,
                                    })
                                }
                            }

                            menuItems = Utils.sortByProperty(menuItems, 'label', 'asc');
                    }
                    break;
            }

            if (menuItems.length) {
                // nextTick(() => {
                //     // MUST happen in nextTick() to avoid recursive updates!
                //     this.pageContext = menuItems[0].context;
                // })

                return menuItems;
            }

            // nextTick(() => {
            //     // MUST happen in nextTick() to avoid recursive updates!
            //     this.pageContext = '';
            // })

            return false;
        }

        get params() {
            return Object.assign(
                {},
                this.$route.params,
                {pageContext: this.pageContext}
            );
        }

        get printMode(): boolean {
            return this.$route.name === 'comparisonReportPDF';
        }

        get sectionKey() {
            let sectionKey = this.params.tab;
            if (this.params.section) {
                sectionKey += `-${this.params.section}`;
            }

            return sectionKey;
        }

        get sectionTitle() {
            // TODO: this is a clone of the Persona report method - move to reusable spot?
            let title = 'UNKNOWN';

            switch (this.params.tab) {
                case undefined: // Summary (default tab)
                case '':
                    title = `${this.comparison.name}: At A Glance`;
                    break;

                case 'brands':
                    title = 'Compare Brand Affinity';

                    switch (this.params.section) {
                        case 'summary':
                            title += `: Summary by Category`;
                            break;
                        case 'top':
                            title += `: Full List of Top Brands`;
                            break;
                        default:
                            title += ': ';
                            break;
                    }
                    break;

                case 'consumer-spending':
                    title = 'Compare Consumer Spending: ';

                    switch (this.params.section) {
                        case 'summary':
                            title += `Summary by Category`;
                            break;
                        // default:
                        //     const categoryName = this.insights.data[0].consumer_spend.categories
                        //         .find(category => Utils.slug(category.name) === this.params.section)
                        //         .name;
                        //     title += `: ${categoryName}`;
                        //     break;
                    }
                    break;

                case 'custom-topics':
                    switch (this.params.section) {
                        case 'social-characteristics':
                            title = 'Compare Social Characteristics Used to Define these Personas';
                            break;
                        default:
                            title = `Compare Added Chart: ${this.customTopic?.name || 'UNKNOWN CHART'}`;
                            break;
                    }
                    break;

                case 'demographics':
                    title = 'Compare Demographics';

                    switch (this.params.section) {
                        case 'age-income-home':
                            title += `: Age, Income, & Home Value`;
                            break;
                        case 'gender-marriage-kids':
                            title += `: Gender, Marriage, & Kids`;
                            break;
                        case 'politics-job-residence':
                            title += `:  Politics, Job, & Residence`;
                            break;
                    }
                    break;

                case 'geographic-areas':
                case 'geography':
                    title = 'Compare Geography: ';

                    switch (this.params.section) {
                        case 'metro-areas':
                            title += `Distribution by Metro Area`;
                            break;
                    }
                    break;

                case 'interests':
                    title = 'Compare Interest Affinity';

                    switch (this.params.section) {
                        case 'summary':
                            title += `: Summary by Category`;
                            break;
                        case 'top':
                            title += `: Full List of Top Interests`;
                            break;
                        default:
                            title += ': ';
                            break;
                    }
                    break;

                case 'market-affinity':
                    switch (this.params.section) {
                        case 'all':
                        default:
                            title = `All Markets:`;
                            break;

                        case 'markets':
                            title = `Markets in Persona:`;
                            break;

                        case 'overview':
                            title = `Market Affinity Overview`;
                            break;
                    }
                    break;

                case 'past-purchases':
                    title = 'Compare Past Purchases';

                    switch (this.params.section) {
                        case 'clothing-children':
                            title += `: Clothing and Children Goods`;
                            break;
                        case 'finance-shopping-style':
                            title += `: Finance and Shopping Styles`;
                            break;
                        case 'interests-activities':
                            title += `: Interests and Activities`;
                            break;
                    }
                    break;

                case 'rfm':
                    title = 'Compare RFM: ';

                    switch (this.params.section) {
                        case 'credit-card':
                            title += `Credit Card Usage`;
                            break;
                        case 'response-rate':
                            title += `Response Rate Drivers`;
                            break;
                        case 'revenue':
                            title += `Revenue Drivers`;
                            break;
                    }
                    break;

                case 'social-activity':
                    title = 'Compare Social Activity: Social Graph, Day Parts, Top Content';
                    break;
            }

            return title;
        }

        get sentences() {
            return this.accountStore.getAccountSentences;
        }

        get sharedConglomerateRfmMarkets(): any[] | null {
            // Check whether the insights data is available yet
            if (this.insights.data?.length < comparisonActivityCount) {
                return null;
            }

            let sharedMarkets = [];

            for (let i = 0, end = this.insights.data.length; i < end; ++i) {
                const personaInsights = this.insights.data[i];
                if (!personaInsights || !personaInsights.hasOwnProperty('conglomerate_rfm')) {
                    // Data is not ready yet!
                    return null;
                }

                // const marketKeys = Object.keys();
                for (const market of personaInsights.conglomerate_rfm.markets) {
                    let personaMarket = pick(
                        clone(market),
                        ['category', 'subCategory', 'id', 'name', 'personaIndices']
                    );

                    let sourceMarket = sharedMarkets.find((market: any) => market.id === personaMarket.id);
                    if (sourceMarket) {
                        // Update existing market
                        sourceMarket.personaIndices = union(sourceMarket.personaIndices, [i]);
                    } else {
                        sharedMarkets.push(Object.assign({}, personaMarket, {
                            personaIndices: [i],
                        }))
                    }
                }
            }

            return sharedMarkets.filter(market => market.personaIndices.length === comparisonActivityCount);
        }

        get sharedConglomerateRfmMarketSelections(): any[] {
            let sharedMarketSelections: any[] = [];
            if (this.comparisonPersonas.length < comparisonActivityCount || !this.allPersonasHaveConglomerateRfmMarketData) {
                return sharedMarketSelections;
            }

            let i = 0;
            for (const persona of this.comparisonPersonas) {
                for (const market of persona.conglomerateRfm?.markets || []) {
                    let personaMarket = pick(
                        clone(market),
                        ['category', 'subCategory', 'id', 'name', 'personaIndices']
                    );

                    let sourceMarket: any = sharedMarketSelections.find((market: any) => market.id === personaMarket.id);
                    if (sourceMarket) {
                        // Update existing market
                        sourceMarket.personaIndices = union(sourceMarket.personaIndices, [i]);
                    } else {
                        sharedMarketSelections.push(Object.assign({}, personaMarket, {
                            personaIndices: [i],
                        }))
                    }
                }

                ++i;
            }

            return sharedMarketSelections.filter(market => market.personaIndices.length === comparisonActivityCount);
        }

        get sharedMode() {
            return this.$route.path.indexOf('/share/') > -1 && this.$route.params.token?.length > 0;
        }

        get sharedTopics(): any {
            // Check whether the insights data is available yet
            if (this.insights.data?.length < comparisonActivityCount) {
                return null;
            }

            let sharedTopics = {
                custom: [],
                default: {},
            };

            const contexts = ['brands', 'interests'];
            for (let i = 0, end = this.insights.data.length; i < end; ++i) {
                const personaInsights = this.insights.data[i];
                if (!personaInsights || !personaInsights.hasOwnProperty('consumer_spend')) {
                    // Data is not ready yet!
                    return null;
                }

                // Always-on default topics
                const source = personaInsights.social;
                for (const context of contexts) {
                    if (!sharedTopics.default.hasOwnProperty(context)) {
                        sharedTopics.default[context] = [];
                    }

                    // Iterate over the platforms
                    for (const platform of Object.keys(source[context].defaultTopics)) {
                        for (let topic of source[context].defaultTopics[platform]) {
                            let platformTopic = pick(clone(topic), ['category', 'id', 'name', 'personaIndices']);
                            let sourceTopic = sharedTopics.default[context].find(topic => topic.id === platformTopic.id);
                            if (sourceTopic) {
                                // Update existing topic
                                sourceTopic.personaIndices = union(sourceTopic.personaIndices, [i]);
                            } else {
                                // Add new topic to the list
                                sharedTopics.default[context].push(Object.assign({}, platformTopic, {
                                    personaIndices: [i],
                                }))
                            }
                        }
                    }
                }

                // Custom user topics
                const customTopicsData = this.insights.data[i].social.customTopics;

                // Summary topic
                const summaryTopicSection = 'social-characteristics';
                if (customTopicsData.hasOwnProperty('groupDefinitionTopic')) {
                    let sourceTopic = sharedTopics.custom.find(topic => topic.params.section === summaryTopicSection);
                    if (sourceTopic) {
                        // Update existing topic
                        sourceTopic.personaIndices = union(sourceTopic.personaIndices, [i]);
                    } else {
                        // Add new topic to the list
                        sharedTopics.custom.push({
                            userDefined: false,
                            label: 'Persona Social Characteristics',
                            name: this.$route.name,
                            params: {tab: 'custom-topics', section: summaryTopicSection},
                            personaIndices: [i],
                        })
                    }
                }

                // User-defined topics
                if (size(customTopicsData.userDefinedTopics)) {
                    const userTopics = Utils.sortByProperty(
                        customTopicsData.userDefinedTopics,
                        'name',
                        'asc'
                    );
                    for (const topic of userTopics) {
                        const userTopicSection = Utils.slug(topic.name);
                        let sourceTopic = sharedTopics.custom.find(topic => topic.params.section === userTopicSection);
                        if (sourceTopic) {
                            // Update existing topic
                            sourceTopic.personaIndices = union(sourceTopic.personaIndices, [i]);
                        } else {
                            // Add new topic to the list
                            sharedTopics.custom.push({
                                userDefined: true,
                                label: `User named topic "${topic.name}"`,
                                name: this.$route.name,
                                params: {tab: 'custom-topics', section: userTopicSection},
                                personaIndices: [i],
                                topic,
                            });
                        }
                    }
                }
            }

            for (const context of contexts) {
                sharedTopics.default[context] = sharedTopics.default[context].filter(topic => topic.personaIndices.length === comparisonActivityCount);
            }
            sharedTopics.custom = sharedTopics.custom.filter(topic => topic.personaIndices.length === comparisonActivityCount);

            return sharedTopics;
        }

        get sortGroupOptions() {
            switch (this.params.tab) {
                case 'brands':
                case 'consumer-spending':
                case 'custom-topics':
                case 'interests':
                case 'market-affinity': {
                    this.sortGroup = this.sortGroup || 'independent';
                    let options: any[] = [
                        {
                            group: 'independent',
                            color: ReportUtilities.reportColors.comparison.sort.independent,
                            label: 'Sort Independently',
                        },
                    ];

                    for (let i = 0, end = this.insights.data.length; i < end; ++i) {
                        options.push({
                            group: `${i}`,
                            color: ReportUtilities.reportColors.comparison.sort[i],
                            label: `Sort by Persona ${Utils.numberToAlpha(i + 1)}`,
                        });
                    }

                    return options;
                }
            }

            return [];
        }

        get subnavLinks() {
            const routeName = this.$route.name;
            let customTopics = {
                // disabled: true,
                id: 'custom-topics',
                label: 'Added Charts',
                name: routeName,
                params: {tab: 'custom-topics'},
            };

            if (size(this.sharedTopics.custom)) {
                // If any topics exist, add them
                Object.assign(customTopics, {links: this.sharedTopics.custom});
            } else {
                // Otherwise, disable this nav item
                Object.assign(customTopics, {
                    disabled: true,
                    title: 'No Topics were analyzed',
                });
            }
            /***** END custom topic handling *****/

            let conglomerateRfmMarket: any = null;
            let pastPurchases: any = null;
            if (this.allPersonasHaveConglomerateRfmMarketData) {
                conglomerateRfmMarket = {
                    id: 'market-affinity',
                    label: 'Market Affinity',
                    name: routeName,
                    params: {tab: 'market-affinity'},
                    links: [
                        {
                            label: 'All Markets',
                            name: routeName,
                            params: {tab: 'market-affinity', section: 'all'},
                        },
                    ],
                };

                if (this.allPersonasHaveConglomerateRfmCategoryData) {
                    conglomerateRfmMarket.links.unshift({
                        label: 'Overview',
                        name: routeName,
                        params: {tab: 'market-affinity', section: 'overview'},
                    });
                }
            } else {
                pastPurchases = {
                    label: 'Past Purchases',
                        name: routeName,
                    params: {tab: 'past-purchases'},
                    links: [
                        {
                            label: 'Clothing and Children',
                            name: routeName,
                            params: {tab: 'past-purchases', section: 'clothing-children'},
                        },
                        {
                            label: 'Interests and Activities',
                            name: routeName,
                            params: {tab: 'past-purchases', section: 'interests-activities'},
                        },
                        {
                            label: 'Finance and Shopping Style',
                            name: routeName,
                            params: {tab: 'past-purchases', section: 'finance-shopping-style'},
                        },
                    ],
                };
            }

            let consumerSpending: any | null = null;
            if (this.allowConsumerSpend) {
                let consumerSpendLinks = [
                    {
                        label: 'Spending Summary',
                        name: routeName,
                        params: {tab: 'consumer-spending', section: 'summary'},
                    },
                    {
                        label: 'Spending Categories',
                        name: routeName,
                        params: {tab: 'consumer-spending', section: 'categories'},
                    },
                ];
                // // Only display categories which are available on EVERY Persona in the comparison
                // const commonCategories = this.insights.data[0].consumer_spend.categories
                //     .filter(category => {
                //         if (!category.subCategories[0].displayComparison) {
                //             // This one's already not shown, remove it from the list
                //             return false;
                //         }
                //
                //         // Ensure this category is available in ALL Personas within the comparison
                //         for (let i = 1, maxI = this.insights.data.length; i < maxI; ++i) {
                //             const matchingCategory = this.insights.data[i].consumer_spend.categories
                //                 .find(personaCategory => personaCategory.id === category.id);
                //             if (!matchingCategory || !matchingCategory.subCategories[0].displayComparison) {
                //                 // Remove from the list, it's missing from a Persona
                //                 return false;
                //             }
                //         }
                //
                //         return true;
                //     });
                //
                // for (const category of commonCategories) {
                //     consumerSpendLinks.push({
                //         label: category.name,
                //         name: routeName,
                //         params: {tab: 'consumer-spending', section: Utils.slug(category.name)},
                //     });
                // }

                consumerSpending = {
                    id: 'consumer-spending',
                    label: 'Consumer Spending',
                    name: routeName,
                    params: {tab: 'consumer-spending'},
                    links: consumerSpendLinks,
                };
            }

            let highlevelRfm: any | null = null;
            if (this.allPersonasHaveHighlevelRfmData) {
                highlevelRfm = {
                    id: 'rfm',
                    label: 'RFM',
                    name: routeName,
                    params: {tab: 'rfm'},
                    links: [
                        {
                            label: 'Credit Card Usage',
                            name: routeName,
                            params: {tab: 'rfm', section: 'credit-card'},
                        },
                        {
                            label: 'Response Rate Drivers',
                            name: routeName,
                            params: {tab: 'rfm', section: 'response-rate'},
                        },
                        {
                            label: 'Revenue Drivers',
                            name: routeName,
                            params: {tab: 'rfm', section: 'revenue'},
                        },
                    ],
                };
            }

            let navLinks = {
                placement: 'page',
                links: [
                    {
                        label: 'Summary',
                        name: routeName,
                        params: {tab: ''},
                        default: true
                    },

                    {
                        label: 'Demographics',
                        name: routeName,
                        params: {tab: 'demographics'},
                        links: [
                            {
                                label: 'Age, Income, & Home',
                                name: routeName,
                                params: {tab: 'demographics', section: 'age-income-home'},
                            },
                            {
                                label: 'Gender, Marriage, & Kids',
                                name: routeName,
                                params: {tab: 'demographics', section: 'gender-marriage-kids'},
                            },
                            {
                                label: 'Politics, Job, & Residence',
                                name: routeName,
                                params: {tab: 'demographics', section: 'politics-job-residence'},
                            },
                            {
                                label: 'Geography',
                                name: routeName,
                                params: {tab: 'geography'},
                            },
                            // {
                            //     label: 'Geography: Media Markets',
                            //     name: routeName,
                            //     params: {tab: 'geographic-areas', section: 'media-markets'},
                            // },
                            // {
                            //     label: 'Geography: States',
                            //     name: routeName,
                            //     params: {tab: 'geographic-areas', section: 'states'},
                            // },
                            {
                                label: 'Geography: Metro Areas',
                                name: routeName,
                                params: {tab: 'geographic-areas', section: 'metro-areas'},
                            },
                            // {
                            //     label: 'Geography: Regions',
                            //     name: routeName,
                            //     params: {tab: 'geographic-areas', section: 'regions'},
                            // },
                        ],
                    },

                    {
                        label: 'Social Activity',
                        name: routeName,
                        params: {tab: 'social-activity'},
                        links: [
                            {
                                label: 'Overview',
                                name: routeName,
                                params: {tab: 'social-activity'},
                            },

                            {
                                label: 'Brands Summary',
                                name: routeName,
                                params: {tab: 'brands', section: 'summary'},
                            },
                            {
                                label: 'Brands Uncategorized',
                                name: routeName,
                                params: {tab: 'brands', section: 'top'},
                            },
                            {
                                label: 'Brand Categories',
                                name: routeName,
                                params: {tab: 'brands', section: 'categories'},
                            },

                            {
                                label: 'Interests Summary',
                                name: routeName,
                                params: {tab: 'interests', section: 'summary'},
                            },
                            {
                                label: 'Interests Uncategorized',
                                name: routeName,
                                params: {tab: 'interests', section: 'top'},
                            },
                            {
                                label: 'Interest Categories',
                                name: routeName,
                                params: {tab: 'interests', section: 'categories'},
                            },
                        ],
                    },

                    conglomerateRfmMarket,
                    pastPurchases,

                    highlevelRfm,

                    consumerSpending,

                    customTopics,
                ].filter(link => link !== null),
            };

            // Pull always-on brands & interests from API
            navLinks.links.map((link: NavLink) => {
                if (link.id) {
                    switch (link.id) {
                        case 'brands':
                        case 'interests':
                            for (const topic of this.sharedTopics.default[link.id]) {
                                const label = topic.name;
                                // Check for duplicate entry
                                if (!link.links?.some(checkTopic => checkTopic.label === label)) {
                                    link.links?.push({
                                        label,
                                        name: routeName,
                                        params: {tab: link.id, section: Utils.slug(label)},
                                    })
                                }
                            }
                            break;
                    }
                }

                return link;
            });

            return navLinks;
        }

        // blurUpdateComparisonName(event: Event) {
        //     // Re-focus the persona name input if there are unsaved changes
        //     if (this.comparison.name !== this.editableComparisonName) {
        //         const target = <HTMLElement>event.target;
        //         target.focus();
        //     } else {
        //         this.allowUpdateComparisonName = false;
        //     }
        // }

        /**
         * Get ChartAssociatedData parameters
         *
         * @param id
         */
        chartAssociatedData(id: string) {
            const [associatedId, personaIndex] = id.split(ReportUtilities.segmentSeparator);
            switch (associatedId) {
                case 'persona_influence': {
                    return {
                        title: 'Persona Influence',
                        detail: `Your persona is {{ value }} {{ direction }} to represent high influencers.`,
                        index: this.insights.data[personaIndex].social.groupMetrics.twitterStats.highInfluenceIndex,
                        value_format: 'percent',
                        positive_label: 'more likely',
                        negative_label: 'less likely',
                        baseline_label: 'High Influencers in Baseline',
                        baseline_value: this.insights.data[personaIndex].social.baselineMetrics.twitterStats.percentHighInfluence,
                        persona_label: 'High Influencers in Persona',
                        persona_value: this.insights.data[personaIndex].social.groupMetrics.twitterStats.percentHighInfluence,
                    };
                }

                case 'typical_social_following_activity': {
                    // This one's a little different, since both values are the BASELINE - just manually override everything...
                    // const followingDifference = ((this.insights.social.groupMetrics.twitterStats.followingIndex - 1) * this.insights.social.baselineMetrics.twitterStats.medianFollowing).toFixed(2);
                    const followingDifference = this.insights.data[personaIndex].social.groupMetrics.twitterStats.medianFollowing - this.insights.data[personaIndex].social.baselineMetrics.twitterStats.medianFollowing;
                    const directionLabel = followingDifference >= 0 ? 'more' : 'fewer'; // TODO: implement based on values
                    const detail_class = followingDifference >= 0 ? 'value-greater' : 'value-less'; // TODO: implement based on values

                    return {
                        title: 'Typical Social Following Activity',
                        // detail: `Your persona follows ${Math.round(Math.abs(followingDifference))} ${directionLabel} Twitter accounts than typical.`,
                        detail: `Your persona follows ${Math.round(Math.abs(followingDifference))} ${directionLabel} accounts than typical.`,
                        detail_class,
                        value_format: 'separated',
                        baseline_label: 'Median Followers in Baseline',
                        baseline_value: Math.round(this.insights.data[personaIndex].social.baselineMetrics.twitterStats.medianFollowers),
                        persona_label: 'Median Following in Baseline',
                        persona_value: Math.round(this.insights.data[personaIndex].social.baselineMetrics.twitterStats.medianFollowing),
                    };
                }

                case (associatedId.match(ReportUtilities.consumerSpendingComparisonPattern) || {}).input: {
                    // const [, , displayOrder] = associatedId.match(ReportUtilities.consumerSpendingComparisonPattern);
                    const [, , subcategoryId] = associatedId.match(ReportUtilities.consumerSpendingComparisonPattern);
                    const detail = this.filterGroup === 'index' ?
                        `Persona A indexes {{ value }}% {{ direction }} than Persona B for <strong>{{ label }}</strong>.` :
                        `{{ value }}% {{ direction }} of Persona A is <strong>{{ label }}</strong> compared to Persona B.`;
                    let title;

                    let i = 0;
                    let comparisonData = [];
                    for (const dataSegment of this.insights.data) {
                        const category = dataSegment.consumer_spend.categories.find(category => Utils.slug(category.name) === this.pageContext);
                        const subCategory = category.subCategories.find(subCategory => subCategory.id === subcategoryId);
                        title = subCategory?.name || title;

                        let series = [];
                        if (subCategory) {
                            for (const spendingIndicator of subCategory.spendingIndicators) {
                                series.push(subCategory);
                            }
                        }

                        comparisonData.push({
                            label: this.comparisonPersonas[i].name,
                            series,
                        });

                        ++i;
                    }

                    return ReportUtilities.chartAssociatedGreatestDifference(
                        title,
                        detail,
                        comparisonData,
                        this.filterGroup!,
                        'name'
                    );
                }

                case (associatedId.match(ReportUtilities.consumerSpendingPattern) || {}).input: {
                    const [, , displayOrder] = associatedId.match(ReportUtilities.consumerSpendingPattern),
                        category = this.insights.consumer_spend.categories
                            .find(category => Utils.slug(category.name) === this.params.section),
                        subCategory = category.subCategories.find(subCategory => subCategory.displayOrder === parseInt(displayOrder));

                    return {
                        title: subCategory.name,
                        detail: `Your persona is {{ value }} {{ direction }} to spend on ${subCategory.name}.`,
                        index: subCategory.index,
                        value_format: 'percent',
                        positive_label: 'more likely',
                        negative_label: 'less likely',
                        baseline_label: 'Spenders in Baseline',
                        baseline_value: subCategory.baselineRatio,
                        persona_label: 'Spenders in Persona',
                        persona_value: subCategory.groupRatio,
                    };
                }

                case (associatedId.match(ReportUtilities.demographicCompareCountPattern) || {}).input:
                case (associatedId.match(ReportUtilities.demographicCompareIndexPattern) || {}).input: {
                    let detail,
                        title,
                        key,
                        property;
                    if (associatedId.match(ReportUtilities.demographicCompareCountPattern)) {
                        [, key] = associatedId.match(ReportUtilities.demographicCompareCountPattern) || ['', ''];
                        property = 'groupRatio';
                        title = this.fieldDictionary.standard[key]?.shortDescription;
                        detail = `{{ value }}% {{ direction }} of Persona A is <strong>{{ label }}</strong> compared to Persona B.`;
                    } else {
                        [, key] = associatedId.match(ReportUtilities.demographicCompareIndexPattern) || ['', ''];
                        property = 'index';
                        title = `${this.fieldDictionary.standard[key]?.shortDescription} Relative Index`;
                        detail = `Persona A indexes {{ value }}% {{ direction }} than Persona B for <strong>{{ label }}</strong>.`;
                    }

                    const comparisonData = this.insights.data.map((dataItem, i) => {
                        const series = Utils.sortByProperty(
                            ReportUtilities.dictionaryLookup(
                                this.fieldDictionary.standard,
                                dataItem.offline_standard?.demographics[key],
                                key
                            ),
                            'position',
                            'asc'
                        );

                        return {
                            label: this.comparisonPersonas[i].name,
                            series,
                        }
                    });

                    return ReportUtilities.chartAssociatedGreatestDifference(
                        title,
                        detail,
                        comparisonData,
                        property
                    );
                }

                case (associatedId.match(ReportUtilities.highLevelRfmPattern) || {}).input: {
                    return ReportUtilities.chartAssociatedData(
                        associatedId,
                        this.insights.data[personaIndex],
                        {
                            params: this.params,
                            fieldDictionary: this.fieldDictionary,
                        }
                    );
                }

                case (associatedId.match(ReportUtilities.highLevelRfmCompareCountPattern) || {}).input:
                case (associatedId.match(ReportUtilities.highLevelRfmCompareIndexPattern) || {}).input: {
                    let detail: string,
                        title: string,
                        fieldName: string,
                        property: string;
                    if (associatedId.match(ReportUtilities.highLevelRfmCompareCountPattern)) {
                        [, fieldName] = associatedId.match(ReportUtilities.highLevelRfmCompareCountPattern) || ['', ''];
                        property = 'groupRatio';
                    } else {
                        [, fieldName] = associatedId.match(ReportUtilities.highLevelRfmCompareIndexPattern) || ['', ''];
                        property = 'index';
                    }

                    // const rollupId = `purch_rollup_${key}`;
                    const dictionaryItem = this.fieldDictionary.highLevelRFM[fieldName];
                    title = dictionaryItem.shortDescription;
                    if (property === 'index') {
                        title = `${title} Relative Index`;
                        detail = `Persona A indexes {{ value }}% {{ direction }} than Persona B for <strong>{{ label }}</strong>.`;
                    } else {
                        const comparisonWord = dictionaryItem.values.some((item: any) => item.shortDescription.indexOf('Many') > -1) ?
                            'has' :
                            'is';
                        detail = `{{ value }}% {{ direction }} of Persona A ${comparisonWord} <strong>{{ label }}</strong> compared to Persona B.`;
                    }

                    const comparisonData = this.insights.data.map((dataItem: any, i: number) => {
                        let series: any[] = dataItem.high_level_rfm?.highLevelRfmVariables[fieldName]
                            .map((item: any) => Object.assign({}, item, {
                                value: dictionaryItem.values.find((field: any) => field.value === item.value)?.shortDescription || item.value
                            }));

                        return {
                            label: this.comparisonPersonas[i].name,
                            series,
                        };
                    });

                    return ReportUtilities.chartAssociatedGreatestDifference(
                        title,
                        detail,
                        comparisonData,
                        property
                    );
                }

                case (associatedId.match(ReportUtilities.pastPurchaseCompareCountPattern) || {}).input:
                case (associatedId.match(ReportUtilities.pastPurchaseCompareIndexPattern) || {}).input: {
                    let detail,
                        title,
                        key,
                        property;
                    if (associatedId.match(ReportUtilities.pastPurchaseCompareCountPattern)) {
                        [, key] = associatedId.match(ReportUtilities.pastPurchaseCompareCountPattern) || ['', ''];
                        property = 'groupRatio';
                        detail = `{{ value }}% {{ direction }} of Persona A is <strong>{{ label }}</strong> compared to Persona B.`;
                    } else {
                        [, key] = associatedId.match(ReportUtilities.pastPurchaseCompareIndexPattern) || ['', ''];
                        property = 'index';
                        detail = `Persona A indexes {{ value }}% {{ direction }} than Persona B for <strong>{{ label }}</strong>.`;
                    }

                    const rollupId = `purch_rollup_${key}`;
                    const categoryDefinition = this.fieldDictionary.standard[rollupId];
                    title = categoryDefinition.shortDescription;
                    if (property === 'index') {
                        title = `${title} Relative Index`;
                    }

                    const comparisonData = this.insights.data.map((dataItem, i) => {
                        let series: any[] = [];
                        categoryDefinition.children.forEach((dictionaryId, itemIndex) => {
                            const dictionaryItem: any = this.fieldDictionary.standard[dictionaryId] || null;
                            const dictionaryElement = dataItem.offline_standard?.demographics[dictionaryId] || null;
                            if (!dictionaryItem || !dictionaryElement) {
                                return;
                            }

                            series.push(Object.assign({}, dictionaryElement[0], {
                                originalValue: `index_${itemIndex}`,
                                // position: dictionaryItem.position,
                                value: dictionaryItem.shortDescription,
                            }));
                        })

                        return {
                            label: this.comparisonPersonas[i].name,
                            series,
                        };
                    });

                    return ReportUtilities.chartAssociatedGreatestDifference(
                        title,
                        detail,
                        comparisonData,
                        property
                    );
                }

                default:
                    return false;
            }
        }

        chartAssociatedId(chartId: string) {
            switch (chartId) {
                case 'age_range':
                    return 'age_avg';

                case 'children_age_range':
                    return 'children_age_range_index';

                case 'children_present_in_hh':
                    return 'children_present_in_hh_index';

                case 'gender':
                    return 'gender_index';

                case 'home_value_range':
                    return 'home_value_avg';

                case 'length_of_residence_range':
                    return 'length_of_residence_avg';

                case 'marital_status':
                    return 'marital_status_index';

                case 'occupation_type':
                    return 'occupation_type_highest_count';

                case 'occupation_type_index':
                    return 'occupation_type_highest_index';

                case 'persona_social_graph_profile':
                    return 'typical_social_following_activity';

                case 'twitter_follow_frequency':
                    return 'persona_influence';

                // Pattern-based chart IDs below

                case (chartId.match(ReportUtilities.consumerSpendingComparisonPattern) || {}).input:
                case (chartId.match(ReportUtilities.demographicCompareCountPattern) || {}).input:
                case (chartId.match(ReportUtilities.demographicCompareIndexPattern) || {}).input:
                case (chartId.match(ReportUtilities.highLevelRfmPattern) || {}).input:
                case (chartId.match(ReportUtilities.highLevelRfmCompareCountPattern) || {}).input:
                case (chartId.match(ReportUtilities.highLevelRfmCompareIndexPattern) || {}).input:
                case (chartId.match(ReportUtilities.pastPurchaseCompareCountPattern) || {}).input:
                case (chartId.match(ReportUtilities.pastPurchaseCompareIndexPattern) || {}).input: {
                    return chartId;
                }

                default:
                    return '';
            }
        }

        /**
         * Convenience method to retrieve and cache chart data for display
         */
        chartData(chartKey: string, returnData: boolean = true) {
            const [chartId, personaIndex] = chartKey.split(ReportUtilities.segmentSeparator);
            if (!this.chartDataCache.hasOwnProperty(chartKey)) {
                try {
                    const options = {
                        asyncData: this.asyncData,
                        chartId,
                        fieldDictionary: this.fieldDictionary,
                        filterGroup: this.filterGroup,
                        filterSettings: this.filterSettings,
                        Highcharts,
                        insights: personaIndex !== undefined ? this.insights.data[personaIndex] : this.insights.data,
                        params: this.params,
                        printMode: this.printMode,
                        returnData,
                        sortGroup: this.sortGroup,
                    }
                    const chartData = ReportUtilities.prepareChartData(options);
                    if (chartData) {
                        chartData.id = chartKey;
                    }
                    if (!(chartData.cache || true)) {
                        return chartData;
                    }
                    this.chartDataCache[chartKey] = chartData;
                } catch (err) {
                    console.error(`Chart data error on '${chartKey}' (return data: ${returnData})\r\n\r\n`, err);
                }
            }

            return this.chartDataCache[chartKey];
        }

        chartDetail(chartKey: string) {
            const [chartId, personaIndex] = chartKey.split(ReportUtilities.segmentSeparator);
            const chartData = this.chartData(chartKey),
                rawData = clone(chartData.rawData),
                sortByGroupRatio = Utils.sortByProperty(rawData, 'groupRatio', 'desc'),
                sortByIndex = Utils.sortByProperty(rawData, 'index', 'desc');

            switch (chartId) {
                case 'dma_code': {
                    const getDmaName = dmaCode => {
                        return rawData.find(feature => parseInt(feature.value) === dmaCode).name;
                    };
                    return [
                        {
                            name: 'Top Media Markets',
                            items: {
                                index: sortByIndex.slice(0, 25).map((item, i) => {
                                    return {
                                        index: i + 1,
                                        label: getDmaName(+item.value),
                                        value: `${item.index.toFixed(2)} times`,
                                    }
                                }),
                                groupRatio: sortByGroupRatio.slice(0, 25).map((item, i) => {
                                    return {
                                        index: i + 1,
                                        label: getDmaName(+item.value),
                                        value: `${item.groupRatio.toFixed(2)}%`,
                                    }
                                }),
                            },
                        },
                        {
                            name: 'Bottom Media Markets',
                            items: {
                                index: sortByIndex.reverse().slice(0, 25).map((item, i) => {
                                    return {
                                        index: sortByIndex.length - i,
                                        label: getDmaName(+item.value),
                                        value: `${item.index.toFixed(2)} times`,
                                    }
                                }),
                                groupRatio: sortByGroupRatio.reverse().slice(0, 25).map((item, i) => {
                                    return {
                                        index: sortByGroupRatio.length - i,
                                        label: getDmaName(+item.value),
                                        value: `${item.groupRatio.toFixed(2)}%`,
                                    }
                                }),
                            },
                        },
                    ];
                }

                case 'metro_area': {
                    const displayCount = Math.min(25, sortByGroupRatio.length / 2);
                    return [
                        {
                            name: 'Top Metros',
                            items: {
                                index: sortByIndex.slice(0, Math.floor(displayCount)).map((item, i) => {
                                    return {
                                        index: i + 1,
                                        label: item.value,
                                        value: `${item.index.toFixed(2)} times`,
                                    }
                                }),
                                groupRatio: sortByGroupRatio.slice(0, Math.floor(displayCount)).map((item, i) => {
                                    return {
                                        index: i + 1,
                                        label: item.value,
                                        value: `${item.groupRatio.toFixed(2)}%`,
                                    }
                                }),
                            },
                        },
                        {
                            name: 'Bottom Metros',
                            items: {
                                index: sortByIndex.reverse().slice(0, Math.ceil(displayCount)).map((item, i) => {
                                    return {
                                        index: sortByIndex.length - i,
                                        label: item.value,
                                        value: `${item.index.toFixed(2)} times`,
                                    }
                                }),
                                groupRatio: sortByGroupRatio.reverse().slice(0, Math.ceil(displayCount)).map((item, i) => {
                                    return {
                                        index: sortByGroupRatio.length - i,
                                        label: item.value,
                                        value: `${item.groupRatio.toFixed(2)}%`,
                                    }
                                }),
                            },
                        },
                    ];
                }

                case 'region': {
                    return [
                        {
                            name: 'Top Regions',
                            items: {
                                index: sortByIndex.slice(0, 25).map((item, i) => {
                                    return {
                                        index: i + 1,
                                        label: item.value,
                                        value: `${item.index.toFixed(2)} times`,
                                    }
                                }),
                                groupRatio: sortByGroupRatio.slice(0, 25).map((item, i) => {
                                    return {
                                        index: i + 1,
                                        label: item.value,
                                        value: `${item.groupRatio.toFixed(2)}%`,
                                    }
                                }),
                            },
                        },

                    ];
                }

                case 'state': {
                    const getStateName = stateCode => {
                        return rawData.find(feature => feature.value === stateCode).name
                    };
                    return [
                        {
                            name: 'Top States',
                            items: {
                                index: sortByIndex.slice(0, 25).map((item, i) => {
                                    return {
                                        index: i + 1,
                                        label: getStateName(item.value),
                                        value: `${item.index.toFixed(2)} times`,
                                    }
                                }),
                                groupRatio: sortByGroupRatio.slice(0, 25).map((item, i) => {
                                    return {
                                        index: i + 1,
                                        label: getStateName(item.value),
                                        value: `${item.groupRatio.toFixed(2)}%`,
                                    }
                                }),
                            },
                        },
                        {
                            name: 'Bottom States',
                            items: {
                                index: sortByIndex.reverse().slice(0, 25).map((item, i) => {
                                    return {
                                        index: sortByIndex.length - i,
                                        label: getStateName(item.value),
                                        value: item.index.toFixed(2),
                                    }
                                }),
                                groupRatio: sortByGroupRatio.reverse().slice(0, 25).map((item, i) => {
                                    return {
                                        index: sortByGroupRatio.length - i,
                                        label: getStateName(item.value),
                                        value: `${item.groupRatio.toFixed(2)}%`,
                                    }
                                }),
                            },
                        },
                    ];
                }
            }
        }

        clearChartDataCache() {
            this.chartDataCache = {};
        }

        // // async copyShareLink() {
        // //     this.shareLinkCopied = await Utils.copyText(document.querySelector('.modal.show [action="share-link"]').textContent);
        // //     window.setTimeout(_ => {
        // //         this.shareLinkCopied = false
        // //     }, 3000);
        // // }
        //
        // async downloadPdf() {
        //     // TODO: call share endpoint to generate share key for URL
        //
        //     // const currentUser = store.getters.getUser;
        //     // const currentAccountId = currentUser?.currentAccountId;
        //     // // const pdfUrl = `${store.getters.getActiveAccount.id}/report/${this.params.id}/pdf`;
        //     // const pdfRoute = this.$router.resolve({
        //     //     // location: {
        //     //         name: 'personaReportPDF',
        //     //         params: {
        //     //             accountId: currentAccountId,
        //     //             token: '00000000-1111-2222-3333-444444444444', // TODO: generate a real token
        //     //             id: this.persona.id,
        //     //         }
        //     //     // }
        //     // });
        //     //
        //     // const endpoint = `/api/pdfs/generate`;
        //     // const body = {
        //     //     // baseUrl: `https://stage.personabuilder.com/#/`,
        //     //     url: `${pdfRoute.href}`,
        //     //     // fileName: 'test.pdf',
        //     // };
        //     //
        //     // const pdfResponse = await api.getAxiosInstance.post(endpoint, body);
        // }

        async handleCustomLayout() {
            if (this.containsSensitiveData) {
                return false;
            }

            this.customLayout = false;

            switch (this.$route.name) {
                // Route-based custom layouts
                case 'comparisonReportPDF': {
                    let chartData: any = {
                        comparison: this.comparison,
                        personas: this.comparisonPersonas,
                        insights: this.insights,

                        summary: [],
                    };

                    for (let personaIndex = 0, end = this.insights.data.length; personaIndex < end; ++personaIndex) {
                        let segmentData = {
                            brandSummary: {
                                twitter: this.chartData(`brief_summary_brands_twitter${ReportUtilities.segmentSeparator}${personaIndex}`),
                            },
                            conglomerateRfmMarket: this.summaryCharts('conglomerateRfmMarket', {
                                personaIndex
                            }),
                            consumerSpendActivity: this.summaryCharts('consumerSpendActivity', {
                                // chart: {height: '32%'},
                                personaIndex
                            }),
                            demographics: this.summaryCharts('demographic', {
                                // chart: {height: '63%'},
                                includeTitle: true,
                                personaIndex,
                            }),
                            interestSummary: {
                                twitter: this.chartData(`brief_summary_interests_twitter${ReportUtilities.segmentSeparator}${personaIndex}`),
                            },
                            pastPurchaseActivity: this.summaryCharts('pastPurchaseActivity', {
                                // chart: {height: '32%'},
                                personaIndex,
                            }),
                            geography: {
                                chart: Highcharts.merge(this.chartData(`dma_code${ReportUtilities.segmentSeparator}${personaIndex}`), {
                                    chart: {
                                        height: '80%',
                                        width: this.printedChartWidth,
                                    }
                                }),
                                detail: this.chartDetail(`dma_code${ReportUtilities.segmentSeparator}${personaIndex}`)?.map(detailItem => {
                                    detailItem.items.index = detailItem.items.index.slice(0, 15);

                                    return detailItem;
                                }),
                            },
                            highLevelRfm: this.allPersonasHaveHighlevelRfmData ?
                                this.summaryCharts('highLevelRfm', {personaIndex}) :
                                false,
                            politicalPartyAffiliation: Highcharts.merge(this.chartData(`political_party_summarized${ReportUtilities.segmentSeparator}${personaIndex}`), {
                                chart: {
                                    width: this.printedChartWidth,
                                },
                            }),
                            politicalSocialSummary: {
                                twitter: this.chartData(`political_summary_twitter${ReportUtilities.segmentSeparator}${personaIndex}`),
                            },
                            socialTopics: this.summaryCharts('socialTopics', {personaIndex}),
                            socialWeeklyActivity: Highcharts.merge(this.chartData(`social_weekly_activity${ReportUtilities.segmentSeparator}${personaIndex}`), {
                                chart: {
                                    width: this.printedChartWidth,
                                    height: '100%',
                                }
                            }),
                            uniqueFacts: this.summaryCharts('uniqueFacts', {personaIndex}),
                        };

                        // console.debug(`SEGMENT DATA @ ${personaIndex} -> `, segmentData);

                        chartData.summary.push(segmentData)
                    }

                    // Demographics
                    const demographicCharts = [
                        'age_range',
                        'income_range',
                        'net_worth_range',
                        'home_value_range',
                        'gender',
                        'marital_status',
                        'children_age_range',
                        'children_present_in_hh',
                        'household_composition',
                        'political_party',
                        'county_size',
                        'occupation_type',
                        'length_of_residence_range',
                        'dwelling_type'
                    ];
                    let demographics: any = {
                        charts: [],
                        associatedData: [],
                    };

                    for (const demographicId of demographicCharts) {
                        for (const context of ['count', 'index']) {
                            const chartId = `demographic_${demographicId}|compare_${context}`;

                            demographics.charts.push({
                                chartId,
                                data: Highcharts.merge(this.chartData(chartId), {
                                    chart: {
                                        width: this.printedChartGridWidth,
                                    }
                                }),
                            })

                            // Associated data
                            const associatedId = this.chartAssociatedId(chartId);
                            if (associatedId) {
                                demographics.associatedData.push({
                                    chartId,
                                    associatedId,
                                    data: this.chartAssociatedData(associatedId),
                                });
                            }
                        }
                    }
                    Object.assign(chartData, {demographics});

                    // All the following charts use independent sorting by index
                    this.sortGroup = 'independent';
                    this.filterGroup = 'index';

                    // Brand and Interest Engagement
                    const brandInterestEngagementCharts = [
                        'summary_brands_twitter_comparison',
                        'summary_interests_twitter_comparison',
                    ];
                    let brandInterestEngagement = {
                        charts: [],
                    };

                    for (const chartId of brandInterestEngagementCharts) {
                        let data = this.chartData(chartId);

                        // Trim to the top 20 items
                        for (const i in data.series) {
                            data.series[i].data = data.series[i].data.slice(0, 20);
                        }
                        data = Highcharts.merge(data, {
                            chart: {
                                height: '100%',
                                width: this.printedChartFullWidth,
                            }
                        })

                        brandInterestEngagement.charts.push({
                            chartId,
                            data,
                        })
                    }
                    Object.assign(chartData, {brandInterestEngagement});

                    // Custom topics
                    if (this.insights.data.every(data => data.social.customTopics.hasOwnProperty('groupDefinitionTopic'))) {
                        const chartId = 'social_engagement_twitter_comparison';
                        let data = this.chartData(chartId);
                        for (const i in data.series) {
                            data.series[i].data = data.series[i].data.slice(0, 20);
                        }

                        data = Highcharts.merge(data, {
                            chart: {
                                width: this.printedChartFullWidth,
                            }
                        });

                        let socialEngagement: any = {
                            charts: [
                                {
                                    chartId,
                                    data,
                                },
                            ]
                        };

                        Object.assign(chartData, {socialEngagement});
                    }

                    const sharedUserTopics = this.sharedTopics.custom.filter(topic => topic.userDefined);
                    if (sharedUserTopics.length) {
                        const userDefinedTopicCharts = sharedUserTopics
                            .map(topicContainer => {
                                return {
                                    chartId: `user_defined_social_engagement_comparison_twitter_${Utils.slug(topicContainer.topic.name)}`,
                                    topicName: topicContainer.topic.name,
                                }
                            });

                        let userDefinedTopics: any = {
                            charts: []
                        };

                        for (const {chartId, topicName} of userDefinedTopicCharts) {
                            let data = this.chartData(chartId);

                            // Trim to the top 10 items
                            for (const i in data.series) {
                                data.series[i].data = data.series[i].data.slice(0, 20);
                            }
                            data = Highcharts.merge(data, {
                                chart: {
                                    height: '110%',
                                    width: this.printedChartFullWidth,
                                }
                            })

                            userDefinedTopics.charts.push({
                                chartId,
                                topicName,
                                data,
                            })
                        }
                        Object.assign(chartData, {userDefinedTopics});
                    }

                    // Consumer Spending
                    Object.assign(chartData, {
                        consumerSpendingSummary: Highcharts.merge(
                            this.chartData('consumer_spending_summary_comparison'),
                            {
                                chart: {
                                    height: '100%',
                                    width: this.printedChartFullWidth,
                                }
                            }
                        )
                    });

                    // Conglomerate RFM (Market Affinity)
                    if (this.allPersonasHaveConglomerateRfmMarketData) {
                        // console.debug('Handle conglomerate RFM Market data comparison...');
                        const conglomerateRfmMarketCharts = [
                            'conglomerate_rfm_comparison_market_overview_top',
                            'conglomerate_rfm_comparison_market_overview_standout',
                        ];
                        let conglomerateRfmMarket: any = {
                            charts: [],
                            selectedMarkets: null,
                        };

                        for (const chartId of conglomerateRfmMarketCharts) {
                            conglomerateRfmMarket.charts.push({
                                chartId,
                                data: this.chartData(chartId),
                            });
                        }

                        // Selected markets
                        // const marketChartPatterns = [
                        //     `conglomerate_rfm_market:{{ context }}:metrics`,
                        //     `conglomerate_rfm_market:{{ context }}:participation`,
                        //     `conglomerate_rfm_market:{{ context }}:ord_avg_amt`,
                        //     `conglomerate_rfm_market:{{ context }}:ord_lat_amt`,
                        // ]
                        // if (this.sharedConglomerateRfmMarketSelections?.length) {
                        //     conglomerateRfmMarket.selectedMarkets = [];
                        //
                        //     for (const selectedMarket of this.sharedConglomerateRfmMarketSelections) {
                        //         // console.debug('SHARED MARKET SELECTION:', selectedMarket);
                        //         // const marketContext = selectedMarket.id;
                        //         // conglomerate_rfm_comparison:4ad59744-8caa-11ee-8ecb-acde48001122
                        //         // let marketCharts = {
                        //         //     name: `${selectedMarket.category} > ${selectedMarket.subCategory} > ${selectedMarket.name}`,
                        //         //     charts: [],
                        //         //     associatedData: [],
                        //         // };
                        //         // for (const chartPattern of marketChartPatterns) {
                        //         //     const chartId = chartPattern.replace('{{ context }}', marketContext);
                        //         //     marketCharts.charts.push({
                        //         //         chartId,
                        //         //         data: this.chartData(chartId),
                        //         //     });
                        //         //
                        //         //     // Associated data
                        //         //     const associatedId = this.chartAssociatedId(chartId);
                        //         //     if (associatedId) {
                        //         //         marketCharts.associatedData.push({
                        //         //             chartId,
                        //         //             associatedId,
                        //         //             data: ReportUtilities.chartAssociatedData(
                        //         //                 `${associatedId}`,
                        //         //                 this.insights,
                        //         //                 {fieldDictionary: this.fieldDictionary}
                        //         //             ),
                        //         //         });
                        //         //     }
                        //         // }
                        //         // conglomerateRfmMarket.selectedMarkets.push(marketCharts);
                        //     }
                        // }

                        Object.assign(chartData, {conglomerateRfmMarket});
                    }

                    // High-Level RFM
                    if (this.allPersonasHaveHighlevelRfmData) {
                        const highLevelRfmCharts = [
                            `cc_amt`,
                            `cc_ord_num`,
                            `cc_major_amt`,
                            `cc_major_ord_num`,

                            `ord_num`,
                            `mem_buyer_num`,
                            `lat_incep_months`,
                            `incep_source_l12_num`,
                            `recency`,

                            `ord_avg_amt`,
                            `ord_lrg_amt`,
                            `ord_lat_amt`,
                            `tot_amt`,
                            `tot_amt_per_mo`,
                        ];
                        let highLevelRfm = {
                            charts: [],
                            associatedData: [],
                        };

                        for (const rfmId of highLevelRfmCharts) {
                            for (const context of ['count', 'index']) {
                                const chartId = `high_level_rfm:${rfmId}|compare_${context}`;

                                highLevelRfm.charts.push({
                                    chartId,
                                    data: Highcharts.merge(this.chartData(chartId), {
                                        chart: {
                                            width: this.printedChartGridWidth,
                                        }
                                    }),
                                })

                                // Associated data
                                const associatedId = this.chartAssociatedId(chartId);
                                if (associatedId) {
                                    highLevelRfm.associatedData.push({
                                        chartId,
                                        associatedId,
                                        data: this.chartAssociatedData(associatedId),
                                    });
                                }
                            }
                        }
                        Object.assign(chartData, {highLevelRfm});
                    }

                    // Past Purchases
                    let pastPurchases = {
                        pages: [
                            {
                                title: 'Clothing and Children',
                                chartIds: [
                                    'past_purchase_womens_fashion|compare_count',
                                    'past_purchase_womens_fashion|compare_index',
                                ],
                            },
                            {
                                title: 'Clothing and Children',
                                chartIds: [
                                    'past_purchase_mens_fashion|compare_count',
                                    'past_purchase_mens_fashion|compare_index',
                                    'past_purchase_childrens_products|compare_count',
                                    'past_purchase_childrens_products|compare_index',
                                ],
                            },
                            {
                                title: 'Interests and Activities',
                                chartIds: [
                                    'past_purchase_outdoor_sports|compare_count',
                                    'past_purchase_outdoor_sports|compare_index',
                                    'past_purchase_homelife|compare_count',
                                    'past_purchase_homelife|compare_index',
                                ],
                            },
                            {
                                title: 'Interests and Activities',
                                chartIds: [
                                    'past_purchase_interests_pastimes|compare_count',
                                    'past_purchase_interests_pastimes|compare_index',
                                    'past_purchase_soho_home_entertainment|compare_count',
                                    'past_purchase_soho_home_entertainment|compare_index',
                                ],
                            },
                            {
                                title: 'Interests and Activities',
                                chartIds: [
                                    'past_purchase_travel|compare_count',
                                    'past_purchase_travel|compare_index',
                                    'past_purchase_hobbies|compare_count',
                                    'past_purchase_hobbies|compare_index',
                                ],
                            },
                            {
                                title: 'Finance and Shopping Style',
                                chartIds: [
                                    'past_purchase_where_they_spend|compare_count',
                                    'past_purchase_where_they_spend|compare_index',
                                    'past_purchase_how_they_spend|compare_count',
                                    'past_purchase_how_they_spend|compare_index',
                                ],
                            },
                            {
                                title: 'Finance and Shopping Style',
                                chartIds: [
                                    'past_purchase_financial_purchases|compare_count',
                                    'past_purchase_financial_purchases|compare_index',
                                ],
                            },
                        ],
                        associatedData: [],
                    };

                    let pastPurchasePageIndex = 0;
                    for (const page of pastPurchases.pages as any) {
                        // page.page = pastPurchasePageIndex;
                        page.charts = [];
                        for (const chartId of page.chartIds) {
                            page.charts.push({
                                chartId,
                                data: Highcharts.merge(this.chartData(chartId), {
                                    chart: {
                                        width: this.printedChartGridWidth,
                                    }
                                }),
                            })

                            // Associated data
                            const associatedId = this.chartAssociatedId(chartId);
                            if (associatedId) {
                                pastPurchases.associatedData.push({
                                    chartId,
                                    associatedId,
                                    data: this.chartAssociatedData(associatedId),
                                });
                            }
                        }

                        ++pastPurchasePageIndex;
                    }
                    Object.assign(chartData, {pastPurchases});

                    // Social activity
                    let socialActivity: any[] = [];
                    for (let personaIndex = 0, end = this.insights.data.length; personaIndex < end; ++personaIndex) {
                        let personaSocialActivity: any = {
                            engagement: {},
                            weeklyActivity: {
                                chart: chartData.summary[personaIndex].socialWeeklyActivity,
                                detail: [
                                    {
                                        name: `Most Active (<span style="color: ${ReportUtilities.reportColors.heatmap.stops[ReportUtilities.reportColors.heatmap.stops.length - 1][1]}">HOTTEST</span>) Times`,
                                        items: this.chartData(`social_weekly_activity${ReportUtilities.segmentSeparator}${personaIndex}`)?.sidebarItems?.topRanked
                                    },
                                    {
                                        name: `Least Active (<span style="color: ${ReportUtilities.reportColors.heatmap.stops[0][1]}">COLDEST</span>) Times`,
                                        items: this.chartData(`social_weekly_activity${ReportUtilities.segmentSeparator}${personaIndex}`)?.sidebarItems?.bottomRanked
                                    },
                                ]
                            },
                            // topContent: []
                        };

                        // for (const contentType of ['hashtags', 'mentions', 'retweets']) {
                        //     const chart = this.chartData(`social_top_content_${contentType}${ReportUtilities.segmentSeparator}${personaIndex}`);
                        //     const chartContent = chart ?
                        //         {
                        //             chart: Highcharts.merge(chart, {
                        //                 chart: {
                        //                     width: this.printedChartWidth,
                        //                 }
                        //             }),
                        //             detail: {
                        //                 contentTypeLabel: Utils.titleCase(contentType === 'retweets' ? 'reposts' : contentType),
                        //                 items: chart.sidebarItems
                        //             },
                        //         } :
                        //         {
                        //             chart,
                        //             detail: false
                        //         };
                        //     personaSocialActivity.topContent.push(chartContent);
                        //     // personaSocialActivity.topContent.push({
                        //     //     chart: Highcharts.merge(this.chartData(`social_top_content_${contentType}${ReportUtilities.segmentSeparator}${personaIndex}`), {
                        //     //         chart: {
                        //     //             width: this.printedChartWidth,
                        //     //         }
                        //     //     }),
                        //     //     detail: {
                        //     //         contentTypeLabel: Utils.titleCase(contentType),
                        //     //         // name: `Top ${Utils.titleCase(contentType)}`,
                        //     //         items: this.chartData(`social_top_content_${contentType}${ReportUtilities.segmentSeparator}${personaIndex}`).sidebarItems
                        //     //     },
                        //     // });
                        // }

                        personaSocialActivity.engagement.charts = [];
                        personaSocialActivity.engagement.associatedData = [];
                        for (const chartId of ['persona_social_graph_profile', 'twitter_follow_frequency']) {
                            personaSocialActivity.engagement.charts.push({
                                chartId,
                                data: Highcharts.merge(this.chartData(`${chartId}${ReportUtilities.segmentSeparator}${personaIndex}`), {
                                    chart: {
                                        width: this.printedChartWidth,
                                    }
                                }),
                            })

                            // Associated data
                            const associatedId = this.chartAssociatedId(chartId);
                            if (associatedId) {
                                personaSocialActivity.engagement.associatedData.push({
                                    chartId,
                                    associatedId,
                                    data: this.chartAssociatedData(`${associatedId}${ReportUtilities.segmentSeparator}${personaIndex}`),
                                });
                            }
                        }

                        socialActivity.push(personaSocialActivity);
                    }
                    Object.assign(chartData, {socialActivity});

                    // Geographic Areas
                    const geographicCharts = [
                        {
                            chartId: 'region',
                            title: 'Regions'
                        },
                        {
                            chartId: 'state',
                            title: 'States',
                        },
                        {
                            chartId: 'metro_area',
                            title: 'Metro Areas',
                        },
                    ];
                    let geographicAreas = [];
                    for (let personaIndex = 0, end = this.insights.data.length; personaIndex < end; ++personaIndex) {
                        let personaGeographicAreas = [];
                        for (const {chartId, title} of geographicCharts) {
                            personaGeographicAreas.push({
                                chartId,
                                title,
                                chart: Highcharts.merge(this.chartData(`${chartId}${ReportUtilities.segmentSeparator}${personaIndex}`), {
                                    chart: {
                                        width: this.printedChartWidth,
                                    },
                                }),
                                detail: this.chartDetail(`${chartId}${ReportUtilities.segmentSeparator}${personaIndex}`),
                            })

                            // // Associated data
                            // const associatedId = this.chartAssociatedId(chartId);
                            // if (associatedId) {
                            //     socialActivity.engagement.associatedData.push({
                            //         chartId,
                            //         associatedId,
                            //         data: this.chartAssociatedData(`${associatedId}${ReportUtilities.segmentSeparator}${personaIndex}`),
                            //     });
                            // }
                        }
                        geographicAreas.push(personaGeographicAreas);
                    }
                    Object.assign(chartData, {geographicAreas});

                    this.customLayout = {
                        component: markRaw(ComparisonSummaryPdf),
                        chartData,
                    };
                }
                    break;
            }

            if (!this.customLayout) {
                switch (this.params.tab) {
                    case undefined: // Summary (default tab)
                    case '':
                        const detail: any[] = [];
                        const chartData: any[] = [];

                        for (let personaIndex = 0; personaIndex < comparisonActivityCount; ++personaIndex) {
                            const demographics = this.summaryCharts(`demographic`, {personaIndex});
                            const uniqueFacts = this.summaryCharts(`uniqueFacts`, {personaIndex});
                            const consumerSpendActivity = this.summaryCharts(`consumerSpendActivity`, {personaIndex});
                            const socialTopics = this.summaryCharts(`socialTopics`, {personaIndex});
                            const socialWeeklyActivity = this.chartData(`social_weekly_activity${ReportUtilities.segmentSeparator}${personaIndex}`);
                            const pastPurchaseActivity = this.summaryCharts(`pastPurchaseActivity`, {personaIndex});

                            /* Geography summary */
                            const geography = {
                                chart: this.chartData(`dma_code${ReportUtilities.segmentSeparator}${personaIndex}`),
                                detail: this.chartDetail(`dma_code${ReportUtilities.segmentSeparator}${personaIndex}`),
                            };

                            // ####################################
                            let segmentData: any = {
                                persona: this.comparisonPersonas[personaIndex],

                                demographics,
                                uniqueFacts,
                                consumerSpendActivity,
                                socialTopics,
                                brandSummary: {
                                    twitter: this.chartData(`brief_summary_brands_twitter${ReportUtilities.segmentSeparator}${personaIndex}`),
                                },
                                interestSummary: {
                                    twitter: this.chartData(`brief_summary_interests_twitter${ReportUtilities.segmentSeparator}${personaIndex}`),
                                },
                                socialWeeklyActivity,
                                politicalPartyAffiliation: this.chartData(`political_party_summarized${ReportUtilities.segmentSeparator}${personaIndex}`),
                                politicalSocialSummary: {
                                    twitter: this.chartData(`political_summary_twitter${ReportUtilities.segmentSeparator}${personaIndex}`),
                                },
                                pastPurchaseActivity,
                                geography,
                            }

                            if (this.allPersonasHaveConglomerateRfmMarketData) {
                                segmentData.conglomerateRfmMarket = this.chartData(`conglomerate_rfm_market_summary:top${ReportUtilities.segmentSeparator}${personaIndex}`);
                            }

                            if (this.allPersonasHaveHighlevelRfmData) {
                                segmentData.highLevelRfm = this.chartData(`high_level_rfm:ord_avg_amt:radial${ReportUtilities.segmentSeparator}${personaIndex}`);
                            }

                            chartData.push(segmentData);
                        }

                        this.customLayout = {
                            component: markRaw(ComparisonSummary),
                            chartData,
                            detail,
                        };
                        break;

                    case 'geographic-areas':
                    case 'geography': {
                        // Map charts
                        let chartData: any[] = [];
                        let detail: any[] = [];
                        let afterChart: string[] = [];

                        let i = 0;
                        for (const sectionData of this.insights.data) {
                            const chartId = `${this.chartList}${ReportUtilities.segmentSeparator}${i}`;
                            chartData.push(this.chartData(chartId));
                            detail.push(this.chartDetail(chartId));

                            switch (this.params.section) {
                                case 'metro-areas':
                                    afterChart.push(
                                        `
                                            <table class="table">
                                                <tbody>
                                                    <tr>
                                                        <td><svg version="1.1" class="index-indicator high-index"><circle cx="6" cy="9" r="9" zIndex="3" stroke-width="1"></circle></svg></td>
                                                        <td class="text-nowrap small"><strong>Index above National Baseline</strong> (more likely to live in)</td>
                                                    <tr>
                                                    <tr>
                                                        <td><svg version="1.1" class="index-indicator low-index"><circle cx="6" cy="9" r="5" zIndex="3" stroke-width="1"></circle></svg></td>
                                                        <td class="text-nowrap small"><strong>Index below National Baseline</strong> (less likely to live in)</td>
                                                    <tr>
                                                </tbody>
                                            </table>
                                        `.trim()
                                    );
                                    break;

                                default:
                                    afterChart.push('');
                            }

                            ++i;
                        }

                        this.customLayout = {
                            component: markRaw(GeographicComparisonReport),
                            chartData,
                            detail,
                            params: {afterChart},
                            sortGroup: this.sortGroup
                        };
                    }
                        break;

                    // case 'rfm': {
                    //     const detail = [];
                    //     const chartData = [];
                    //     let chartList = [];
                    //
                    //     switch (this.params.section) {
                    //         case 'credit-card':
                    //             chartList = [
                    //                 // 'high_level_rfm:rfm_idx:gauge',
                    //                 'rfm_credit_all',
                    //                 'high_level_rfm:cc_amt',
                    //                 'high_level_rfm:cc_ord_num',
                    //                 'rfm_credit_major',
                    //                 'high_level_rfm:cc_major_amt',
                    //                 'high_level_rfm:cc_major_ord_num',
                    //             ];
                    //             break;
                    //         case 'response-rate':
                    //             chartList = [
                    //                 // 'high_level_rfm:recency_idx:gauge',
                    //                 'high_level_rfm:ord_num',
                    //                 'high_level_rfm:mem_buyer_num',
                    //                 'high_level_rfm:lat_incep_months',
                    //                 'high_level_rfm:incep_source_l12_num',
                    //                 'high_level_rfm:recency'
                    //             ];
                    //             break;
                    //         case 'revenue':
                    //             chartList = [
                    //                 'rfm_ord_all',
                    //                 'high_level_rfm:ord_avg_amt',
                    //                 'high_level_rfm:ord_lrg_amt',
                    //                 'high_level_rfm:ord_lat_amt',
                    //                 'high_level_rfm:tot_amt',
                    //                 'high_level_rfm:tot_amt_per_mo',
                    //             ];
                    //             break;
                    //     }
                    //
                    //     for (let personaIndex = 0; personaIndex < comparisonActivityCount; ++personaIndex) {
                    //         // TODO: add all the charts for the current section
                    //         let segmentCharts: any[] = [];
                    //         let segmentDetail: any[] = [];
                    //         for (const chartId of chartList) {
                    //             segmentCharts.push({
                    //                 chartId,
                    //                 data: this.chartData(`${chartId}${ReportUtilities.segmentSeparator}${personaIndex}`),
                    //             });
                    //
                    //             // TODO: chart detail!
                    //             const associatedId = this.chartAssociatedId(chartId);
                    //             if (associatedId) {
                    //                 segmentDetail.push({
                    //                     chartId,
                    //                     associatedId,
                    //                     data: this.chartAssociatedData(`${associatedId}${ReportUtilities.segmentSeparator}${personaIndex}`),
                    //                 })
                    //             }
                    //         }
                    //
                    //         chartData.push(segmentCharts);
                    //         detail.push(segmentDetail);
                    //     }
                    //
                    //     this.customLayout = {
                    //         component: markRaw(RfmComparison),
                    //         chartData,
                    //         detail,
                    //     };
                    // }
                    //     break;

                    case 'social-activity': {
                        const detail = [];
                        let chartData: any[] = [];

                        for (let personaIndex = 0; personaIndex < comparisonActivityCount; ++personaIndex) {
                            chartData.push({
                                topSection: [
                                    {
                                        chart: this.chartData(`persona_social_graph_profile${ReportUtilities.segmentSeparator}${personaIndex}`),
                                        associatedData: this.chartAssociatedData(`${this.chartAssociatedId('persona_social_graph_profile')}${ReportUtilities.segmentSeparator}${personaIndex}`)
                                    },
                                    {
                                        chart: this.chartData(`twitter_follow_frequency${ReportUtilities.segmentSeparator}${personaIndex}`),
                                        associatedData: this.chartAssociatedData(`${this.chartAssociatedId('twitter_follow_frequency')}${ReportUtilities.segmentSeparator}${personaIndex}`)
                                    },
                                ],
                                middleSection: {
                                    chart: Highcharts.merge(this.chartData(`social_weekly_activity${ReportUtilities.segmentSeparator}${personaIndex}`), {
                                        chart: {
                                            height: '85%',
                                        },
                                        title: {
                                            text: 'Weekly Activity',
                                            margin: 0,
                                        },
                                    }, ReportUtilities.defaultChartOptions),
                                    detail: [
                                        {
                                            name: `Most Active (<span style="color: ${ReportUtilities.reportColors.heatmap.stops[ReportUtilities.reportColors.heatmap.stops.length - 1][1]}">HOTTEST</span>) Times`,
                                            items: this.chartData(`social_weekly_activity${ReportUtilities.segmentSeparator}${personaIndex}`)?.sidebarItems?.topRanked
                                        },
                                        {
                                            name: `Least Active (<span style="color: ${ReportUtilities.reportColors.heatmap.stops[0][1]}">COLDEST</span>) Times`,
                                            items: this.chartData(`social_weekly_activity${ReportUtilities.segmentSeparator}${personaIndex}`)?.sidebarItems?.bottomRanked
                                        },
                                    ]
                                },
                                // bottomSection: ['hashtags', 'mentions', 'retweets'].map(contentType => {
                                //     const chart = this.chartData(`social_top_content_${contentType}${ReportUtilities.segmentSeparator}${personaIndex}`);
                                //     return chart ?
                                //         {
                                //             chart,
                                //             detail: {
                                //                 contentTypeLabel: Utils.titleCase(contentType === 'retweets' ? 'reposts' : contentType),
                                //                 items: chart.sidebarItems
                                //             },
                                //         } :
                                //         {
                                //             chart,
                                //             detail: false
                                //         };
                                // })
                            });
                        }

                        this.customLayout = {
                            component: markRaw(SocialActivityComparison),
                            chartData,
                            detail,
                        };
                    }
                        break;

                    default:
                        this.customLayout = false;
                }

            }
        }

        // async handlePageContext() {
        //     if (this.pageContextMenu) {
        //         // Pull first item from the context menu
        //         this.pageContext = this.pageContextMenu[0].context
        //     }
        // }


        /**
         * Functions to run on "page load"-whether via mounted() or inline route change
         * @param isInitial
         */
        async initialize(isInitial: boolean = false) {
            this.pageContext = '';
            this.clearChartDataCache();
            this.filterSettings.count = 0;
            await this.prepareAsyncData(isInitial);
            await this.handleCustomLayout();
            // await this.handlePageContext();

            this.filterGroup = null; // Reset sort group elements
            this.sortGroup = null; // Reset sort group elements
            const sgo = this.sortGroupOptions; // Call once to ensure default settings are ready
        }

        async prepareAsyncData(initial: boolean = false) {
            this.asyncData = [];

            if (initial) {
                await this.retrieveComparisonData();
                if (this.containsSensitiveData) {
                    return false;
                }
                await this.retrieveReportData();
            }

            if (!this.insights.is_data_available) {
                console.error('No insights data available')
                return false;
            }

            // Retrieve any asynchronous data necessary for the active report page
            const forLoop = async () => {
                for (const chartKey of this.chartList) {
                    const [chartId, personaIndex] = chartKey.split('@');
                    const chartInfo = ReportUtilities.prepareChartData({
                        asyncData: this.asyncData,
                        chartId,
                        fieldDictionary: this.fieldDictionary,
                        filterGroup: this.filterGroup,
                        filterSettings: this.filterSettings,
                        Highcharts,
                        insights: personaIndex ?
                            this.insights.data[personaIndex] :
                            isArray(this.insights.data) ? this.insights.data[0] : this.insights.data,
                        params: this.params,
                        returnData: false,
                        sortGroup: this.sortGroup,
                    });
                    if (typeof chartInfo === 'object' && chartInfo.asyncData !== undefined) {
                        const asyncData = await chartInfo.asyncData();

                        // If any data is returned, store it for later use
                        forOwn(asyncData, (data, key) => {
                            this.asyncData[key] = data;
                        });
                    }
                }
            };
            await forLoop();
        }

        async retrieveComparisonData() {
            await this.comparisonStore.clearComparisonState();
            let existingComparison = await this.comparisonStore.getComparisonById(this.params);
            existingComparison.mode = ComparisonMode.REPORT;
            await this.comparisonStore.setComparison(existingComparison);

            if (this.containsSensitiveData) {
                return false;
            }

            // Get the Persona jobs
            let jobParams = {
                accountId: this.params.accountId,
                originator: 'personas',
                jobs: [
                    // {
                    //     originatorId: existingComparison[`personaId1`],
                    //     id: existingComparison[`personaJobId1`]
                    // },
                    // {
                    //     originatorId: existingComparison[`personaId2`],
                    //     id: existingComparison[`personaJobId2`]
                    // },
                ],
                shared: this.sharedMode,
            }
            for (let i = 1; i <= comparisonActivityCount; ++i) {
                jobParams.jobs.push({
                    originatorId: existingComparison[`personaId${i}`],
                    id: existingComparison[`personaJobId${i}`],
                });
                // const idProp = `personaId${i}`,
                //     jobIdProp = `personaJobId${i}`;
            }

            const personaJobs = await this.jobStore.getJobsByIds(jobParams);
            let personaParams: any = {
                accountId: jobParams.accountId,
                ids: [],
                shared: this.sharedMode,
            };
            for (let i: number = 0; i < personaJobs.length; i++) {
                await this.comparisonStore.addComparisonPersonaJob(personaJobs[i]);
                personaParams.ids.push(personaJobs[i].persona.id);
            }

            // Get a decorated personas for use in characteristics
            const personas = await this.personaStore.getPersonasByIds(personaParams);
            for (let j: number = 0; j < personas.length; j++) {
                personas[j].mode = ComparisonMode.REPORT;
                personaParams.persona = personas[j];
                await this.personaStore.mapPersonaBuild(personaParams);
                await this.comparisonStore.addComparisonPersona(this.personaStore.getPersona);
            }

            await this.comparisonStore.setComparisonEdit(existingComparison);
        }

        async retrieveReportData() {
            let personaCount: any = {
                highLevelRfm: 0
            };

            for (let i = 1; i <= comparisonActivityCount; ++i) {
                await this.insightStore.clearInsightState();
                const idProp = `personaId${i}`,
                    jobIdProp = `personaJobId${i}`;
                this.insightStore.setOriginatorRoot('personas');
                this.insightStore.setOriginatorId(this.comparison[idProp]);
                await this.insightStore.setInsightJobId(this.comparison[jobIdProp]);

                let personaInsights: any = {
                    personaId: this.comparison[idProp],
                    personaName: this.comparisonPersonas[i - 1]?.name,
                };

                // Get insights
                let insightsTypes: string[] = [
                    'offline_insights_standard',
                    'social_insights',
                    'unique_insights',
                    'consumer_spend_insights',
                ];

                if (this.account.allowConglomerateRFMVariables) {
                    insightsTypes.push('conglomerate_rfm_insights');
                }
                if (this.account.allowHighLevelRFMVariables) {
                    insightsTypes.push('high_level_rfm_insights');
                }
                let insightsData = await this.insightStore.getInsightsBatch(this.params, insightsTypes);
                for (let i = 0, end = insightsTypes.length; i < end; ++i) {
                    const insightsType = insightsTypes[i];
                    const insightsKey = insightsType.replace('_insights', '');
                    let data = insightsData[insightsType];
                    switch (insightsKey) {
                        // Apply data transformations
                        case 'conglomerate_rfm':
                            if (!StaticUtils.versionCheck(data?.reportFormatVersion || '0', '1.1.1', StaticUtils.versionMatchAtLeast)) {
                                data = null;
                                console.warn('No Conglomerate RFM data for this Persona');
                            }
                            break;
                    }

                    if (data !== null) {
                        personaInsights[insightsKey] = data;
                    }
                }

                personaInsights.is_data_available = true;
                this.insights.data.push(personaInsights);
            }
            if (this.sharedMode) {
                this.shareToken = await this.accountStore.getAccountShareToken({
                    accountId: this.$route.params.accountId,
                    token: this.$route.params.token
                });
                // await this.$store.dispatch('setJobId', this.shareToken.personaJobId);
            }

            // this.fieldDictionary = this.accountStore.getAccountDictionary;
            // this.sentences = this.accountStore.getAccountSentences;
            this.insights.is_data_available = true;

            // // TODO: add more failure checks as necessary
            // if (!this.insights.offline_standard?.demographics?.hasOwnProperty('gender')) {
            //     this.insights.is_data_available = false;
            //     return;
            // }
        }

        summaryCharts(context: string, options: any = {}) {
            const personaIndex = options.personaIndex || 0;
            delete options.personaIndex;

            const chartOptions = Object.assign({
                includeTitle: false,
            }, options);
            let summaryData: any[] = [];

            switch (context) {
                case 'conglomerateRfmMarket':
                    return this.allPersonasHaveConglomerateRfmMarketData ?
                        this.chartData(`conglomerate_rfm_market_summary:top${ReportUtilities.segmentSeparator}${personaIndex}`) :
                        false;

                case 'consumerSpendActivity':
                    // let consumerSpendActivity = [];
                    if (this.allowConsumerSpend) {
                        // Step 1: Get the top three subcategories by index
                        let consumerSpendSubcategories: any[] = [];
                        for (const category of this.insights.data[personaIndex].consumer_spend.categories) {
                            for (const subcategory of category.subCategories) {
                                if (subcategory.displayComparison ?? false) {
                                    consumerSpendSubcategories.push(subcategory);
                                }
                            }
                        }
                        consumerSpendSubcategories = Utils.sortByProperty(consumerSpendSubcategories, 'index', 'desc').slice(0, 3);

                        // Step 2: Prepare chart data
                        // let spendIndex = 0;
                        for (const subcategory of consumerSpendSubcategories) {
                            const chartSourceData = Utils.sortByProperty(subcategory.spendingIndicators, 'index', 'desc')
                                .slice(0, 5);

                            const series = [{
                                colors: reportColors.donut.standard,
                                colorByPoint: true,
                                name: 'Relative Index',
                                showInLegend: false,
                                data: chartSourceData.map(item => item.index),
                            }];

                            summaryData.push(Highcharts.merge(ReportUtilities.defaultChartOptions, {
                                chart: {
                                    height: '37%',
                                    type: 'bar',
                                },
                                title: {
                                    text: subcategory.name,
                                },
                                xAxis: {
                                    categories: chartSourceData.map(item => item.shortDescription),
                                },
                                yAxis: chartAxisOptions.sIndex,
                                series,
                                tooltip: {
                                    formatter: tooltipFormatter('flex', true),
                                },
                            }));

                            // let series = [];
                            // const isPrimary = spendIndex === 0;
                            // let childPos = 0;
                            // const itemSize = 10;
                            // for (const indicator of Utils.sortByProperty(subcategory.spendingIndicators, 'groupRatio', 'desc').slice(0, 5)) {
                            //     const radius = 95 - (childPos * itemSize);
                            //     const innerRadius = radius - itemSize;
                            //     series.push({
                            //         name: indicator.shortDescription,
                            //         data: [{
                            //             color: ReportUtilities.reportColors.donut.standard[childPos],
                            //             radius,
                            //             innerRadius,
                            //             y: indicator.groupRatio,
                            //         }],
                            //         showInLegend: true
                            //     });
                            //     ++childPos;
                            // }
                            //
                            // consumerSpendActivity.push(Highcharts.merge(ReportUtilities.defaultChartOptions, {
                            //     rawData: subcategory,
                            //     sliderPhrase: 'likely to spend',
                            //     tooltipPhrase: 'of these consumers are highly likely to spend',
                            //     icon: [subcategory.iconStyle || 'solid', subcategory.iconName?.replace('fa-', '') || 'hand-holding-dollar'],
                            //
                            //     chart: {
                            //         type: 'solidgauge',
                            //         className: `past-purchase-${isPrimary ? 'primary' : 'secondary'}`,
                            //         backgroundColor: 'transparent',
                            //         height: '175%',
                            //         marginLeft: 0,
                            //         marginRight: 0,
                            //         // marginBottom: 100,
                            //         spacingBottom: 0,
                            //         events: {
                            //             load: chart => {
                            //                 ReportUtilities.chartOverlayCallback(chart.target, 'consumerSpendSummary');
                            //                 ReportUtilities.chartOverlayCallback(chart.target, 'icon', false);
                            //             },
                            //             redraw: chart => {
                            //                 ReportUtilities.chartOverlayCallback(chart.target, 'consumerSpendSummary');
                            //                 ReportUtilities.chartOverlayCallback(chart.target, 'icon', false);
                            //             },
                            //         },
                            //     },
                            //     title: {
                            //         text: subcategory.name,
                            //         attr: {
                            //             title: subcategory.name,
                            //         },
                            //         style: {
                            //             fontSize: `${parseInt(ReportUtilities.defaultChartOptions.title.style.fontSize) * (isPrimary ? 1 : .66)}px`,
                            //         },
                            //         useHTML: true,
                            //     },
                            //     pane: {
                            //         center: ['50%', '35%'],
                            //         size: `${isPrimary ? 100 : 75}%`,
                            //         startAngle: -90,
                            //         endAngle: 270,
                            //         background: {
                            //             backgroundColor: '#efefef',
                            //             borderWidth: 0,
                            //             innerRadius: '95%',
                            //             outerRadius: '100%',
                            //             shape: 'arc',
                            //         },
                            //     },
                            //     yAxis: {
                            //         lineWidth: 0,
                            //         minorTickInterval: null,
                            //         tickWidth: 0,
                            //         title: {text: ''},
                            //         labels: {
                            //             enabled: false,
                            //         },
                            //         min: 0,
                            //         max: 100,
                            //     },
                            //     plotOptions: {
                            //         solidgauge: {
                            //             dataLabels: {
                            //                 enabled: false,
                            //             },
                            //             stickyTracking: false,
                            //         },
                            //         margin: [0, 0, 0, 0],
                            //     },
                            //     series,
                            //     tooltip: {
                            //         enabled: true,
                            //         borderWidth: 1,
                            //         // followPointer: true,
                            //         formatter: function () {
                            //             return `<span style="color:${this.point.color}">\u25CF</span> ${this.series.name}: <b>${Utils.formatValue(this.point.y, 'percent', 2)}</b><br/>`;
                            //         },
                            //     },
                            //     legend: {
                            //         enabled: true,
                            //         layout: 'vertical',
                            //         labelFormatter: function format() {
                            //             return `<span class="align-middle d-inline-block me-2" style="border-radius: 50%; background-color: ${this.data[0].color}; height: 1em; width: 1em;">\</span> ${this.name}`;
                            //         },
                            //         symbolHeight: 0,
                            //         symbolWidth: 0,
                            //         symbolRadius: 0,
                            //         squareSymbol: false,
                            //
                            //         floating: true,
                            //         useHTML: true,
                            //         itemStyle: {
                            //             fontSize: '11px',
                            //             width: '160px'
                            //         },
                            //         align: 'center',
                            //         verticalAlign: 'top',
                            //     },
                            // }));
                            //
                            // ++spendIndex;
                        }
                    }
                    // return consumerSpendActivity
                    break;

                case 'demographic':
                    // let demographics = [];
                    const demographicsSummaryCharts = [
                        'gender_summary',
                        'age_range_summary',
                        'income_range_summary',
                        'children_present_in_hh_summary',

                        'marital_status_summary',
                        'net_worth_range_summary',
                        'occupation_type_summary',
                        'county_size_summary', // Urbanicity,

                        'length_of_residence_range_summary',
                        'dwelling_type_summary',
                        'owner_renter_summary', // Home Owner/renter,
                        'region_summary', // Region
                    ];
                    for (const chartName of demographicsSummaryCharts) {
                        const chartKey = `${chartName}${ReportUtilities.segmentSeparator}${personaIndex}`,
                            chartData = this.chartData(chartKey);
                        if (chartData) {
                            let chart = Highcharts.merge(chartData, {
                                chart: {
                                    type: 'pie',
                                    height: '70%',
                                    events: {
                                        load: chart => {
                                            ReportUtilities.chartOverlayCallback(chart.target, 'icon');
                                        },
                                        redraw: chart => {
                                            ReportUtilities.chartOverlayCallback(chart.target, 'icon');
                                        },
                                    },
                                    marginLeft: 0,
                                    marginTop: 5,
                                    marginBottom: 5,
                                    // marginRight: 0,
                                },
                                title: chartOptions.includeTitle ?
                                    {text: chartData.series[0].name, align: 'left'} :
                                    false,
                                plotOptions: ReportUtilities.chartPlotOptions.donut,
                                legend: {
                                    align: 'right',
                                    layout: 'vertical',
                                    margin: 0,
                                    padding: 0,
                                    verticalAlign: 'middle',
                                    width: '50%',
                                    itemStyle: {
                                        fontSize: '.85em',
                                        fontWeight: 'normal',
                                        textOverflow: 'ellipsis',
                                    },
                                    labelFormatter: function () {
                                        // Make the label bold if it's the highest relative index in the series
                                        // Step 1: Get highest relativeIndex from linkedSeries
                                        const maxRelativeIndex = this.series.linkedSeries[0].data.reduce(
                                            (max, item) => (item.y > max ? item.y : max),
                                            this.series.linkedSeries[0].data[0].y
                                        );
                                        // Step 2: Bold the label if the current position matches the highest indexing position
                                        if (this.series.linkedSeries[0].data[this.index].y === maxRelativeIndex) {
                                            return `<strong>${this.name}</strong>`;
                                        }
                                        return this.name;
                                    }
                                },
                                tooltip: {
                                    formatter: ReportUtilities.tooltipFormatter('demographicSummary'),
                                    outside: true,
                                    useHTML: true,
                                },
                            });
                            summaryData.push(chart);
                        }
                    }
                    // return demographics;
                    break;

                case 'highLevelRfm':
                    return this.allPersonasHaveHighlevelRfmData ?
                        this.chartData(`high_level_rfm:ord_avg_amt:radial${ReportUtilities.segmentSeparator}${personaIndex}`) :
                        false;

                case 'pastPurchaseActivity':
                    const pastPurchaseDictionaryKeys: string[] = [];
                    for (const [key, dictionaryItem] of Object.entries(this.fieldDictionary.standard)) {
                        if (dictionaryItem.displayPersona
                            && dictionaryItem.category === 'Purchase'
                            && dictionaryItem.children !== null) {
                            pastPurchaseDictionaryKeys.push(key);
                        }
                    }

                    // Order by index and filter to top three
                    let pastPurchaseData = [];
                    for (const key of pastPurchaseDictionaryKeys) {
                        if (this.insights.data[personaIndex].offline_standard?.demographics.hasOwnProperty(key)) {
                            pastPurchaseData.push(Highcharts.merge(this.insights.data[personaIndex].offline_standard?.demographics[key][0], {key}));
                        }
                    }
                    const pastPurchases = Utils.sortByProperty(pastPurchaseData, 'index', 'desc')
                        .slice(0, 3);

                    // Prepare chart data
                    // let pastPurchaseActivity = [];
                    // let purchaseIndex = 0;
                    for (const purchaseCategory of pastPurchases) {
                        let children = [];
                        // const isPrimary = purchaseIndex === 0;
                        const parentDictionary = this.fieldDictionary.standard[purchaseCategory.key]
                        for (const childKey of parentDictionary.children) {
                            if (!this.insights.data[personaIndex].offline_standard.demographics.hasOwnProperty(childKey) || !this.fieldDictionary?.standard?.hasOwnProperty(childKey)) {
                                continue;
                            }
                            const childData = clone(this.insights.data[personaIndex].offline_standard.demographics[childKey][0]) || false;
                            if (childData) {
                                children.push(Object.assign({}, childData, {dictionary: this.fieldDictionary.standard[childKey]}));
                            }
                        }
                        const chartSourceData = Utils.sortByProperty(children, 'index', 'desc')
                            .slice(0, 5)
                            .map(childData => {
                                childData.shortDescription = childData.dictionary.shortDescription;

                                return childData;
                            });
                        const series = [{
                            colors: reportColors.donut.standard,
                            colorByPoint: true,
                            name: 'Relative Index',
                            showInLegend: false,
                            data: chartSourceData.map(item => item.index),
                        }];

                        summaryData.push(Highcharts.merge(ReportUtilities.defaultChartOptions, {
                            chart: {
                                height: '37%',
                                type: 'bar',
                            },
                            title: {
                                text: parentDictionary.shortDescription,
                            },
                            xAxis: {
                                categories: chartSourceData.map(item => item.shortDescription),
                            },
                            yAxis: chartAxisOptions.sIndex,
                            series,
                            tooltip: {
                                formatter: tooltipFormatter('flex', true),
                            },
                        }));
                    }
                    // return pastPurchaseActivity;
                    break;

                case 'socialTopics':
                    const maxSocialTopics = 4;
                    let socialTopics = [];
                    // User defined topics - only show shared/overlapping selections
                    const sharedTopicNames = this.sharedTopics.custom
                        .filter(topic => topic.hasOwnProperty('topic'))
                        .map(topic => topic.topic.name || null);
                    if (this.insights.data[personaIndex].social.customTopics.userDefinedTopics.length) {
                        const userDefinedTopics = Utils.sortByProperty(
                            this.insights.data[personaIndex].social.customTopics.userDefinedTopics
                                .filter((topic: any) => sharedTopicNames.includes(topic.name)),
                            'name',
                            'asc'
                        ).slice(0, 4);
                        for (let topic of userDefinedTopics) {
                            // Ensure that each topic has actual data in it
                            let itemCount = 0;
                            for (let context of ['twitter']) {
                                itemCount += topic[context]?.topByIndex.filter((item: any) => item.groupCount > 0).length || 0;
                            }
                            if (itemCount) {
                                topic.tab = 'custom-topics';
                                topic.section = Utils.slug(topic.name); // Still uses topic.name since this is user-defined
                                socialTopics.push(topic);
                            }
                        }
                    }
                    // Default topics - hardcoded to enforce selection order
                    if (socialTopics.length < maxSocialTopics) {
                        let defaultTopicQueue: any[] = [
                            {tab: 'interests', topicCategory: 'TV Shows'},
                            {tab: 'brands', topicCategory: 'Nonprofit'},
                            {tab: 'brands', topicCategory: 'Restaurants'},
                            {tab: 'brands', topicCategory: 'Auto'},
                        ]
                            .filter(queueTopic => !socialTopics.some(socialTopic => socialTopic.name === queueTopic.topicCategory));

                        let qIndex = 0;
                        for (let index = socialTopics.length; index < maxSocialTopics; ++index) {
                            const {tab, topicCategory} = defaultTopicQueue[qIndex];
                            for (const [platform, topics] of Object.entries(this.insights.data[personaIndex].social[tab].defaultTopics)) {
                                const topic = topics.find(topic => topic.category === topicCategory);
                                let currentSocialTopic = socialTopics[index] || {};
                                if (topic) {
                                    const topicSlug = Utils.slug(topic.category);
                                    currentSocialTopic.tab = tab;
                                    currentSocialTopic.section = topicSlug;
                                    currentSocialTopic.key = topic.key || topicSlug;
                                    currentSocialTopic.category = topic.category;
                                    currentSocialTopic[platform] = topic;
                                    socialTopics[index] = currentSocialTopic;
                                }
                            }
                            ++qIndex;
                        }
                    }

                    return socialTopics.filter((topic: any) => topic !== undefined);

                case 'uniqueFacts':
                    let uniqueFacts = [];
                    /*
                    UF #1: highest penetration ratio for a Brand sub category, chosen from the 20 highest by penetration counts (or whatever is selected for brand summary).
                    UF #2: highest penetration ratio for an interest sub category,  chosen from the 20 highest by penetration counts (or whatever is selected for interest summary).
                    UF #3: highest indexing demo not used in persona characteristics
                    UF #4: highest indexing Consumer Spend Intensity variable - the "Highest of the H values" in the Unique Facts json.
                        consumerSpendH
                    UF #5: highest indexing past "purchase" variable (not parent or rollup - only children) - `demographics.top[1]
                    */
                    const uniqueFactSource = [
                        {
                            sourceType: 'brandTopics',
                            index: 0,
                            property: 'zScore',
                        },
                        {
                            sourceType: 'interestTopics',
                            index: 0,
                            property: 'zScore',
                        },
                        {
                            sourceType: 'demographics',
                            index: 0,
                            property: 'index',
                        },
                        {
                            sourceType: 'consumerSpendH',
                            index: 0,
                            property: 'index',
                        },
                        {
                            sourceType: 'purchase',
                            index: 0,
                            property: 'index',
                        },
                    ]
                        .filter(factSource => {
                            // Only show consumersSpenduUniquefFacts if it's allowed at the account level
                            return this.allowConsumerSpend ?
                                true :
                                factSource.sourceType.indexOf('consumerSpend') === -1;
                        });

                    let i = 0;
                    for (const sourceParameters of uniqueFactSource) {
                        const {sourceType, index, property} = sourceParameters,
                            factData: any = this.insights.data[personaIndex].unique[sourceType],
                            factSource: any = factData.top[index];
                        const value = factSource[property],
                            key = Utils.slug(`${factSource.item?.fieldName || i}-${factSource.value?.value || i}`);
                        const sentence = this.sentences.sentences.find(sentence => sentence.style === factSource.sentence?.style);

                        let description: string;
                        if (sentence && sentence.hasOwnProperty('sentence')) {
                            const tokenPattern = /{{([^}]+)}}/g;
                            description = `${sentence.sentence}`; // Ensure this is not a pointer by creating a new template literal
                            Array.from(description.matchAll(tokenPattern))
                                .forEach(match => {
                                    const [token, property] = match;
                                    let replacement: string;
                                    switch (property) {
                                        // case (property.match(fieldDictionaryTokenPattern) || {}).input:
                                        //     replacement = factSource[property.match(fieldDictionaryTokenPattern)[1]];
                                        //     break;

                                        case 'likelyText':
                                            const indexText: any = Utils.sortByProperty(this.sentences.indexText, 'min', 'desc')
                                                .find(indexText => {
                                                    return indexText.style === factSource.sentence?.indexStyle && factSource.index >= indexText.min;
                                                });
                                            replacement = indexText.text;
                                            break;

                                        default:
                                            replacement = Utils.descendentProp(factSource, property);
                                    }
                                    description = description.replace(token, replacement);
                                });
                        } else {
                            description = `NO SENTENCE FOUND FOR STYLE "${factSource.sentence?.style}" - DATA TYPE: ${factSource.dataType}/${factSource.dataType === 'topic' ? factSource.item.topicName : 'UNKNOWN'}`
                        }
                        description = Utils.sentenceCase(description);

                        let graphTooltip: string,
                            includeFact: boolean = true,
                            infoTooltip: string,
                            wedgeStop;
                        switch (property) {
                            // case 'groupRatio':
                            //     wedgeStop = `${Math.round(value)}%`;
                            //     const verb = sourceType === 'brandTopics' ? 'engaged' : 'interested';
                            //     graphTooltip = `${wedgeStop} of this group is ${verb}`;
                            //     let infoArray: string[] = factSource.topByCount || [];
                            //
                            //     if (Utils.isEmptyArray(infoArray)) {
                            //         /*
                            //         TODO: Remove after we verify that all records properly decorate the unique fact topByCount data
                            //         - Using the `id` of the top brand or interest from `unique_insights`, retrieve the corresponding topic object from `social_insights`.[brands|interests].topicSummary.twitter.
                            //         - for the tooltip, using the `item.id` from the brand/interest in `unique_insights`, find the corresponding topic details
                            //           in social_insights ([brands|interests].topicsSummary.twitter) and use the topByCount array to retrieve a list of
                            //           `displayName` as the text to build the hover text
                            //         */
                            //         const socialContext = sourceType.replace('Topics', 's');
                            //         const tooltipDataSource = this.insights.data[personaIndex].social[socialContext].topicsSummary.twitter
                            //             .find(topic => topic.id === factSource.item.id);
                            //         if (tooltipDataSource) {
                            //             infoArray = tooltipDataSource.topByCount.map(item => item.displayName);
                            //         } else {
                            //             infoArray = [`[ERROR] No topic data found for source type "${sourceType}" ${socialContext}`];
                            //         }
                            //     }
                            //
                            //     break;

                            case 'index':
                            default:
                                const descriptionSource = sourceType === 'demographics' ? 'value' : 'item';
                                const indexPercent = value * 100,
                                    highValue = 1000;
                                wedgeStop = Utils.formatValue(
                                    indexPercent <= 100 ?
                                        indexPercent / 2 :
                                        Math.min(((indexPercent / highValue) * 50) + 50, 96),
                                    'percent',
                                    2
                                );
                                graphTooltip = Utils.formatValue(value, 'indexLikelihood');
                                infoTooltip = factSource[descriptionSource].longDescription;
                                break;

                            case 'zScore': {
                                const standardDeviation = factSource['stdDev'];
                                if (!standardDeviation || !this.allPersonasHaveZScoreData) {
                                    includeFact = false;
                                    break;
                                }

                                const deviatedValue = (3 * standardDeviation) + (value * standardDeviation);
                                const maxDeviation = (6 * standardDeviation);
                                const percentage = Math.round((deviatedValue / maxDeviation) * 100);

                                wedgeStop = `${Math.max(0, Math.min(100, percentage))}%`;
                                graphTooltip = '';
                                let infoArray: string[] = factSource.topByCount || [];

                                if (Utils.isEmptyArray(infoArray)) {
                                    const socialContext = sourceType.replace('Topics', 's'); // e.g. brandTopics -> brands
                                    const tooltipDataSource = this.insights.data[personaIndex].social[socialContext].topicsSummary.twitter
                                        .find(topic => topic.id === factSource.item.id);
                                    if (tooltipDataSource) {
                                        infoArray = tooltipDataSource.topByCount.map(item => item.displayName);
                                    } else {
                                        infoArray = [`[ERROR] No topic data found for source type "${sourceType}" ${socialContext}`];
                                    }
                                }

                                infoTooltip = ReportUtilities.dataSourceItemToolTipFormatter(infoArray);
                            }
                                break;
                        }

                        if (includeFact) {
                            uniqueFacts.push({
                                description,
                                key,
                                value,
                                wedgeStop,
                                colors: ReportUtilities.reportColors.wedge,
                                tooltip: {
                                    graph: graphTooltip,
                                    info: infoTooltip,
                                }
                            });
                        }
                        ++i;
                    }

                    return uniqueFacts;
            }

            summaryData = summaryData.map((chartData: any) => {
                if (options.chart) {
                    chartData.chart = Highcharts.merge(chartData.chart, options.chart);
                }
                return chartData;
            });

            return summaryData;
        }

        @Watch('$route.params')
        async onRouteParamsChanged() {
            this.reportReady = false;
            await this.initialize(false);
            this.reportReady = true;
        }

        @Watch('filterGroup')
        @Watch('filterSettings', {deep: true})
        @Watch('sortGroup')
        onFilterSettingsChanged() {
            this.clearChartDataCache();
        }

        @Watch('pageContext')
        async onPageContextChanged(newVal: string | null, oldVal: string | null) {
            if (this.chartList.length) {
                this.customLayout = false;
                await this.prepareAsyncData(false);
                await this.handleCustomLayout();
            }
        }

        @Watch('pageContextMenu', {immediate: true})
        async onPageContextMenuChanged(contextMenu: any[] | boolean) {
            if (contextMenu?.length && !this.pageContext) {
                // New menu - set the first item as the default context
                this.pageContext = contextMenu[0]?.context;
            }
        }

    }

    export default toNative(ComparisonReport);
</script>

<style lang="scss" src="../../common/report/report.scss"/>
<style lang="scss" src="./comparison-report.scss"/>
